import * as actions from "../actions/messengerActions";

const initialState = {
    connectionState: null,
    pusher: null,
};

function messengerReducer(state = initialState, action) {
    switch (action.type) {
        case actions.SET_CONNECTION_STATE:
            return {
                ...state,
                connectionState: action.connectionState,
            };
        case actions.SET_PUSHER_OBJECT:
            return {
                ...state,
                pusher: action.pusher,
            };
        default:
            return state;
    }
}

export default messengerReducer;
