import React from "react";
import {withRouter, Redirect} from "react-router";
import {Link} from "react-router-dom";
import classNames from "classnames";
import get from "lodash/get";
import styled from "styled-components";
import IoIosChatbubbles from "react-icons/lib/io/chatbubbles";
import IoIosColorWand from "react-icons/lib/io/ios-color-wand";
import IoIosAdmin from "react-icons/lib/md/account-circle";
import IoIosPlanet from "react-icons/lib/io/planet";
import IoIosRocket from "react-icons/lib/md/school";
import IoIosSettings from "react-icons/lib/io/android-settings";
import {Box, Heading} from "@unibuddy/patron";
import Logo from "shared/Experimental/General/components/Logo/Logo";
import {
    isFeatureEnabledByUnibuddyOrByUnibuddyAndUniversity,
    useFeatureFlags,
} from "shared/Utils/FeatureUtils";
import {HasUniversityFeature} from "shared/Feature/HasUniversityFeature";
import NavbarLink, {SubLink} from "../Navbar/components/NavbarLink/NavbarLink";
import s from "../Navbar/containers/Navbar.pcss";
import {Consumer} from "../App/AppContainer";
import {
    BUDDIES,
    CUSTOMIZATION,
    CUSTOMIZATION_DEGREES,
    CUSTOMIZATION_DEGREE_LEVELS_AND_TYPES,
    CUSTOMIZATION_DEGREE_LEVELS,
    COMMUNITY,
    INTEGRATIONS,
    CRM,
    ADMIN_USERS,
    SETTINGS,
} from "./Features";

const RedirectToRoute = ({to}) => <Redirect to={to} />;

const UniversityTitle = styled(Heading)`
    text-align: center;
    font-weight: 600;
    font-size: 18px;
`;

const CommunityStandAloneNavBar = props => {
    const selectedOrganisation = get(props, "authState.me.universityUser.university.name", "");
    const path = get(props, "location.pathname");
    const invitationPath = path === "/buddies/staff" ? "/account/invite/staff" : "/account/invite";
    const buddiesPath =
        path === "/buddies/staff" || path === "/account/invite/staff"
            ? "/buddies/staff"
            : "/buddies";
    const adminsRoute = path.includes("/users");
    const universitySlug = get(props, "me.universityUser.university.slug");
    const universityFeatureData = useFeatureFlags(universitySlug);
    const universityFeatures = universityFeatureData.data
        ? universityFeatureData.data.university.config.features
        : [];
    const loadCustomizeFeaturesSublink = () => {
        if (
            !universityFeatureData.loading &&
            universityFeatureData.data &&
            universityFeatures.find(aFeature => aFeature.universityCanToggleFeature === true)
        ) {
            return [<SubLink to="/customization/features">Features</SubLink>];
        }
        return [];
    };
    const isDegreeTypesTabEnabled = isFeatureEnabledByUnibuddyOrByUnibuddyAndUniversity(
        universityFeatures,
        "enableDegreeTypes",
    );
    return (
        <Consumer>
            {({collapsed}) => (
                <div
                    className={classNames(s.container, {
                        collapsedSidebar: collapsed,
                    })}
                >
                    <Link to="/" className={s.logoLink} aria-label="Unibuddy logo" href="/">
                        <Logo className={s.logo} width={121} displayText={!collapsed} />
                    </Link>

                    {!collapsed ? (
                        <Box paddingBottom="large">
                            <UniversityTitle level="4">{selectedOrganisation}</UniversityTitle>
                        </Box>
                    ) : null}

                    <NavbarLink
                        to="/buddies"
                        icon={<IoIosRocket />}
                        subLinks={[
                            <SubLink to={buddiesPath}>Manage</SubLink>,
                            <SubLink to={invitationPath}>Invitations</SubLink>,
                            <SubLink to="/timesheet/students">Timesheet</SubLink>,
                        ]}
                    >
                        {BUDDIES}
                    </NavbarLink>

                    <NavbarLink
                        icon={<IoIosColorWand />}
                        to="/customization"
                        subLinks={[
                            <SubLink to="/degrees">{CUSTOMIZATION_DEGREES}</SubLink>,
                            <SubLink to="/degree">
                                {isDegreeTypesTabEnabled
                                    ? CUSTOMIZATION_DEGREE_LEVELS_AND_TYPES
                                    : CUSTOMIZATION_DEGREE_LEVELS}
                            </SubLink>,
                            ...loadCustomizeFeaturesSublink(),
                        ]}
                    >
                        {CUSTOMIZATION}
                    </NavbarLink>

                    <NavbarLink icon={<IoIosChatbubbles />} to="/community">
                        {COMMUNITY}
                    </NavbarLink>

                    <NavbarLink
                        icon={<IoIosPlanet />}
                        subLinks={[<SubLink to="/integrations">{CRM}</SubLink>]}
                    >
                        {INTEGRATIONS}
                    </NavbarLink>

                    <HasUniversityFeature
                        feature="manageUniAdmins"
                        universitySlug={universitySlug}
                        fallback={adminsRoute ? <RedirectToRoute to="/community" /> : null}
                    >
                        <NavbarLink to="/users" icon={<IoIosAdmin />}>
                            {ADMIN_USERS}
                        </NavbarLink>
                    </HasUniversityFeature>

                    <NavbarLink to="/settings" icon={<IoIosSettings />}>
                        {SETTINGS}
                    </NavbarLink>
                </div>
            )}
        </Consumer>
    );
};

export default withRouter(CommunityStandAloneNavBar);
