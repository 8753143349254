import * as React from "react";
import {SVGProps} from "react";

const IconAcademicHat = (props: SVGProps<SVGSVGElement> & {title?: string}) => {
    const {color = "#131622", title, ...rest} = props;
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={19}
        fill="none"
        role="img"
        {...rest}
    >
        <title>{title ?? "Buddies"}</title>
        <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M3.556 7.399a58.12 58.12 0 0 0-.472 6.104A46.763 46.763 0 0 1 11 17.743a46.757 46.757 0 0 1 7.916-4.24c-.051-2.064-.21-4.1-.472-6.104m-14.888 0A48.644 48.644 0 0 0 1 6.617 57.606 57.606 0 0 1 11 1a57.607 57.607 0 0 1 10 5.617c-.862.238-1.714.499-2.556.782m-14.888 0A48.755 48.755 0 0 1 11 10.613a48.754 48.754 0 0 1 7.444-3.214M5.95 12.066a.721.721 0 1 0 0-1.443.721.721 0 0 0 0 1.443Zm0 0V8.53A53.256 53.256 0 0 1 11 5.761M4.261 16.867a5.752 5.752 0 0 0 1.69-4.08v-1.442"
        />
    </svg>;
};
export default IconAcademicHat;
