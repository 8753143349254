export default {
    shared: {
        NAME: "Shared",
        events: {
            productOpened: {
                name: "Product Opened",
                requiredFields: ["name", "product", "universitySlug"],
            },
            pageViewed: {
                name: "Page Viewed",
                requiredFields: ["prefix"],
            },
            recordingToggled: {
                name: "Recording Toggled",
                requiredFields: [
                    "toggledTo",
                    "accountRole",
                    "universitySlug",
                    "liveEventId",
                    "subProduct",
                ],
            },
            recordingDownloaded: {
                name: "Recording Downloaded",
                requiredFields: [
                    "fileId",
                    "liveEventId",
                    "accountRole",
                    "universitySlug",
                    "subProduct",
                ],
            },
            timesheetViewed: {
                name: "Unidash_Admins_TimeSpent_PageViewed",
                requiredFields: ["universitySlug", "universityName"],
            },
            buddyBroadcast: {
                name: "Buddy Broadcast Sent",
                requiredFields: ["universitySlug", "universityName"],
            },
            timesheetCSVDownload: {
                name: "Timesheet CSV Downloaded",
                requiredFields: ["universitySlug", "universityName"],
            },
            showAmbassadorRepliesEnabled: {
                name: "Toggled On Show Ambassador Replies",
                requiredFields: ["universitySlug", "universityName"],
            },
            insightMessagesDrawerOpened: {
                name: "Opened Insight Messages Drawer",
                requiredFields: ["universitySlug", "universityName"],
            },
            insightCustomDateRangeFilterApplied: {
                name: "Conversation Insights Custom Date Range Filter Applied",
                requiredFields: ["universitySlug", "universityName"],
            },
            insightReportExported: {
                name: "Conversation Insights Report Exported",
                requiredFields: ["universitySlug", "universityName", "isCustomFilter"],
            },
            prospectNextStep: {
                name: "Prospect Next Step",
                requiredFields: ["universitySlug", "universityName", "forStage", "eventType", "actionType"],
            },
            prospectStageViewed: {
                name: "Prospect Stages Time Filter Applied",
                requiredFields: ["universitySlug", "universityName", "timeFilter"],
            },
            prospectStageStatsExport: {
                name: "Prospect Stage Stats Report Exported",
                requiredFields: ["universitySlug", "universityName", "timeFilter"],
            },
            prospectStageReportHovered: {
                name: "Prospect Stage Report Hovered",
                requiredFields: ["universitySlug", "universityName", "forStage"],
            },
            inviteProspectByLink: {
                name: "Invite Prospect via Copy Link",
                requiredFields: ["universitySlug", "universityName"],
            },
            inviteProspectByEmail: {
                name: "Invite Prospect via Email Notification",
                requiredFields: ["universitySlug", "universityName"],
            },
            addFiltersOnProspectNBS: {
                name: "Add Filters on Prospect Next step",
                requiredFields: ["universitySlug", "field", "values"],
            },
            viewProspectConversationSummary: {
                name: "Admin View Summary",
                requiredFields: ["universitySlug", "universityName"],
            },
            nbsAnalyticsViewed: {
                name: "View NBS Analytics",
                requiredFields: ["universitySlug", "universityName"],
            },
            filterChangeOnDashboard: {
                name: "Applied Filters on Dashboard",
                requiredFields: ["universitySlug", "universityName", "dashboard", "customerTier"],
            },
            exportOnDashboard: {
                name: "Admin Dashboard Visualisation Exported",
                requiredFields: ["universitySlug", "universityName", "dashboard", "customerTier", "exportType"],
            },
            analyticsViewed: {
                name: "Admin Analytics Viewed",
                requiredFields: ["universitySlug", "universityName", "dashboard", "customerTier"],
            },
            bulkResolvedConversations: {
                name: "Bulk Resolved Conversations",
                requiredFields: ["universityId", "universityName", "fromDate", "toDate", "resolvedCount"],
            },
            ambassadorProfileEditClicked: {
                name: "Clicked Edit Action on Buddies List",
                requiredFields: ["universityId", "universityName", "buddyId"],
            },
            ambassadorProfileViewClicked: {
                name: "Viewed Student Ambassador Profile by admin",
                requiredFields: ["universityId", "universityName", "buddyId"],
            },
            ambassadorProfileEdited: {
                name: "Edited Student Ambassador profile by Admin",
                requiredFields: ["universityId", "universityName", "buddyId"],
            },
            buddyProfileCustomised: {
                name: "Buddy Profile Customised",
                requiredFields: ["universitySlug"],
            },
            promptToggleEnabled: {
                name: "Admin toggled on Scheduling Prompts",
                requiredFields: ["universitySlug"],
            },
            briefCopied: {
                name: "Admin Copied AI prompts",
                requiredFields: ["universitySlug"],
            },
            briefRemoved: {
                name: "Admin removed AI prompts",
                requiredFields: ["universitySlug", "action"],
            },
            generateMorePromptsClicked: {
                name: "Admin clicked generate more prompts",
                requiredFields: ["universitySlug", "times"],
            },
            moderationPageViewed: {
                name: "Admin Viewed Moderation",
                requiredFields: ["universitySlug"],
            },
            approveBlockClicked: {
                name: "Admin clicked approve block",
                requiredFields: ["universitySlug"],
            },
            unApproveBlockClicked: {
                name: "Admin clicked Unapprove Block",
                requiredFields: ["universitySlug"],
            },
        },
    },
    chat: {
        NAME: "Chat",
        events: {
            adminIntervention: {
                name: "Admin Intervention",
                requiredFields: ["conversationId", "messageCode"],
            },
            conversationManagementSearch: {
                name: "search_conversation_management",
                requiredFields: ["universityAdminId", "pageViewId"],
            },
            conversationManagementStatusFilterApplied: {
                name: "Conversation filter applied on conversation management by Admin",
                requiredFields: ["universitySlug", "filterValue"],
            },
            conversationManagementAmboStatusFilterApplied: {
                name: "Ambassador Status Filter applied on conversation management by Admin",
                requiredFields: ["universitySlug", "filterValue"],
            },
            conversationManagementSortApplied: {
                name: "Conversations Sorted by Admin ",
                requiredFields: ["universitySlug", "sortKey"],
            },
        },
    },
};
