import * as actions from "../actions/blogActions";

function blogReducer(
    state = {
        pageNumber: 0,
        sortField: "-created",
        blogPostId: null,
    },
    action,
) {
    switch (action.type) {
        case actions.SET_BLOG_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: action.pageNumber,
            };
        case actions.SET_BLOG_SORT_FIELD:
            return {
                ...state,
                sortField: action.sortField,
            };
        case actions.SET_BLOG_POST_ID:
            return {
                ...state,
                blogPostId: action.blogPostId,
            };
        default:
            return state;
    }
}

export default blogReducer;
