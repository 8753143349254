import React from "react";
import {Query} from "react-apollo";
import getMemberOrganisations from "./queries/getMemberOrganisations";

const MemberOrganisationsQuery = ({render}) => (
    <Query query={getMemberOrganisations} asyncMode>
        {({data, error, loading}) => {
            if (loading || error) {
                return render({memberOrganisations: []});
            }
            return render({memberOrganisations: data.memberOrganisations});
        }}
    </Query>
);

export default MemberOrganisationsQuery;
