import PropTypes from "prop-types";
import React, {Component} from "react";
import compose from "lodash/flowRight";
import {graphql} from "react-apollo";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import {connect} from "react-redux";
import {Form, FormGroup, SubmitButton, TextInput} from "@unibuddy/patron";
import {string} from "yup";
import {withTranslation} from "@unibuddy/intl";
import {PASSWORD_MIN_CHAR} from "shared/Shared/validations/validationMessages";
import resetPasswordMutation from "../../mutations/resetPasswordMutation";
import ProgressBar from "../../../General/components/ProgressBar/ProgressBar";
import PageHeader from "../../../Shared/components/PageHeader/PageHeader";
import Stack from "../../../Experimental/Layout/components/Stack/Stack";

const PASSWORD_MINIMUM_LENGTH = 12;

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            loading: false,
            errorMsg: "",
        };
    }

    async onRequestPasswordReset({password}, formActions) {
        this.setState({loading: true});

        try {
            const {data} = await this.props.resetPassword({
                variables: {
                    token: this.props.token,
                    userId: this.props.userId,
                    password,
                },
            });

            if (data.resetPasswordV2 && data.resetPasswordV2.error) {
                this.setState({error: true, errorMsg: data.resetPasswordV2.error});
                formActions.setFieldError(
                    "password",
                    data.resetPasswordV2.error,
                );
            } else {
                const redirectUrl = data.resetPasswordV2.redirectUrl;
                this.props.onComplete(redirectUrl);
            }
        } catch (e) {
            this.setState({loading: false, error: true});
        } finally {
            formActions.setSubmitting(false);
        }
    }

    render() {
        const {t} = this.props;

        return (
            <div>
                <ProgressBar start={this.state.loading} />
                <div className="container-fluid" id="resetPassword">
                    <Row>
                        <Col mdOffset={3} md={6} smOffset={2} sm={8} xs={12} className="m-t-sm">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    <div className="text-center">
                                        <PageHeader
                                            title={`${this.props.set ? "Set" : "Reset"} Password`}
                                        />
                                        <p>{t("enterNewPassword", "Enter your new password")}</p>
                                    </div>
                                    <Form
                                        onSubmit={this.onRequestPasswordReset.bind(this)}
                                        validationSchema={{
                                            password: string().min(
                                                PASSWORD_MINIMUM_LENGTH,
                                                t(
                                                    "validations:passwordMinChat",
                                                    PASSWORD_MIN_CHAR(PASSWORD_MINIMUM_LENGTH),
                                                    {
                                                        minChar: PASSWORD_MINIMUM_LENGTH,
                                                    },
                                                ),
                                            ),
                                        }}
                                    >
                                        {({isValid}) => {
                                            return (
                                                <Stack space={"medium"}>
                                                    <FormGroup
                                                        id={"password"}
                                                        label={t("newPassword", "New Password")}
                                                    >
                                                        <TextInput
                                                            name={"password"}
                                                            type={"password"}
                                                            required
                                                        />
                                                    </FormGroup>
                                                    <Stack space={"small"} align={"center"}>
                                                        <SubmitButton
                                                            id="continue"
                                                            color="primary"
                                                            disabled={!isValid}
                                                        >
                                                            {this.props.set
                                                                ? t("setPassword", "Set Password")
                                                                : t(
                                                                      "changePassword",
                                                                      "Change Password",
                                                                  )}
                                                        </SubmitButton>
                                                    </Stack>
                                                </Stack>
                                            );
                                        }}
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

ResetPassword.propTypes = {
    onComplete: PropTypes.func.isRequired,
};

const resetPasswordOptions = {
    name: "resetPassword",
};

export default compose(
    connect(),
    graphql(resetPasswordMutation, resetPasswordOptions),
    withTranslation(["passwordReset", "validations"]),
)(ResetPassword);
