import React from "react";
import classNames from "classnames";
import s from "./DotsLoader.pcss";

const DotsLoader = ({className, color}) => (
    <svg
        className={classNames(s.dots, className)}
        width={132}
        height={58}
        xmlns="http://www.w3.org/2000/svg"
    >
        <g className={classNames(s.dots, className)} fill={color} fillRule="evenodd">
            <circle className={classNames(s.dot1, className)} cx={25} cy={30} r={5} />
            <circle className={classNames(s.dot2, className)} cx={65} cy={30} r={5} />
            <circle className={classNames(s.dot3, className)} cx={105} cy={30} r={5} />
        </g>
    </svg>
);

export default DotsLoader;
