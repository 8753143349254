export const FIRST_NAME_REQUIRED = "First Name is a required field";
export const LAST_NAME_REQUIRED = "Last Name is a required field";
export const EMAIL_REQUIRED = "Email is a required field";
export const CODE_REQUIRED = "Code is a required field";
export const VALID_EMAIL = "Please enter a valid email address.";
export const PASSWORD_REQUIRED = "Password is a required field";
export const PASSWORD_MIN_CHAR = PASSWORD_MINIMUM_LENGTH =>
    `Password must be at least ${PASSWORD_MINIMUM_LENGTH} characters`;
export const CONFIRM_PASSWORD_REQUIRED = "Confirm Password is a required field";
export const CONFIRM_PASSWORD_MIN_CHAR = PASSWORD_MINIMUM_LENGTH =>
    `Confirm Password must be at least ${PASSWORD_MINIMUM_LENGTH} characters`;
export const CONFIRM_PASSWORD_DOESNT_MATCH = "Confirm Password doesn't match";
export const PRIVACY_CHECKED = "Privacy Policy must be checked";
export const EMAIL_ALREADY_EXISTS = "Email already exists";
export const DATE_OF_ENTRY_REQUIRED = "Date of entry is a required field";
export const COUNTRY_REQUIRED = "Country is a required field";
export const DOMESTIC_REGION_REQUIRED = "Domestic region is a required field";
export const DEGREE_LEVEL_REQUIRED = "Degree level is a required field";
export const DEGREES_REQUIRED = "Area of study is a required field";
export const DATE_OF_BIRTH_REQUIRED = "Date of birth is a required field";
export const LANGUAGE_PREFERENCE_REQUIRED = "Language preference is a required field";
