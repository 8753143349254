import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import compose from "lodash/flowRight";
import {withApollo} from "react-apollo";
import {string} from "yup";
import {clearAuth, setFlow, setRedirectUrl} from "shared/Auth/actions/authActions";
import Auth from "shared/Auth/auth";
import withSSOAuth from "shared/Auth/withSSOAuth/withSSOAuth";
import SubmitButton from "shared/Experimental/Forms/components/SubmitButton/SubmitButton";
import Form from "shared/Experimental/Forms/components/Form/Form";
import FormGroup from "shared/Experimental/Forms/components/FormGroup/FormGroup";
import TextInput from "shared/Experimental/Forms/components/TextInput/TextInput";
import {config} from "shared/ConfigManager/ConfigManager";
import {
    SSO_REDIRECT_TIMEOUT as REDIRECT_TIMEOUT,
    AUTH_STATE_UNKNOWN as UNKNOWN,
    AUTH_STATE_NON_SSO as NON_SSO,
    AUTH_STATE_SSO as SSO,
    ACVL_APPLICATION_ID,
} from "shared/Utils/Constants";
import removeTrailingSlash from "shared/Utils/removeTrailingSlash";
import ErrorMessage from "shared/Experimental/Forms/components/ErrorMessage/ErrorMessage";
import PageTitle from "../../shared/components/PageTitle/PageTitle";
import {Panel, PanelBody} from "../../shared/components/Panel";
import loginStyles from "./Login.pcss";

export class LoginComponent extends Component {
    state = {
        hasRedirectBump: false,
    };

    componentDidMount () {
        const {isSSOAuthEnabled} = this.props;

        if (isSSOAuthEnabled) {
            this.handleAuthFlow()
                .catch((e) => {
                    console.error(e);
                });
        }
    }

    async handleAuthFlow () {
        const {authState} = this.props;
        const flow = authState?.flow ?? UNKNOWN;

        if (flow === NON_SSO) return;

        if (flow === SSO) {
            const redirectUrl = authState?.redirectUrl;

            if (redirectUrl) {
                this.handleRedirectTo(redirectUrl);
                return;
            }
        }

        const url = `${removeTrailingSlash(config.GATEWAY_URL)}/acvl/clients/${ACVL_APPLICATION_ID}-/redirect-url`;
        const response = await fetch(url, {credentials: "include"});

        if (response.status === 200) {
            // Put the `SSO` flow into the store
            this.props.dispatch(setFlow(SSO));

            // Response data has the redirect URL
            const {
                redirect_url: redirectUrl,
            } = await response.json();

            // Put the redirect URL into the store
            this.props.dispatch(setRedirectUrl(redirectUrl));

            // Redirect to the redirect URL
            this.handleRedirectTo(redirectUrl);
            return;
        }

        if (response.status === 404) {
            // Put the `NON-SSO` flow into the store
            this.props.dispatch(setFlow(NON_SSO));
        }
    }

    handleRedirectTo (redirectUrl) {
        if (REDIRECT_TIMEOUT) {
            this.setState({hasRedirectBump: true}, () => {
                setTimeout(() => {
                    window.location.replace(redirectUrl);
                }, REDIRECT_TIMEOUT);
            });
            return;
        }

        window.location.replace(redirectUrl);
    }

    async handleSubmit(values, actions) {
        try {
            const pathname = this.props.location?.pathname;
            await Auth.login(
                this.props.client,
                this.props.authState,
                this.props.dispatch,
                values.email,
                values.password,
                pathname,
                "ABRA_URL",
                true,
            );
            actions.setSubmitting(false);
            // error handling
            const {authState} = this.props;
            if (!Auth.loggedIn(authState)) {
                actions.setFieldError("serverError", "Either username or password is wrong");
            } else {
                const isUniversity = Auth.loggedIn(authState, "university");
                if (!isUniversity) {
                    actions.setFieldError("serverError", "Only university users can login here.");
                    this.props.dispatch(clearAuth());
                } else {
                    const {hostname} = window.location;
                    const isUITest = hostname === "127.0.0.1" || hostname === "localhost";
                    if (isUITest) {
                        this.props.history.replace("/blank");
                    } else {
                        this.props.history.replace("/");
                    }
                }
            }
        } catch (error) {
            actions.setSubmitting(false);
            const {graphQLErrors: [{message = ""} = {}] = []} = error;
            if (message.includes("locked")) this.props.history.push("/auth/reset/accountlocked");
            else actions.setFieldError("serverError", message);
        }
    }

    hasLoginForm () {
        const {isSSOAuthEnabled} = this.props;
        if (isSSOAuthEnabled) {
            const {authState} = this.props;
            const flow = authState?.flow ?? UNKNOWN;

            //  ============================================================
            //  Flow          Meaning         Decision
            //  ============================================================
            //  UNKNOWN       Pre-ACVL        NO. Request ACVL
            //  SSO           Post ACVL       NO. Redirect to SSO login
            //  NON_SSO       Post ACVL       YES. Show the NON-SSO login

            return (flow === NON_SSO);
        }

        return true;
    }

    renderRedirectBump () {
        return (
            <Panel className={loginStyles.redirectBump}>
                <PanelBody>
                    <div className="text-left">
                        We shall now redirect you to your authentication provider
                    </div>
                </PanelBody>
            </Panel>
        );
    }

    renderLoginForm () {
        return (
            <Panel className={loginStyles.loginForm}>
                <PanelBody>
                    <Form
                        id="login-form"
                        data-test-id="login-form"
                        initialValues={{email: "", password: "", serverError: ""}}
                        validationSchema={{
                            email: string()
                                .email()
                                .required(),
                            password: string().required(),
                        }}
                        onSubmit={this.handleSubmit.bind(this)}
                    >
                        <FormGroup id="email" label="Email">
                            <TextInput name="email" type="email" placeholder="Email Address" />
                        </FormGroup>

                        <FormGroup id="password" label="Password">
                            <TextInput name="password" type="password" placeholder="Password" />
                        </FormGroup>
                        <SubmitButton block color="primary" id="login" type="submit">
                            Login
                        </SubmitButton>
                        <ErrorMessage className={loginStyles.serverError} name="serverError" />
                        <hr />
                        <div className="text-right">
                            <Link id="resetPassword" to="/auth/reset">
                                Forgot your password?
                            </Link>
                        </div>
                    </Form>
                </PanelBody>
            </Panel>
        );
    }

    render() {
        const {hasRedirectBump} = this.state;
        let content = null;
        if (hasRedirectBump) {
            content = this.renderRedirectBump();
        } else if (this.hasLoginForm()) {
            content = this.renderLoginForm();
        }

        return (
            <div className={loginStyles.container}>
                <PageTitle
                    className={loginStyles.title}
                    title="Unibuddy University Login"
                    skipLinkId="main-content"
                />
                {content}
            </div>
        );
    }
}

const mapStateToProps = ({authState, me}) => ({
    authState,
    me,
});

export default compose(
    connect(mapStateToProps),
    withSSOAuth,
    withApollo,
)(LoginComponent);
