import gql from "graphql-tag";

const resetPasswordMutation = gql`
    mutation ResetPasswordMutation($password: String, $token: String, $userId: String) {
        resetPasswordV2(userId: $userId, password: $password, token: $token) {
            success
            redirectUrl
            error
        }
    }
`;

export default resetPasswordMutation;
