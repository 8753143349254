import React from "react";
import PropTypes from "prop-types";

const LogoWithoutTextSVG = ({logoColor}) => {
    return (
        <svg viewBox="0 0 232 206" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>{"Unibuddy"}</title>
            <g fillRule="evenodd">
                <path
                    d="M48.6 108.4L102.8 103.7V0.0999756H0V102.9C0 159.7 46 205.7 102.8 205.7V166.5C74.2 162 51.2 138.5 48.6 108.4ZM64.4 51.4C71.5 51.4 77.3 57.2 77.3 64.3C77.3 71.4 71.5 77.2 64.4 77.2C57.3 77.2 51.5 71.4 51.5 64.3C51.5 57.2 57.3 51.4 64.4 51.4Z"
                    fill={logoColor}
                />
                <path
                    d="M128.5 0.0999756V101.4L177.1 97.1C179.9 129.2 158.8 157.8 128.5 165.3V205.7C185.3 205.7 231.3 159.7 231.3 102.9V0.0999756H128.5ZM167.1 77.0999C160 77.0999 154.2 71.2999 154.2 64.1999C154.2 57.0999 160 51.2999 167.1 51.2999C174.2 51.2999 180 57.0999 180 64.1999C180 71.2999 174.2 77.0999 167.1 77.0999Z"
                    fill={logoColor}
                />
            </g>
        </svg>
    );
};

LogoWithoutTextSVG.propTypes = {
    logoColor: PropTypes.string,
};

LogoWithoutTextSVG.defaultProps = {
    logoColor: "#01D4CF",
};

export default LogoWithoutTextSVG;
