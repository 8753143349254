import gql from "graphql-tag";

const universityLogoQuery = gql`
    query university($universityId: String) {
        university(id: $universityId) {
            id
            name
            nickname
            slug
            logo
            squareLogo
            whiteLogo
        }
    }
`;

export default universityLogoQuery;
