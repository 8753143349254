import React from "react";
import {useLocation} from "react-router-dom";
import {useQuery} from "react-apollo";
import styled, {css} from "styled-components";
import {Box} from "@unibuddy/patron";
import universityLogoQuery from "./UniversityLogoQuery";
import InstitutionNavContent from "./InstitutionNavContent";

const StyledNavBar = styled.aside`
    ${props =>
        props.isOpen &&
        css`
            @media (min-width: 360px) and (max-width: 1023px) {
                background-color: #404446;
                position: fixed;
                left: 0;
                top: 0;
                bottom: 0;
                width: 315px;
                overflow: auto;
                z-index: 10;
                transition: all 1s ease-in;
            }
        `}

    ${props =>
        props.isOpen &&
        css`
            @media (min-width: 200px) and (max-width: 359px) {
                background-color: #404446;
                position: fixed;
                left: 0;
                top: 0;
                bottom: 0;
                width: 250px;
                overflow: auto;
                z-index: 10;
                transition: all 1s ease-in;
            }
        `}

    ${props =>
        !props.isOpen &&
        css`
            @media (max-width: 1023px) {
                display: none;
                transition: all 1s ease-in;
            }
        `}

@media(min-width: 1024px) {
        background-color: #404446;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        width: 315px;
        overflow: auto;
        z-index: 10;
    }
`;
// for collapsed institution navbar
const CollapsedNavBar = styled.aside`
    ${props =>
        props.isOpen &&
        css`
            @media (max-width: 1023px) {
                background-color: #404446;
                position: fixed;
                left: 0;
                top: 0;
                bottom: 0;
                width: 59px;
                overflow: auto;
                z-index: 100;
            }
        `}

    ${props =>
        !props.isOpen &&
        css`
            @media (max-width: 1023px) {
                display: none;
            }
        `}

    @media(min-width: 1024px) {
        background-color: #404446;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        width: 59px;
        overflow: auto;
        z-index: 100;
    }
`;

// Institution nav bar component
function InstitutionNavBar({university, memberOrganisations, isOpen, isEnterpriseNavOpen}) {
    const result = useQuery(universityLogoQuery, {
        variables: {
            universityId: university.id,
        },
    });
    const currentPath = useLocation().pathname;
    const isUniversityView = currentPath.indexOf("/enterprise-management-tools") === -1;
    const navContent = (
        <Box backgroundColor="#404446" color="white" width="315px" height="100%">
            <InstitutionNavContent
                university={university}
                logo={result}
                organisations={memberOrganisations}
            />
        </Box>
    );

    if (isUniversityView) {
        return (
            <CollapsedNavBar data-test-id="institution-nav-bar" isOpen={isOpen}>
                {navContent}
            </CollapsedNavBar>
        );
    }
    return (
        <StyledNavBar data-test-id="institution-nav-bar" isOpen={isEnterpriseNavOpen}>
            {navContent}
        </StyledNavBar>
    );
}

export default InstitutionNavBar;
