import React, {useState} from "react";
import {Box, Spinner} from "@unibuddy/patron";
import {useLocation} from "react-router";

const ResourceLibraryPage = () => {
  const [isLoading, setLoading] = useState(true);
  const {search} = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const iframeUrl = `https://resource-library.unibuddy.co${query.get(
    "iframeURL",
  ) || ""}`;
  return (
    <Box h={"100vh"} paddingX={"medium"}>
      {isLoading && <Spinner />}
      <iframe
        onLoad={() => setLoading(false)}
        height="100%"
        width="100%"
        src={iframeUrl}
        frameBorder="0"
        data-test-id="resource-library-iframe"
      />
    </Box>
  );
};

export default ResourceLibraryPage;
