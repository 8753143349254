export const AUTH_TRANSFER_READY = "AUTH_TRANSFER_READY";
export const AUTH_TRANSFER = "AUTH_TRANSFER";
export const AUTH_CLEARED = "AUTH_CLEARED";
export const IS_EMBEDDED = window !== window.parent;
export const TRANSFER_PARENT_PARAMS = "TRANSFER_PARENT_PARAMS";
export const MIXPANEL = "mixpanel";

// SSO
export const IS_SSO_ENABLED = "ub-university-sso";
export const IS_SSO_ENABLED_DEFAULT_VALUE = false;
export const SSO_REDIRECT_TIMEOUT = 2250;
export const AUTH_STATE_UNKNOWN = "UNKNOWN";
export const AUTH_STATE_NON_SSO = "NON_SSO";
export const AUTH_STATE_SSO = "SSO";
export const ACVL_APPLICATION_ID = "university";

export const COOKIE_CATEGORIES_VALUES = {
    ALL: "all",
    NECESSARY: "necessary",
    PERFORMANCE: "performance",
    ANALYTICS: "analytics",
    MARKETING: "marketing",
    FUNCTIONAL: "functional",
    STATISTICS: "statistics",
    TARGETING: "targeting",
    SOCIAL: "social",
};

export const COOKIE_CATEGORIES = new Map([
    [COOKIE_CATEGORIES_VALUES.ALL, [MIXPANEL]],
    [COOKIE_CATEGORIES_VALUES.NECESSARY, []],
    [COOKIE_CATEGORIES_VALUES.PERFORMANCE, []],
    [COOKIE_CATEGORIES_VALUES.ANALYTICS, [MIXPANEL]],
    [COOKIE_CATEGORIES_VALUES.MARKETING, []],
    [COOKIE_CATEGORIES_VALUES.FUNCTIONAL, []],
    [COOKIE_CATEGORIES_VALUES.STATISTICS, [MIXPANEL]],
]);

// Danger : Don't change this Map. This is based on one trust cookie categories.
export const ONE_TRUST_COOKIE_LIST_ID_MAP = {
    C0001: COOKIE_CATEGORIES_VALUES.NECESSARY,
    C0002: COOKIE_CATEGORIES_VALUES.PERFORMANCE,
    C0003: COOKIE_CATEGORIES_VALUES.FUNCTIONAL,
    C0004: COOKIE_CATEGORIES_VALUES.TARGETING,
    C0005: COOKIE_CATEGORIES_VALUES.SOCIAL,
    UNI01: COOKIE_CATEGORIES_VALUES.ANALYTICS,
};

export const AccountRoles = {
    APPLICANT: "Prospect",
    STUDENT: "Student",
    AMBASSADOR: "Ambassador",
    UNIVERSITY_ADMIN: "University Admin",
    UNIBUDDY_ADMIN: "Unibuddy Admin",
};
