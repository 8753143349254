import React from "react";
import {Query} from "react-apollo";
import {MINUTES} from "shared/Utils/DateUtils";
import MODERATION_COUNT_QUERY from "./query";

const ModerationCountQuery = ({render}) => (
    <Query query={MODERATION_COUNT_QUERY} asyncMode pollInterval={5 * MINUTES}>
        {({data, error, loading}) => {
            if (loading || error) {
                return render({count: 0});
            }
            return render({count: data.universityBlockReportsCount});
        }}
    </Query>
);

export default ModerationCountQuery;
