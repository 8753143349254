import React from "react";
import PasswordResetRequested from "../../../../shared/PasswordReset/components/PasswordResetRequested/PasswordResetRequested";
import ContentLayout from "../../components/ContentLayout/ContentLayout";

const RequestResetPasswordCompleteLayout = () => (
    <ContentLayout>
        <PasswordResetRequested loginLink="/auth/login" />
    </ContentLayout>
);

export default RequestResetPasswordCompleteLayout;
