import React from "react";
import {connect} from "react-redux";
import compose from "lodash/flowRight";
import get from "lodash/get";
import {HasUniversityFeature} from "shared/Feature/HasUniversityFeature";
import withAuth from "../../../shared/Auth/withAuth/withAuth";
import {COMMUNITY_STAND_ALONE} from "../../Utils/FeatureFlagConstants";
import MainNavBar from "./MainNavBar";
import CommunityStandAloneNavBar from "../../CommunityStandAlone/NavBar";

export const Navbar = props => {
    const universitySlug = get(props, "me.universityUser.university.slug");
    return (
        <HasUniversityFeature
            feature={COMMUNITY_STAND_ALONE}
            universitySlug={universitySlug}
            fallback={<MainNavBar {...props} />}
        >
            <CommunityStandAloneNavBar {...props} />;
        </HasUniversityFeature>
    );
};

export default compose(
    connect(),
    withAuth,
)(Navbar);
