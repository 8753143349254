// Action types

export const SET_CONNECTION_STATE = "SET_CONNECTION_STATE";
export const SET_PUSHER_OBJECT = "SET_PUSHER_OBJECT";

// Action creators

export function setConnectionState(connectionState) {
    return {
        type: SET_CONNECTION_STATE,
        connectionState,
    };
}

export function setPusherObject(pusher) {
    return {
        type: SET_PUSHER_OBJECT,
        pusher,
    };
}
