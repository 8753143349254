import React from "react";
import {config} from "shared/ConfigManager/ConfigManager";
import {ArrowLeft, Box, Stack, TextBlock} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import RemoteComponent from "../../Utils/RemoteComponent";
import {COLORS} from "../../ProspectNextSteps/utils/constants";

export type ProfileRoutesProps = {
    authState: Record<string, any>;
    rootUrl?: string;
};

const {STRONG_BLUE} = COLORS;
const StyledLink = styled(TextBlock)`
    cursor: pointer;
    &:hover {
        color: ${STRONG_BLUE};
        text-decoration-line: underline;
        text-decoration-color: ${STRONG_BLUE};
    }
`;
const LegacyNarrowContainer = styled.div`
    margin: 0 auto;
    max-width: 640px;
    padding: 0 15px;
`;
const ProfileRoutes = (props: ProfileRoutesProps) => {
    const {events} = useAnalytics();
    const remoteScope = "remote.js";
    const profileRemoteUrl = config.AMBASSADOR_PROFILE_FEDERATED_MODULES_APP_URL as string;
    const url = `${profileRemoteUrl}/${remoteScope}`;
    return <Box paddingY={"xlarge"}>
        <Stack space={"small"}>
            <LegacyNarrowContainer className={""}>
                <NavLink aria-label={"Go back to manage buddies"} to={"/buddies/students"}>
                    <StyledLink size={"medium"} weight={400} align={"bottom"}><ArrowLeft/> &nbsp;{"Manage Buddies"}
                    </StyledLink>
                </NavLink>
            </LegacyNarrowContainer>
            <RemoteComponent
                url={url}
                remoteScope="ambassador_profile"
                remoteModule="AmbassadorProfile"
                nestedProps={{
                    events: {events},
                    authState: props.authState,
                    path: {rootURL: props.rootUrl},
                    env: {
                        GATEWAY_URL: config.GATEWAY_URL,
                        ASSETS_URL: config.ASSETS_URL,
                        UB_LAUNCHDARKLY_CLIENT_ID: config.UB_LAUNCHDARKLY_CLIENT_ID,
                    },
                }}
            />
        </Stack>
    </Box>;
};

export default ProfileRoutes;
