import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {
  View,
  Form,
  Stack,
  Inline,
  Heading,
  Tooltip,
  Toggle,
  Button,
  useLocalStorage,
  Box,
  TextInput,
  TextBlock,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@unibuddy/patron";
import useErrorReporting from "shared/General/components/ErrorReporting/useErrorReporting";
import styled from "styled-components";
import {useBooleanFeatureFlag} from "ub-feature-flagging-react";

/**
 * This page is there to enable or disable toggles for developers and testers
 */
export const VIDEO_DEBUG_ACTIVE = "VIDEO_DEBUG_ACTIVE";
export const VIDEO_RECORDING_ENABLED = "VIDEO_RECORDING_ENABLED";
export const VIDEO_ERROR_RECORDING_ENABLED = "VIDEO_ERROR_RECORDING_ENABLED";
export const MANUAL_RECORDING_ENABLED = "MANUAL_RECORDING_ENABLED";
export const EVENTS_ADMIN_MODULE_FEDERATION_ENABLED =
  "virtual-events-admin-module-federation";
export const CHAT_ROOMS_ENABLED = "virtual-event-chat-rooms";
export const IS_LAUNCHDARKLY_ACTIVE = "launchdarkly-active";

/**
 * @param storageParam //string constant
 * @returns value
 */
export function useDevTools(storageParam = "VIDEO_DEBUG_ACTIVE") {
  const [value] = useLocalStorage(storageParam, false);
  const isEnabledInLaunchdarkly = useBooleanFeatureFlag(storageParam, false);
  if (storageParam === VIDEO_RECORDING_ENABLED) return true;
  return value || isEnabledInLaunchdarkly;
}
const ActiveSign = styled.div`
  height: 15px;
  width: 15px;
  margin-left: 15px;
  background-color: green;
  border-radius: 15px;
`;

const InactiveSign = styled.div`
  height: 15px;
  width: 15px;
  background-color: grey;
  margin-left: 15px;
  border-radius: 15px;
`;
/**
 *
 * This function is only here to hardcode values of local storage for feature we've
 * enabled in that way pre-launchdarkly
 *
 * @param feature
 * @param defaultValue
 * @returns
 */
function useFeatureStorage(feature, defaultValue) {
  const [isActive, setIsActive] = useLocalStorage(feature, defaultValue);
  if (feature === VIDEO_RECORDING_ENABLED) {
    return [true, () => {}];
  }
  return [isActive, setIsActive];
}

const FeatureTableRow = ({feature, featureDescription, disabledEdit}) => {
  const isEnabledInLaunchdarkly = useBooleanFeatureFlag(feature, false);
  const [localFeature, setLocalFeature] = useFeatureStorage(feature, false);
  return (
    <TableRow>
      <TableCell align="left">{featureDescription}</TableCell>
      <TableCell align="center">
        <Form initialValues={{[feature]: localFeature}}>
          <Tooltip label={featureDescription}>
            {/* Tooltips only work on div elements or components with a forwardRef */}
            <Box>
              <Toggle
                disabled={disabledEdit}
                name={feature}
                onChange={() => {
                  setLocalFeature(!localFeature);
                }}
              />
            </Box>
          </Tooltip>
        </Form>
      </TableCell>
      <TableCell align="center">
        <Box>{isEnabledInLaunchdarkly ? <ActiveSign /> : <InactiveSign />}</Box>
      </TableCell>
      <TableCell align="center">
        <Box>
          {isEnabledInLaunchdarkly || localFeature ? (
            <ActiveSign />
          ) : (
            <InactiveSign />
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};
const FeatureTable = () => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader align="left">Feature</TableHeader>
          <TableHeader align="left">Local</TableHeader>
          <TableHeader align="left">Launchdarkly</TableHeader>
          <TableHeader align="left">App</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        <FeatureTableRow
          feature={VIDEO_RECORDING_ENABLED}
          featureDescription="Video recording (automatic)"
          disabledEdit
        />
        <FeatureTableRow
          feature={EVENTS_ADMIN_MODULE_FEDERATION_ENABLED}
          featureDescription="Enable Microsite Admin Module Federation"
        />
      </TableBody>
    </Table>
  );
};

export default function DevTools() {
  const isLaunchdarklyActive = useBooleanFeatureFlag(
    IS_LAUNCHDARKLY_ACTIVE,
    false,
  );
  const [errorValue, setErrorValue] = useState("");

  const history = useHistory();
  const {reportError} = useErrorReporting();

  const generateTestError = () => {
    const err = new Error(errorValue);
    reportError(err);
    setErrorValue("");
  };

  return (
    <View flex="1" minH={0} minW={0}>
      <View
        bgColor="grey50"
        padding="medium"
        h={60}
        justifyContent="center"
        borderBottomWidth={1}
        borderColor="grey150"
      >
        <Inline space="small" verticalAlign="center">
          <Button onClick={() => history.goBack()}>Back</Button>
          <Heading level="1" size="xsmall" weight="700">
            Dev Tools
          </Heading>
        </Inline>
      </View>
      <View
        flex="1"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        paddingTop="large"
      >
        <TextBlock>Lauchdarkly active</TextBlock>{" "}
        <View>{isLaunchdarklyActive ? <ActiveSign /> : <InactiveSign />}</View>
      </View>
      <View
        flex="1"
        alignItems="center"
        justifyContent="center"
        paddingTop="large"
      >
        <View
          w={["full", "75%", 600]}
          padding={["large", "xlarge"]}
          flex="1"
          borderRadius="small"
          shadow="small"
        >
          <Stack space="medium">
            <Heading level="1" size="xsmall" weight="700">
              Feature Flags
            </Heading>
            <FeatureTable />
            <hr />
            <Heading level="2" size="xsmall" weight="700">
              Other Tools
            </Heading>
            <TextBlock>
              Send a test error to Sentry - include some text if you want (this
              doesn&apos;t work on localhost):
            </TextBlock>
            <TextInput
              value={errorValue}
              onChange={e => setErrorValue(e.target.value)}
            />
            <Button onClick={generateTestError}>Send error to Sentry</Button>
          </Stack>
        </View>
      </View>
    </View>
  );
}
