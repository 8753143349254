import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "@unibuddy/intl";
import s from "./PasswordResetRequested.pcss";
import PageHeader from "../../../Shared/components/PageHeader/PageHeader";

const PasswordResetRequested = props => {
    const {t} = useTranslation("passwordReset");

    return (
        <div className={s.container} id="requestPasswordResetComplete">
            <div className={s.header}>
                <PageHeader title={t("resetRequested", "Reset Requested")} />
                {t("resetSent", "We have sent you an email to reset your password.")}{" "}
                <a href={props.loginLink}>{t("loginHere", "Login here")}</a>
            </div>
        </div>
    );
};

PasswordResetRequested.propTypes = {
    loginLink: PropTypes.string.isRequired,
};

export default PasswordResetRequested;
