import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import PageHeader from "../../../Shared/components/PageHeader/PageHeader";
import s from "./ResetPasswordComplete.pcss";

/**
 We use an <a> rather than a <Link> here because react router
 treats fully qualified urls (including the domain) as if they
 are relative paths (which they are not!)
 */
const ResetPasswordComplete = props => (
    <div>
        <div className="container-fluid" id="resetPasswordComplete">
            <Row>
                <Col mdOffset={3} md={6} smOffset={2} sm={8} xs={12} className="m-t-sm">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <Row>
                                <Col xs={12} className={s.panel}>
                                    <PageHeader title="Password Reset" />
                                    <div>
                                        Your password has been reset. You may now&nbsp;
                                        <a id="continue" href={props.redirectUrl}>
                                            login
                                        </a>
                                        &nbsp;with your new password.
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
);

ResetPasswordComplete.propTypes = {
    redirectUrl: PropTypes.string.isRequired,
};

export default ResetPasswordComplete;
