import * as React from "react";
import {useQuery} from "react-apollo";
import GetUniversityQuery from "shared/University/queries/GetUniversityQuery";
import {Box, Spinner} from "@unibuddy/patron";
import {UniversityField} from "../../types/generated";

const UniversityContext = React.createContext({});

export const UniversityProvider = ({children, slug}) => {
    const {data, loading, error} = useQuery(GetUniversityQuery, {
        variables: {
            slug,
        },
    });

    if (loading) {
        return <Box><Spinner/></Box>;
    }
    if (error) {
        return <div>Unable to fetch University data. Please try after sometime</div>;
    }

    const university = data?.university;
    return <UniversityContext.Provider value={university}>{children}</UniversityContext.Provider>;
};

export const useUniversity = (): UniversityField => React.useContext(UniversityContext);
