import React from "react";
import RequestPasswordReset from "../../../../shared/PasswordReset/containers/RequestPasswordReset/RequestPasswordReset";
import ContentLayout from "../../components/ContentLayout/ContentLayout";

const RequestPasswordResetLayout = ({history}) => (
    <ContentLayout>
        <RequestPasswordReset
            themePrimary="3091F2"
            loginLink="/auth/login"
            onComplete={() => history.push("/auth/reset/requested")}
            rootUrl="ABRA_URL"
        />
    </ContentLayout>
);

export default RequestPasswordResetLayout;
