import gql from "graphql-tag";

const getMemberOrganisations = gql`
    query getMemberOrganisations {
        memberOrganisations {
            id
            name
            linkedInstance
        }
    }
`;

export default getMemberOrganisations;
