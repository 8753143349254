import React from "react";
import compose from "lodash/flowRight";
import withAuth from "shared/Auth/withAuth/withAuth";
import {Route, Switch, Redirect} from "react-router-dom";

const MentorPage = React.lazy(() => import(/* webpackChunkName: "buddy" */ "./MentorPage"));

export const BuddyPageRoutes = props => (
    <div id="buddypageroutes-page">
        <div>
            <Switch>
                <Route
                    path={`${props.mainRoute}/buddies/student/:status`}
                    render={renderProps => (
                        <MentorPage
                            enterpriseNavOpen={props.enterpriseNavOpen}
                            toggle={props.toggle}
                            {...props}
                            {...renderProps}
                        />
                    )}
                />
                <Route
                    path={`${props.mainRoute}/buddies/student`}
                    render={renderProps => (
                        <MentorPage
                            enterpriseNavOpen={props.enterpriseNavOpen}
                            toggle={props.toggle}
                            {...props}
                            {...renderProps}
                        />
                    )}
                />
                <Route
                    path={`${props.mainRoute}/buddies`}
                    render={() => <Redirect to={`${props.mainRoute}/buddies/student`} />}
                />
            </Switch>
        </div>
    </div>
);

export default compose(withAuth)(BuddyPageRoutes);
