import * as React from "react";
import {SVGProps} from "react";

const IconPuzzle = (props: SVGProps<SVGSVGElement>& {title?: string}) => {
    const {color = "#131622", title, ...rest} = props;
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={21}
        height={21}
        fill="none"
        role="img"
        {...rest}
    >
        <title>{title ?? "Integrations"}</title>
        <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M13.25 5.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875S9.5 2.09 9.5 3.125c0 .369.128.713.349 1.003.215.283.401.604.401.959a.641.641 0 0 1-.657.643c-1.391-.04-2.78-.14-4.163-.3a48.44 48.44 0 0 1 .315 4.907.656.656 0 0 1-.658.663c-.355 0-.676-.186-.959-.401a1.647 1.647 0 0 0-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401.31 0 .555.26.532.57a48.042 48.042 0 0 1-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 0 0 .657-.643c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959 0 .333.277.599.61.58a48.086 48.086 0 0 0 5.427-.63c.272-1.562.466-3.136.582-4.717a.533.533 0 0 0-.533-.57c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401a.655.655 0 0 0 .658-.663 48.42 48.42 0 0 0-.37-5.36 48.14 48.14 0 0 1-5.766.689.578.578 0 0 1-.61-.579Z"
        />
    </svg>;
};
export default IconPuzzle;
