import React, {cloneElement} from "react";
import styled from "styled-components";
import spaceMap from "../../utils/spaceMap";
import alignMap from "../../utils/alignMap";
import verticalAlignMap from "../../utils/verticalAlignMap";
import spacePropType from "../../utils/spacePropType";
import alignPropType from "../../utils/alignPropType";
import flexWrapPropType from "../../utils/flexWrapPropType";
import flexWrapMap from "../../utils/flexWrapMap";
import verticalAlignPropType from "../../utils/verticalAlignPropType";
import mediaQueries from "../../utils/mediaQueries";
import mapResponsiveProp, {mapResponsivePropAndInverse} from "../../utils/mapResponsiveProp";
import mapRenderableChildren from "../../utils/mapRenderableChildren";

const Container = styled.div`
    padding-top: 1px;
    &:before {
        ${({space}) => {
            return mediaQueries({
                marginTop: mapResponsivePropAndInverse(space, spaceMap),
            });
        }}
        content: "";
        display: block;
    }
`;

const InlineInner = styled.div`
    display: flex;
    flex-wrap: wrap;
    ${({align, verticalAlign, space, wrap}) =>
        mediaQueries({
            justifyContent: mapResponsiveProp(align, alignMap),
            alignItems: mapResponsiveProp(verticalAlign, verticalAlignMap),
            marginLeft: mapResponsivePropAndInverse(space, spaceMap),
            flexWrap: mapResponsiveProp(wrap, flexWrapMap),
        })};
`;

export const InlineItem = styled.div`
    /* https://css-tricks.com/flexbox-truncated-text/ */
    min-width: 0;
    ${({flexShrink, flexGrow}) => ({flexShrink, flexGrow})};

    & > * {
        margin: 0;
    }

    ${({space}) => {
        const value = mapResponsiveProp(space, spaceMap);
        return mediaQueries({
            marginLeft: value,
            marginTop: value,
        });
    }}
`;

/**
 * The Inline layout primitive injects margin between elements via their common parent
 * Read ../../README.md for more details and example use cases.
 *
 * We use an attribute selector [data-ub-layout-inline] for higher specificity, so we
 * can easily overwrite margins for children.
 *
 * @export
 * @param {React.Props} {props}
 * @param {String} {props.align}
 * @param {String} {props.space}
 * @param {import("react").ReactElement} {props.children}
 * @returns
 */
function Inline({children, align, space, verticalAlign, wrap}) {
    /* eslint-disable react/no-array-index-key */
    return (
        <Container space={space}>
            <InlineInner
                data-ub-layout-inline
                data-test-id="layout-inline-inner"
                align={align}
                space={space}
                verticalAlign={verticalAlign}
                data-space={space}
                wrap={wrap}
            >
                {mapRenderableChildren(children, (child, index) => {
                    if (child.type === InlineItem) {
                        return cloneElement(child, {key: index, space});
                    }
                    return (
                        <InlineItem data-test-id="layout-inline-item" key={index} space={space}>
                            {child}
                        </InlineItem>
                    );
                })}
            </InlineInner>
        </Container>
    );
    /* eslint-enable react/no-array-index-key */
}

Inline.propTypes = {
    align: alignPropType,
    space: spacePropType,
    verticalAlign: verticalAlignPropType,
    wrap: flexWrapPropType,
};

Inline.defaultProps = {
    space: "none",
    align: "left",
    verticalAlign: "top",
    wrap: "wrap",
};

export default Inline;
