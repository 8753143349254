/* eslint-disable */
import React, {useEffect, Fragment, Suspense} from "react";
import compose from "lodash/flowRight";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Route, Switch} from "react-router-dom";
import get from "lodash/get";
import {useAnalytics} from "@unibuddy/tracking";
import {GlobalAlert} from "shared/GlobalAlert/components/GlobalAlert";
import {productSpec} from "shared/AnalyticsNew/constants/productSpecification";
import {SupportWidget} from "shared/SupportWidget/components/SupportWidget";
import {SkipNavContent} from "shared/Experimental/A11y/components/SkipNav/SkipNav";
import NavbarComponent from "./modules/Navbar/containers/Navbar";
import AppSidebar from "./modules/App/AppSidebar";
import AllRoutes from "./AllRoutes";

export const AuthenticatedRoutes = compose(
    withRouter,
    connect(({authState}) => ({authState})),
)(({authState, isInstitution, isOpen, toggle}) => (
    <Fragment>
        <AppSidebar isInstitution={isInstitution} isOpen={isOpen} toggle={toggle}>
            <NavbarComponent />
        </AppSidebar>

        <Suspense fallback={<div />}>
            <GlobalAlert />
            <SupportWidget me={authState.me} />
            <SkipNavContent id={"main-content"}>
                <AllRoutes
                    universitySlug={get(authState, "me.universityUser.university.slug")}
                    isInstitution={isInstitution}
                />
            </SkipNavContent>
        </Suspense>
    </Fragment>
));

const AppRoutes = ({authState, isInstitution, isOpen, toggle}) => {
    const {
        events: {productOpened},
    } = useAnalytics();
    useEffect(
        () => {
            productOpened({
                name: productSpec.universityDashboard.NAME,
                product: productSpec.universityDashboard.NAME,
                universitySlug: get(
                    authState,
                    "me.universityUser.university.slug",
                    "Not logged in",
                ),
            });
        },
        [productOpened], // eslint-disable-line react-hooks/exhaustive-deps
    );
    return (
        <Switch>
            <Route
                render={props => (
                    <AuthenticatedRoutes
                        isInstitution={isInstitution}
                        isOpen={isOpen}
                        toggle={toggle}
                        {...props}
                    />
                )}
            />
        </Switch>
    );
};

export default connect(({authState}) => ({authState}))(AppRoutes);
