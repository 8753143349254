/* eslint-disable */
import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {v4 as uid} from "uuid";
import {Platform} from "react-native";
import {Severity} from "@sentry/browser";
import isAvailable from "shared/Utils/PlatformFeatures";
import errorDeviceStorage from "./errorDeviceStorage";

const initialContext = {reportError: console.log};
export const ErrorReportingContext = React.createContext(initialContext);

/**
 * Exposes a method for reporting errors to 3rd party services or internal logging.
 * Typically it should be used at the top of the application tree so it catches most React
 * rendering errors when used together with the ErrorBoundary component.
 * This should be done once per application otherwise we will get duplicate errors. That
 * is why there is a context check in cDM that checks if this component already exists up the tree.
 * Uses the context API to pass the `reportError` functionality down the component tree.
 *
 * @class ErrorReportingProvider
 * @extends {PureComponent}
 */
class ErrorReportingProvider extends PureComponent {
    static propTypes = {
        adaptor: PropTypes.shape({}).isRequired,
    };

    constructor(props) {
        super(props);
        this.uuid = Platform.OS === "web" ? this.getUniqueId() : "unknown";
    }

    static contextType = ErrorReportingContext;

    static duplicateErrorMessage =
        "ErrorReportingProvider component should be used once per application.";
    static duplicateError = new Error(ErrorReportingProvider.duplicateErrorMessage);

    componentDidMount() {
        // try and detect if ErrorReporting already exists up the tree
        if (this.context !== initialContext) {
            console.error(ErrorReportingProvider.duplicateErrorMessage);
            this.reportError(ErrorReportingProvider.duplicateError, {
                level: "warning",
            });
        }

        errorDeviceStorage().then(res => {
            if (res) {
                this.uuid = res;
            }
        });
    }

    /*
     * Because we don't track personal information in error reports (such as ip address),
     * we create a unique ID per user otherwise it's impossible to track how many people
     * are affected by a problem.
     * To attempt to keep the number accurate we store the generated ID in localStorage
     * and then reuse it each time, however if the client blocks access to localStorage
     * we should just generate a new ID for each session.
     */
    getUniqueId = () => {
        const UB_UUID = "UB_UUID";

        if (isAvailable("localStorage")) {
            const existingId = localStorage.getItem(UB_UUID);
            if (existingId) return existingId;

            const newId = uid();
            localStorage.setItem(UB_UUID, newId);
            return newId;
        } else {
            return uid();
        }
    };

    /**
     * @memberof ErrorReportingProvider
     * @param {Error} err - can be any type of Error
     * @param {Object} data - custom data attached to the error payload
     * @param {Object} data.tags - key value pair tags
     * @param {string} data.level - one of: fatal, error, warning, info, or debug (error is default)
     */
    reportError = (err, data) => {
        if (process.env.NODE_ENV === "production") {
            console.error(err);
        }
        this.props.adaptor.captureException(err, {uuid: this.uuid, ...data});
    };

    /**
     * @memberof ErrorReportingProvider
     * @param {String} message - string message to log
     * @param {string} level - one of Sentry.Severity enums (Sentry.Severity.Debug is default)
     */
    logMessage = (message, level = Severity.Debug) => {
        if (!message) {
            return;
        }

        this.props.adaptor.captureMessage(message, level);
    };

    render() {
        return (
            <ErrorReportingContext.Provider
                value={{
                    reportError: this.reportError,
                    logMessage: this.logMessage,
                }}
            >
                {this.props.children}
            </ErrorReportingContext.Provider>
        );
    }
}

export default ErrorReportingProvider;
