import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";
import classNames from "classnames";
import {useAnalytics} from "@unibuddy/tracking";
import {SkipNavContent} from "shared/Experimental/A11y/components/SkipNav/SkipNav";
import s from "./PageTitle.pcss";

const PageTitle = props => {
    const {
        events: {pageViewed},
    } = useAnalytics();
    useEffect(
        () => {
            pageViewed({
                prefix: props.title,
            });
        },
        [pageViewed, props.title],
    );

    const container = props.className ? props.className : s.container;

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Unibuddy - ${props.title}`}</title>
            </Helmet>
            <div data-test-id="page-title-container" className={container} hidden={props.hideTitle}>
                <SkipNavContent id={props.skipLinkId || "content"}>
                    <h1 className={classNames(s.title, props.className)}>{props.title}</h1>
                </SkipNavContent>
                {props.rightAddon && (
                    <div data-test-id="page-title-right-addon" className={s.rightAddon}>
                        {props.rightAddon}
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

PageTitle.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    rightAddon: PropTypes.element,
};

PageTitle.defaultProps = {
    className: undefined,
};

export default PageTitle;
