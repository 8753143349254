import {getConfigFromWindow} from "../tools/build/utils/utils";
import {AnyUserField, UniversityField} from "./types/generated";

type InitialiseVitallyProps = {
    authState: AnyUserField;
    university: UniversityField;
};

const PRODUCTION_ENV_NAME = "production";

// DO NOT MODIFY
export const PROD_UNI_TEST_CONFIG = {
    accountId: "58cd905a612314000452f5dc", // Your Great College in prod
    name: "Your Great College",
    slug: "demo-university",
    avatar: "https://s3-eu-west-1.amazonaws.com/unibudy-prod/5ecceb2fe55cd409d52083ab.png",
    userId: "123412341234123412341234",
    firstName: "Test",
    lastName: "Account",
    email: "test.account@unibuddy.com",
};

const npsCustomisations = {
    productName: "Unibuddy",
    delay: 1000,
    primaryColor: '#0264D4', // blue600 in patron v1 theme
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    npsQuestion: ({productName}) => `How likely is it that you would recommend ${productName}?`,
    followUpTitle: "Thank you for your feedback!",
    followUpSubtitle: ({score}) => {
        return score <= 7 ? `How could Unibuddy improve?` : `What do you and/or your team like about Unibuddy?`;
    },
};

export function initialiseVitallyNPS({authState, university}: InitialiseVitallyProps) {
    if (getConfigFromWindow("ENV_NAME", null).toLowerCase() === PRODUCTION_ENV_NAME) {
        // Initialise university account
        (window as any).Vitally.account({
            accountId: university.id,
            traits: {
                name: university.name,
                slug: university.slug,
                avatar: university.squareLogo,
            },
        });

        // Initialise university user, link with university account
        (window as any).Vitally.user({
            userId: authState.id,
            accountId: university.id,
            traits: {
                firstName: authState.firstName,
                lastName: authState.lastName,
                email: authState.email,
            },
        });

        // "survey" should only be used in production. This checks users against vitally config/playbooks and records responses
        (window as any).Vitally.nps('survey', npsCustomisations);
    } else if ((window as any)?.env?.VITALLY_NPS_TOKEN) {
        // Initialise university account using "Your Great College" prod uni id
        // We have to use a uni that exists in the vitally dashboard in production,
        // otherwise responses are not collected.
        // DO NOT MODIFY THE DETAILS BELOW!
        // Otherwise the uni name/avatar in Vitally will change for the entire account in prod.
        // Vitally has no guards in place to prevent this, and we have local/QA data that could
        // clash with real production university names and cause confusion.
        (window as any).Vitally.account({
            accountId: PROD_UNI_TEST_CONFIG.accountId,
            traits: {
                name: PROD_UNI_TEST_CONFIG.name,
                slug: PROD_UNI_TEST_CONFIG.slug,
                avatar: PROD_UNI_TEST_CONFIG.avatar,

            },
        });

        // Initialise university user using "Your Great College" prod uni id
        (window as any).Vitally.user({
            userId: PROD_UNI_TEST_CONFIG.userId,
            accountId: PROD_UNI_TEST_CONFIG.accountId,
            traits: {
                firstName: PROD_UNI_TEST_CONFIG.firstName,
                lastName: PROD_UNI_TEST_CONFIG.lastName,
                email: PROD_UNI_TEST_CONFIG.email,
            },
        });

        // "test" should be used for testing UI. This bypasses user checks, but DOES NOT record responses
        // "show" should ONLY be used for testing integration. This bypasses user checks,
        // but DOES still record responses to the production dashboard.
        (window as any).Vitally.nps('test', npsCustomisations);
    };
};
