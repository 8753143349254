// / action types

export const SET_INVITE_PAGE_NUMBER = "SET_INVITE_PAGE_NUMBER";
export const SELECT_INVITE = "SELECT_INVITE";
export const DESELECT_INVITE = "DESELECT_INVITE";
export const CLEAR_INVITE_SELECTION = "CLEAR_INVITE_SELECTION";
export const SET_SORT_FIELD = "SET_SORT_FIELD";
export const SET_INVITE_FILTER = "SET_INVITE_FILTER";

// / action creators

export function setPageNumber(pageNumber) {
    return {
        type: SET_INVITE_PAGE_NUMBER,
        pageNumber,
    };
}

export function selectInvite(inviteId) {
    return {
        type: SELECT_INVITE,
        inviteId,
    };
}

export function deselectInvite(inviteId) {
    return {
        type: DESELECT_INVITE,
        inviteId,
    };
}

export function clearInviteSelection() {
    return {
        type: CLEAR_INVITE_SELECTION,
    };
}

export function setSortField(text) {
    return {
        type: SET_SORT_FIELD,
        sortField: text,
    };
}

export function setFilterField(filterKey, filterText) {
    return {
        type: SET_INVITE_FILTER,
        filterText,
        filterKey,
    };
}
