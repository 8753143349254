import React from "react";
import {Route} from "react-router-dom";
import PageTitle from "shared/Shared/components/PageTitle/PageTitle";
import AccountLockedLayout from "./layouts/AccountLockedLayout/AccountLockedLayout";
import RequestPasswordResetCompleteLayout from "./layouts/RequestPasswordResetCompleteLayout/RequestPasswordResetCompleteLayout";
import RequestPasswordResetLayout from "./layouts/RequestPasswordResetLayout/RequestPasswordResetLayout";
import ResetPasswordCompleteLayout from "./layouts/ResetPasswordCompleteLayout/ResetPasswordCompleteLayout";
import ResetPasswordLayout from "./layouts/ResetPasswordLayout/ResetPasswordLayout";

const PasswordResetRoutes = ({match}) => (
    <React.Fragment>
        <PageTitle title="Reset" />
        <Route path={`${match.url}/`} exact component={RequestPasswordResetLayout} />
        <Route path={`${match.url}/user/:userId/token/:token`} component={ResetPasswordLayout} />
        <Route path={`${match.url}/complete`} component={ResetPasswordCompleteLayout} />
        <Route path={`${match.url}/requested`} component={RequestPasswordResetCompleteLayout} />
        <Route path={`${match.url}/accountlocked`} component={AccountLockedLayout} />
    </React.Fragment>
);

export default PasswordResetRoutes;
