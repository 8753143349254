import gql from "graphql-tag";

const universityUserFragment = gql`
    fragment UniversityUserFull on UniversityUserField {
        id
        firstName
        lastName
        university {
            id
            name
            slug
            isFeatureEnabled(feature: "customAttributes")
            marketplaces {
                name
                slug
                id
            }
            country {
                id
                name
            }
        }
        isOnline
        phoneNumber
        preferences {
            newMessageNotifications
        }
        groups {
            type
        }
    }
`;

export default universityUserFragment;
