import * as React from "react";
import {SVGProps} from "react";

const IconCalendar = (props: SVGProps<SVGSVGElement>& {title?: string}) => {
    const {color = "#272B3D", title, ...rest} = props;
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        role="img"
        {...rest}
    >
        <title>{title ?? "Events"}</title>
        <g clipPath="url(#a)">
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M6.75 3.911v2.25m10.5-2.25v2.25M3 19.661V8.411a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 8.411v11.25m-18 0a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25m-18 0v-7.5a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v7.5"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 .911h24v24H0z"/>
            </clipPath>
        </defs>
    </svg>;
};
export default IconCalendar;
