import {config} from "../ConfigManager/ConfigManager";

export default function initialiseZendesk() {
    const script = document.createElement("script");
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${config.ZENDESK_WIDGET_KEY}`;
    script.id = "ze-snippet";
    document.getElementsByTagName("head")[0].appendChild(script);
}

export const hideZendex = () => {
    if (window.zE) {
        window.zE("webWidget", "hide");
    }
};
