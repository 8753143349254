import * as React from "react";
import {SVGProps} from "react";

const IconBinoculars = (props: SVGProps<SVGSVGElement> & {title?: string}) => {
    const {color = "#272B3D", title, ...rest} = props;
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        role={"img"}
        {...rest}
    >
        <title>{title ?? "Marketplace"}</title>
        <path
            fill={color}
            fillRule="evenodd"
            d="M5.5 19.411a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0 1.5a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM3.338 5.911A3.5 3.5 0 0 0 3 7.411h1.454c0-.205.066-.403.134-.595.109-.306.266-.59.496-.82A2.002 2.002 0 0 1 8.502 7.41H10a3.5 3.5 0 0 0-6.662-1.5ZM14.338 5.911a3.5 3.5 0 0 0-.338 1.5h1.498a2.002 2.002 0 0 1 3.418-1.416c.375.376.743 1.365.743 1.896L21 7.41a3.5 3.5 0 0 0-6.662-1.5ZM18.5 19.411a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0 1.5a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z"
            clipRule="evenodd"
        />
        <path
            fill={color}
            fillRule="evenodd"
            d="m1.097 15.476 1.999-8.877 1.492.217-2.124 9.42-1.367-.76ZM22.889 15.411 20.892 6.55l-1.453.362 2.097 9.325 1.352-.825ZM8.5 16.411l.002-9H10v9H8.5ZM14 16.411l-.002-9h1.5l.002 9H14Z"
            clipRule="evenodd"
        />
        <path
            fill={color}
            fillRule="evenodd"
            d="M14 8.911H9v-1h5v1ZM14 12.911H9v-1h5v1ZM14 14.911H9v-1h5v1Z"
            clipRule="evenodd"
        />
    </svg>;
};
export default IconBinoculars;
