import * as React from "react";
import {SVGProps} from "react";

const IconDanger = (props: SVGProps<SVGSVGElement>& {title?: string}) => {
    const {color = "#131622", title, ...rest} = props;
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        role="img"
        {...rest}
    >
        <title>{title ?? "Moderation"}</title>
        <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M11.608 10.659v3.75m-9.304 3.375c-.865 1.5.218 3.375 1.95 3.375h14.708c1.732 0 2.814-1.875 1.949-3.375L13.557 5.037c-.866-1.501-3.032-1.501-3.898 0L2.304 17.784Zm9.304-.375h.007v.007h-.007v-.007Z"
        />
    </svg>;
};
export default IconDanger;
