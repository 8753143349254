import React from "react";
import {
    SkipNavLink as ReachSkipNavLink,
    SkipNavContent as ReachSkipNavContent,
} from "@reach/skip-nav";
import "@reach/skip-nav/styles.css";

export function SkipNavLink(props) {
    return <ReachSkipNavLink {...props} />;
}

export function SkipNavContent(props) {
    return <ReachSkipNavContent tabIndex={-1} {...props} />;
}
