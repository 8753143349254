import React from "react";
import ResetPassword from "../../../../shared/PasswordReset/containers/ResetPassword/ResetPassword";
import ContentLayout from "../../components/ContentLayout/ContentLayout";

const ResetPasswordLayout = props => (
    <ContentLayout>
        <ResetPassword
            set
            token={props.match.params.token}
            userId={props.match.params.userId}
            themePrimary="3091F2"
            onComplete={() => props.history.push("/auth/reset/complete")}
        />
    </ContentLayout>
);

export default ResetPasswordLayout;
