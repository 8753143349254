// action types

export const SET_BLOG_PAGE_NUMBER = "SET_BLOG_PAGE_NUMBER";
export const SET_BLOG_SORT_FIELD = "SET_BLOG_SORT_FIELD";
export const SET_BLOG_POST_ID = "SET_BLOG_POST_ID";

// action creators

export function setPageNumber(pageNumber) {
    return {
        type: SET_BLOG_PAGE_NUMBER,
        pageNumber,
    };
}

export function setSortField(text) {
    return {
        type: SET_BLOG_SORT_FIELD,
        sortField: text,
    };
}

export function setBlogPostId(blogPostId) {
    return {
        type: SET_BLOG_POST_ID,
        blogPostId,
    };
}
