import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import classNames from "classnames";
import IoIosArrowForward from "react-icons/lib/io/ios-arrow-forward";
import {Box, TextBlock} from "@unibuddy/patron";
import focusHeader from "shared/Experimental/A11y/components/SkipNav/FocusHeader";
import s from "./NavbarLink.pcss";
import NavLinkWithFocus from "../NavLinkWithFocus/NavLinkWithFocus";

const getFirstLinkPath = subLinks => {
    return subLinks[0].props.to;
};

export const SubLink = props => (
    <NavLinkWithFocus activeClassName={s.isSelected} className={s.subLink} {...props}>
        {props.children}
    </NavLinkWithFocus>
);

const NavbarLinkComponent = ({
                                 // react-router props start
                                 match,
                                 staticContext,
                                 history,
                                 location,
                                 // react-router props end
                                 subLinks,
                                 icon,
                                 children,
                                 notification,
                                 ...props
                             }) => {
    if (!Array.isArray(subLinks)) {
        return (
            <div className={s.outer}>
                <NavLinkWithFocus
                    className={classNames(s.link, s.standalone)}
                    activeClassName={s.isSelected}
                    aria-label={props.label ?? children}
                    {...props}
                >
                    <span className={s.icon}>{icon}</span>
                    <Box display={"flex"} justifyContent={"space-between"} w={"155px"} className={s.badgeContainer}>
                        <span className={s.linkContent}>{children}</span>
                        {notification ? (
                            <Box
                                bgColor="#AD0101"
                                borderRadius={"small"}
                                paddingX={"xxsmall"}
                                marginBottom={"xxsmall"}
                                className={s.badge}
                                data-test-id="navbar-link-notification"
                            >
                                <TextBlock size={"xxsmall"} color={"white"}>
                                    {notification > 999 ? "999+" : notification}
                                </TextBlock>
                            </Box>
                        ) : null}
                    </Box>

                </NavLinkWithFocus>
            </div>
        );
    }

    // This implies the root page should never have sublinks as
    // they will always remain hidden
    const hasMatch =
        location.pathname === "/"
            ? false
            : subLinks.find(l => location.pathname.includes(l.props.to));
    return (
        <div
            data-test-id="navbar-link-outer"
            className={classNames(s.outer, {
                [s.isSelected]: hasMatch,
            })}
        >
            <Link
                data-test-id="navbar-link"
                className={classNames(s.link, {
                    [s.isSelected]: hasMatch,
                })}
                {...props}
                aria-label={props.label ?? children}
                to={getFirstLinkPath(subLinks)}
                onClick={() => focusHeader("content")}
            >
                <span className={s.icon}>{icon}</span>
                <Box display={"flex"} justifyContent={"space-between"} w={"155px"} className={s.badgeContainer}>
                    <span className={s.linkContent}>{children}</span>
                    {notification ? (
                        <Box
                            bgColor="#AD0101"
                            borderRadius={"small"}
                            paddingX={notification > 99 ? "xxsmall" : "xsmall"}
                            marginBottom={"xxsmall"}
                            className={s.badge}
                            data-test-id="navbar-link-notification"
                        >
                            <TextBlock size={"xxsmall"} color={"white"}>
                                {notification > 999 ? "999+" : notification}
                            </TextBlock>
                        </Box>
                    ) : null}
                </Box>


                <IoIosArrowForward
                    className={s.caret}
                    role={"img"}
                    aria-label={` ${hasMatch ? "close" : "open"} ${props.label ?? children} sub links`}
                />

            </Link>
            <div
                data-test-id="navbar-link-sublinks"
                className={classNames(s.subLinks, {
                    [s.isSelected]: hasMatch,
                })}
            >
                {React.Children.map(subLinks, child =>
                    React.cloneElement(child, {
                        tabIndex: !hasMatch ? -1 : undefined,
                        "aria-hidden": !hasMatch,
                    }),
                )}
            </div>
        </div>
    );
};

NavbarLinkComponent.propTypes = {
    // eslint-disable-next-line react/require-default-props
    subLinks: PropTypes.arrayOf(PropTypes.element),
};

export default withRouter(NavbarLinkComponent);
