import * as React from "react";
import {SVGProps} from "react";

const IconUser = (props: SVGProps<SVGSVGElement>& {title?: string}) => {
    const {color = "#131622", title, ...rest} = props;
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        role="img"
        {...rest}
    >
        <title>{title ?? "Prospects"}</title>
        <g clipPath="url(#a)">
            <path
                fill={color}
                fillRule="evenodd"
                d="M12 3a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM5.277 19.64A17.193 17.193 0 0 0 12 21c2.386 0 4.658-.484 6.723-1.359a6.75 6.75 0 0 0-13.446 0Zm-1.526.465a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h24v24H0z"/>
            </clipPath>
        </defs>
    </svg>;
};
export default IconUser;
