import gql from "graphql-tag";
import anyUserFragment from "../fragments/anyUserFragment";
import universityUserFragment from "../fragments/universityUserFragment";
import applicantFragment from "../fragments/applicantFragment";

const meQuery = gql`
    query MeQuery {
        me {
            anyUser {
                ...AnyUserFull
            }
            universityUser {
                ...UniversityUserFull
            }
            applicant {
                ...ApplicantFull
            }
        }
    }
    ${anyUserFragment}
    ${universityUserFragment}
    ${applicantFragment}
`;

export default meQuery;
