import get from "lodash/get";
import {config} from "../ConfigManager/ConfigManager";

export const enableIntercomWidget = me => {
    const appId = process.env.NODE_ENV === "test" ? "AppId" : config.INTERCOM_APP_ID;
    if (me && window.Intercom && appId) {
        const user = me.anyUser;
        window.Intercom("boot", {
            app_id: appId, // use environment variable
            user_id: user.id,
            email: user.email,
            name: `${user.firstName} ${get(user, "lastName", "")}`,
            role: user.accountRole,
        });
    }
};

export const logoutIntercom = () => {
    if (window.Intercom) {
        window.Intercom("shutdown");
    }
};
