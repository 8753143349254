const getEnv = (name, defaultVal) =>
    typeof process.env[name] === "undefined" || process.env[name] === ""
        ? defaultVal
        : process.env[name];

// Get configuration from window.env
const getConfigFromWindow = (name, defaultVal) =>
    typeof window.env === "undefined" || typeof window.env[name] === "undefined" || window.env[name] === ""
        ? defaultVal
        : window.env[name];

//environments other than staging and production
const environmentsWithSupportOfTrendingTopics = [
    "qa-9",
];

const disableTrendingTopics = () =>
    getEnv("ENV_NAME") && !environmentsWithSupportOfTrendingTopics.includes(getEnv("ENV_NAME"));

module.exports = {
    getEnv,
    disableTrendingTopics,
    environmentsWithSupportOfTrendingTopics,
    getConfigFromWindow,
};
