import React from "react";
import ResetPasswordComplete from "../../../../shared/PasswordReset/components/ResetPasswordComplete/ResetPasswordComplete";
import ContentLayout from "../../components/ContentLayout/ContentLayout";

const ResetPasswordCompleteLayout = () => (
    <ContentLayout>
        <ResetPasswordComplete redirectUrl="/auth/login" />
    </ContentLayout>
);

export default ResetPasswordCompleteLayout;
