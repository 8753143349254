import "babel-polyfill";
import "whatwg-fetch";
import localForage from "localforage";
import get from "lodash/get";
import React, {Component} from "react";
import {ApolloProvider} from "react-apollo";
import {Spinner} from "@unibuddy/patron";
import {render} from "react-dom";
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import {persistStore} from "redux-persist";
import "react-table/react-table.css";
import {getConsents} from "shared/CookieManager/CookieManager";
import Messenger from "shared/Chat/messenger";
import {setMe, setToken} from "shared/Auth/actions/authActions";
import {initMixpanel} from "./modules/Utils/mixpanel";
import App from "./App";
import configureStore from "./modules/core/store/configureStore";
import "./modules/styles/main.pcss";
// eslint-disable-next-line import/first
import {ConfigContext} from "shared/ConfigContext/configContext";
import createApolloGatewayClient from "./modules/Utils/createApolloGatewayClient";

export const store = configureStore();

const client = createApolloGatewayClient();

class AppProvider extends Component {
    state = {
        rehydrated: false,
        adaptors: [],
    };

    componentWillMount() {
        persistStore(store, {whitelist: ["authState"], storage: localForage}, async () => {
            this.setState({rehydrated: true});

            const authState = get(store.getState(), "authState", {});
            let token = get(authState, "token", "");
            const initToken = get(authState, "initialUniToken", "");
            const initialMe = get(authState, "initialMe", {});

            if (initToken && token !== initToken) {
                token = initToken;
                store.dispatch(setToken(token));
                store.dispatch(setMe(initialMe));
            }

            const messengerState = get(store.getState(), "messengerState");
            if (token) {
                await Messenger.connect(
                    token,
                    messengerState,
                    store.dispatch,
                );
            }
        });
        const adaptors = [];
        const preferences = getConsents();
        adaptors.push(...initMixpanel(preferences));
        this.setState({adaptors});
    }

    render() {
        if (!this.state.rehydrated) {
            return <div id="unibuddy-loading"><Spinner/></div>;
        }
        return (
            <ApolloProvider client={client}>
                <Provider store={store}>
                    <ConfigContext.Provider value={window.env}>
                        <Router>
                            <App analyticsAdaptors={this.state.adaptors} />
                        </Router>
                    </ConfigContext.Provider>
                </Provider>
            </ApolloProvider>
        );
    }
}

render(<AppProvider />, document.getElementById("container"));
