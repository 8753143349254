import * as React from "react";
import {SVGProps} from "react";

const IconArticle = (props: SVGProps<SVGSVGElement> & {title?: string}) => {
    const {color = "#131622", title, ...rest} = props;
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={21}
        fill="none"
        role="img"
        {...rest}
    >
        <title>{title ?? "Resource Library"}</title>
        <path
            fill={color}
            d="M10 2.411a2.5 2.5 0 0 1 2.5 2.5v8.88l-5.62 5.62H4a2.5 2.5 0 0 1-2.5-2.5v-12a2.5 2.5 0 0 1 2.5-2.5h6Zm0-1.5H4a4 4 0 0 0-4 4v12a4 4 0 0 0 4 4h3.088a1 1 0 0 0 .707-.293l5.912-5.912a1 1 0 0 0 .293-.708V4.911a4 4 0 0 0-4-4Z"
        />
        <path
            fill="#131622"
            d="M10 5.914H4a.75.75 0 1 1 0-1.5h6a.75.75 0 1 1 0 1.5ZM6.51 8.832H4a.75.75 0 0 1 0-1.5h2.51a.75.75 0 0 1 0 1.5ZM8.252 20.117h-1.5v-4.773a1.683 1.683 0 0 1 1.682-1.68h4.809v1.5h-4.81a.18.18 0 0 0-.181.18v4.773Z"
        />
    </svg>;
};
export default IconArticle;
