import gql from "graphql-tag";

const globalAlertQuery = gql`
    query GlobalAlert($active: Boolean) {
        globalAlerts(active: $active) {
            title
            message
            active
        }
    }
`;

export default globalAlertQuery;
