import React, {ReactElement, useEffect, useRef, useState} from "react";
import {
  Box,
  Dropdown,
  DropdownButton,
  DropdownIcon,
  DropdownItem,
  DropdownList,
  View,
} from "@unibuddy/patron";
import {useBooleanFeatureFlag} from "ub-feature-flagging-react";
import AnalyticsDashboard from "@unibuddy/analytics-ui";
import ErrorBoundary from "shared/General/components/ErrorBoundary/ErrorBoundary";
import {config} from "shared/ConfigManager/ConfigManager";
import {useAnalytics} from "@unibuddy/tracking";
import styled from "styled-components";
import {
  AnalyticsDashboardType,
  CustomerTier,
  LOGO_SERVICE_TRACKING,
  LOGO_SERVICE_TRACKING_ENABLED_DASHBOARDS,
} from "../utils/constant";

const StyledDropdown = styled(Box)`
  button {
    color: #000;
    background: white;
    border-color: #0003;
    height: 31px;
    border-radius: 2px;
    font-weight: 400;
  }
`;

type AnalyticsProps = {
  authState: {
    token: string;
    me: {
      token: string;
      universityUser: {
        university: {
          slug: string;
          name: string;
        };
      };
    };
  };
  analyticsDashboardType: AnalyticsDashboardType;
};

const AnalyticsPage = ({
  authState,
  analyticsDashboardType,
}: AnalyticsProps): ReactElement => {
  const isPremiumPlusCustomer = useBooleanFeatureFlag(
    config.LD_UNIDASH_ANALYTICS_PREMUIM_PLUS_CUSTOMER,
    false,
  );
  const [showExport, setShowExport] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const isLiteProCustomer = useBooleanFeatureFlag(
    config.LD_UNIDASH_ANALYTICS_LITE_AND_PRO_CUSTOMER,
    false,
  );
  // This LD flag is to count visitor tracking data from logo service (earlier mixpanel)
  const logoServiceTracking = useBooleanFeatureFlag(
    config.LD_UNIDASH_ANALYTICS_LOGO_SERVICE_VISITOR_TRACKING,
    false,
  );

  const dashboardRef = useRef(null);

  let dashboardSlug;
  if (isPremiumPlusCustomer) {
    dashboardSlug = `uni${analyticsDashboardType}${CustomerTier.PremiumPlusCustomer}`;
  } else if (isLiteProCustomer) {
    dashboardSlug = `uni${analyticsDashboardType}${CustomerTier.LiteAndProCustomer}`;
  }
  if (
    logoServiceTracking &&
    LOGO_SERVICE_TRACKING_ENABLED_DASHBOARDS.has(analyticsDashboardType)
  ) {
    dashboardSlug += LOGO_SERVICE_TRACKING;
  }

  const CUMULIO_INTEGRATION_ID: string = config.CUMULIO_UNIDASH_INTEGRATION_ID;
  const GATEWAY_URL: string = config.GATEWAY_URL;
  const {
    events: {filterChangeOnDashboard, exportOnDashboard, analyticsViewed},
  } = useAnalytics();

  const universitySlug = authState?.me?.universityUser?.university?.slug;
  const universityName = authState?.me?.universityUser?.university?.name;
  useEffect(() => {
    analyticsViewed({
      universitySlug,
      universityName,
      dashboard: analyticsDashboardType,
      customerTier: isPremiumPlusCustomer
        ? CustomerTier.PremiumPlusCustomer
        : CustomerTier.LiteAndProCustomer,
    });
  }, [
    universitySlug,
    universityName,
    analyticsDashboardType,
    isPremiumPlusCustomer,
    analyticsViewed,
  ]);

  const onFilterChange = () => {
    filterChangeOnDashboard({
      universitySlug,
      universityName,
      dashboard: analyticsDashboardType,
      customerTier: isPremiumPlusCustomer
        ? CustomerTier.PremiumPlusCustomer
        : CustomerTier.LiteAndProCustomer,
    });
  };

  const onExport = (event) => {
    exportOnDashboard({
      universitySlug,
      universityName,
      dashboard: analyticsDashboardType,
      customerTier: isPremiumPlusCustomer
        ? CustomerTier.PremiumPlusCustomer
        : CustomerTier.LiteAndProCustomer,
      exportType: event?.detail?.data?.exportType,
    });
  };
  const onDashboardExport = async (type) => {
    setShowLoader(true);
    if (dashboardRef) {
      await dashboardRef?.current.exportDashboard(type);
    }
    setShowLoader(false);
  };
  useEffect(() => {
    if (analyticsDashboardType) setShowExport(false);
  }, [analyticsDashboardType]);

  const onLoad = () => {
    setShowExport(true);
    // for enabling hotjar tracking
    dashboardRef.current?.shadowRoot
      ?.querySelector("iframe.cio-embed-dashboard")
      ?.setAttribute("data-hj-allow-iframe", "");
  };

  return (
    <ErrorBoundary>
      <View h={40} />
      {isPremiumPlusCustomer && showExport && (
        <Box position={"relative"} zIndex={1}>
          <StyledDropdown
            position={"absolute"}
            right={"27.2%"}
            top={"25px"}
            zIndex={1}
          >
            <Dropdown>
              <DropdownButton
                disabled={showLoader}
                rightIcon={<DropdownIcon />}
              >
                {showLoader ? (
                  <Box>Exporting...</Box>
                ) : (
                  <Box paddingRight={"large"}>Export</Box>
                )}
              </DropdownButton>
              <DropdownList>
                <DropdownItem onSelect={() => onDashboardExport("png")}>
                  <Box paddingRight={"xlarge"}>As PNG</Box>
                </DropdownItem>
                <DropdownItem onSelect={() => onDashboardExport("pdf")}>
                  <Box paddingRight={"xlarge"}>As PDF</Box>
                </DropdownItem>
              </DropdownList>
            </Dropdown>
          </StyledDropdown>
        </Box>
      )}
      <Box paddingX={"large"} zIndex={10}>
        <AnalyticsDashboard
          authState={authState}
          dashboardSlug={dashboardSlug}
          env={{
            CUMULIO_INTEGRATION_ID,
            GATEWAY_URL,
          }}
          onFilterChange={onFilterChange}
          onExport={onExport}
          onLoad={onLoad}
          dashboardRef={dashboardRef}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default AnalyticsPage;
