import * as React from "react";
import {SVGProps} from "react";

const IconContent = (props: SVGProps<SVGSVGElement>& {title?: string}) => {
    const {color = "#131622", title, ...rest} = props;
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
        role="img"
        {...rest}
    >
        <title>{title ?? "Content"}</title>
        <path
            fill={color}
            d="M13.373 18H4.629A4.627 4.627 0 0 1 0 13.373V4.628A4.627 4.627 0 0 1 4.628 0h5.79a.754.754 0 0 1 0 1.509h-5.79a3.119 3.119 0 0 0-3.119 3.119v8.745a3.118 3.118 0 0 0 3.119 3.118h8.745a3.118 3.118 0 0 0 3.119-3.118V7.588a.754.754 0 0 1 1.509 0v5.785A4.629 4.629 0 0 1 13.373 18Z"
        />
        <path
            fill={color}
            d="m14.038 2.665 1.298 1.298-6.907 6.91-1.334-.02.018-1.262 6.925-6.924v-.002Zm0-1.731a.967.967 0 0 0-.685.283l-7.6 7.603c-.09.09-.141.21-.143.336l-.038 2.704a.484.484 0 0 0 .478.49l2.792.039h.006a.483.483 0 0 0 .342-.142l7.596-7.596a.967.967 0 0 0 0-1.37l-2.064-2.064a.965.965 0 0 0-.684-.283Z"
        />
    </svg>;
};
export default IconContent;
