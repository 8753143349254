import React from "react";
import {Query} from "react-apollo";
import get from "lodash/get";
import {MINUTES} from "shared/Utils/DateUtils";
import UNANSWERED_AND_UNRESOLVED_QUERY from "./query";

const UnansweredCountQuery = ({render, groupType}) => (
    <Query
        query={UNANSWERED_AND_UNRESOLVED_QUERY}
        variables={{
            getMentor: groupType === "student" || !groupType,
            getStaff: groupType === "staff" || !groupType,
        }}
        asyncMode
        pollInterval={5 * MINUTES}
    >
        {({data, error, loading}) => {
            if (loading || error) {
                return render({count: 0});
            }
            return render({
                count:
                    get(data, "mentorApplicantChatGroupCount", 0) +
                    get(data, "buddyApplicantChatGroupCount", 0),
            });
        }}
    </Query>
);

export default UnansweredCountQuery;
