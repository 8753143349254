import React from "react";
import {HasUniversityFeature} from "shared/Feature/HasUniversityFeature";
import MainNavRoutes from "./MainNavRoutes";
import {COMMUNITY_STAND_ALONE} from "./modules/Utils/FeatureFlagConstants";

const CommunityStandAloneRoutes = React.lazy(() =>
    import(/* webpackChunkName: "CommunityStandAloneRoutes" */ "./modules/CommunityStandAlone/Routes"),
);

const AllRoutes = ({universitySlug, isInstitution}) => {
    return (
        <HasUniversityFeature
            feature={COMMUNITY_STAND_ALONE}
            universitySlug={universitySlug}
            fallback={<MainNavRoutes isInstitution={isInstitution} />}
        >
            <CommunityStandAloneRoutes />
        </HasUniversityFeature>
    );
};

export default AllRoutes;
