const colours = {
    blackPearl: "#181D22",
    greyBlack: "#0B0A0A",
    grey: "#3C3B3B",
    stoneGrey: "#313B45",
    smartGrey: "#536374",
    softGrey: "#A9B4C0",
    mediumGrey: "#949D9F",
    inkWater: "#D0D6DC",
    solitude: "#E4E8EB",
    deepSolitude: "#F7F8F9",
    endeavour: "#26588B",
    astralBlue: "#306FAE",
    deepSky: "#3D8BDA",
    dodgerBlue: "#3091F2",
    "Blue/100": "#CDE4FE",
    lightBlue: "#EBF4FF",
    redCloud: "#d23e38",
    pikachu: "#FFC92D",
    freshLeaves: "#1CA56E",
    pureHit: "#23CF8A",
    bodyColour: "#292929",
    lightBodyColour: "#757474",
    errorColour: "#d2413b",
    primaryColour: "#2672bd",
    "Grey/50": "#F5F5F5",
    "Grey/150": "#D9D9DD",
    "Grey/600": "#5C5F72",
};

export default colours;
