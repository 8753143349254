import {COLORS} from "../../../ProspectNextSteps/utils/constants";

const nunitoMetrics = {
    capHeight: 705,
    ascent: 1011,
    descent: -353,
    lineGap: 0,
    unitsPerEm: 1000,
};

export const enterpriseTheme = {
    TextBlock: {
        fontFamily: "Nunito",
        fontMetrics: nunitoMetrics,
        color: COLORS.DARK_GREY,
    },
    Heading: {
        fontFamily: "Nunito",
        fontMetrics: nunitoMetrics,
    },
};
