import React from "react";
import gql from "graphql-tag";
import {Query} from "react-apollo";
import PropTypes from "prop-types";
import get from "lodash/get";

export const HAS_UNIVERSITY_FEATURE_QUERY = gql`
    query hasUniversityFeatureQuery($universitySlug: String!, $feature: String!) {
        university(slug: $universitySlug) {
            id
            isFeatureEnabled(feature: $feature)
            isFeatureReadOnly(feature: $feature)
            config {
                features {
                    feature {
                        name
                    }
                    enabledByUnibuddy
                    universityCanToggleFeature
                    enabledByUniversity
                }
            }
        }
    }
`;

export const HasUniversityFeature = ({universitySlug, feature, children, fallback = null}) => (
    <Query query={HAS_UNIVERSITY_FEATURE_QUERY} variables={{universitySlug, feature}}>
        {({loading, data}) => {
            if (loading) {
                return null;
            }
            if (
                !get(data, "university.isFeatureEnabled", false) &&
                !get(data, "university.isFeatureReadOnly", false)
            ) {
                return fallback;
            }
            return children;
        }}
    </Query>
);

HasUniversityFeature.propTypes = {
    children: PropTypes.node.isRequired,
    universitySlug: PropTypes.string.isRequired,
    feature: PropTypes.string.isRequired,
};
