export const USER_GENERATED_CONTENT = "userGeneratedContent";
export const ENABLE_CONVERSATION_INSIGHTS = "insights";
export const ENABLE_DATA_ANALYTICS_ON_COUNTRY_AND_COURSES =
    "enableDataAnalyticsOnCountryAndCourses";
export const STAFF_PROFILES = "staffProfiles";
export const INSIGHTS = "insights";
export const ENABLE_ALUMNI_AMBASSADOR_TYPE = "enableAlumniAmbassadorType";
export const ENABLE_EMAIL_VERIFICATION = "enableEmailVerification";
export const RANDOMIZE_MENTOR_LIST = "randomizeMentorList";
export const BUDDIES_SAVED_ANSWER = "buddiesSavedAnswer";
export const VIDEO_LIVESTREAMS = "videoLiveStream";
export const RANDOMIZE_STAFF_LIST = "randomizeStaffList";
export const ENABLE_PROSPECT_APP = "enableProspectApp";
export const INTERCOM_WIDGET = "intercomWidget";
export const SHOW_COUNTRY_FLAG = "showCountryFlag";
export const SHOW_UNIBUDDY_CONFERENCE_FEATURES = "showUnibuddyConferenceFeatures";
export const ENABLE_TOPIC_LABELLING_IN_CHAT = "enableTopicLabellingInChat";
export const COLLECT_DOMESTIC_REGION_ON_SIGNUP = "collectDomesticRegionOnSignup";
export const COMMUNITY = "community";
export const MANAGE_UNI_ADMINS = "manageUniAdmins";
export const EVENTS_PREMIUM = "eventsPremium";
export const TIME_SPENT = "timeSpent";
export const TIME_SPENT_R2 = "timeSpentR2";
export const NEW_EVENTS_TAB = "newEventsTab";
export const AGE_VERIFICATION_R1 = "ageVerificationR1";
export const ENABLE_DEGREE_TYPES = "enableDegreeTypes";
export const COMMUNITY_DATA_IN_CRM = "communityDataInCRM";
export const COMMUNITY_STAND_ALONE = "communityStandAlone";
