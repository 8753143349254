import React, {Suspense, useEffect, useState} from "react";
import compose from "lodash/flowRight";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Redirect, Route, Switch} from "react-router-dom";
import {useBooleanFeatureFlag} from "ub-feature-flagging-react";

import Auth from "shared/Auth/auth";
import {PatronThemeProvider} from "@unibuddy/patron";
import DesignSystemProvider from "shared/Experimental/Utils/components/DesignSystemProvider/DesignSystemProvider";
import {isFeatureEnabledByUnibuddyOrByUnibuddyAndUniversity} from "shared/Utils/FeatureUtils";
import {config} from "shared/ConfigManager/ConfigManager";

import AppBody from "./modules/App/AppBody";
import AppRoutes from "./AppRoutes";
import AppContainer from "./modules/App/AppContainer";
import Login from "./modules/Authenticate/containers/Login";
import Logout from "./modules/Authenticate/containers/Logout";
import SSOLogin from "./modules/Authenticate/containers/SSOLogin";
import PasswordResetRoutes from "./modules/PasswordReset/PasswordResetRoutes";
import EnterpriseAppRoutes from "./modules/EnterpriseMgtTools/EnterpriseAppRoutes";
import AuthRoutesContainer from "./modules/Authenticate/containers/AuthRoutesContainer";
import MemberOrganisationsQuery from "./modules/Navbar/components/MemberOrganisations/MemberOrganisationsQuery";
import InstitutionNavBar from "./modules/EnterpriseMgtTools/containers/NavbarEnterprise/InstitutionNavBar";
import {EnterpriseErrorContextProvider} from "./modules/EnterpriseMgtTools/containers/NavbarEnterprise/EnterpriseErrorContext";
import {enterpriseTheme} from "./modules/InstitutionAnalytics/general/components/FontTheme";
import {adminTheme} from "./modules/Utils/theme/theme";

import DevTools from "./modules/Devtools/DevTools";
import {COMMUNITY_STAND_ALONE} from "./modules/Utils/FeatureFlagConstants";
import {UniversityProvider, useUniversity} from "./modules/University/UniversityProvider";
import {initialiseVitallyNPS} from "./Vitally";

const PublicRoutes = ({match}) => {
    return (
        <AuthRoutesContainer>
            <Suspense fallback={null}>
                <Switch>
                    <Route path={`${match.url}/login`} component={Login}/>
                    <Route path={`${match.url}/logout`} component={Logout}/>
                    <Route path={`${match.url}/reset`} component={PasswordResetRoutes}/>
                    <Route path={`${match.url}/sso`} component={SSOLogin}/>
                </Switch>
            </Suspense>
        </AuthRoutesContainer>
    );
};

const RenderRoutes = ({authState}) => {
    const [open, setOpen] = useState(false);
    const [enterpriseNavOpen, setEnterpriseNavOpen] = useState(false);
    const [vitallyInitialised, setVitallyInitialised] = useState(false);
    const isVitallyEnabled = useBooleanFeatureFlag(config.LD_UNIDASH_VITALLY_NPS, false);

    const uniUserGroups = authState?.me?.universityUser?.groups ?? [];

    // Used to determine if the user is a super admin or not
    const organisationUserGroup = uniUserGroups.filter(group => group.type === "organisation_user");
    const [communityStandAloneEnabled, setCommunityStandAloneEnabled] = useState(false);
    const university = useUniversity();
    const universityFeatures = university?.config?.features ?? [];

    if (authState?.me?.anyUser && university && !vitallyInitialised && isVitallyEnabled) {
        initialiseVitallyNPS({authState: authState.me.anyUser, university});
        setVitallyInitialised(true);
    }

    useEffect(
        () => {
            if (universityFeatures?.length) {
                setCommunityStandAloneEnabled(
                    isFeatureEnabledByUnibuddyOrByUnibuddyAndUniversity(
                        universityFeatures,
                        COMMUNITY_STAND_ALONE,
                    ),
                );
            }
        },
        [universityFeatures],
    );
    const toggle = () => {
        setOpen(!open);
    };

    const toggleEnterpriseNav = () => {
        setEnterpriseNavOpen(!enterpriseNavOpen);
    };
    return <AppContainer>
        {organisationUserGroup.length && !communityStandAloneEnabled ? (
            <MemberOrganisationsQuery
                render={({memberOrganisations}) => {
                    memberOrganisations.sort((org1, org2) =>
                        org1.name.toLowerCase().localeCompare(org2.name.toLowerCase()),
                    );
                    return (
                        <PatronThemeProvider theme={enterpriseTheme}>
                            <InstitutionNavBar
                                university={university}
                                memberOrganisations={memberOrganisations}
                                isOpen={open}
                                isEnterpriseNavOpen={enterpriseNavOpen}
                            />
                        </PatronThemeProvider>
                    );
                }}
            />
        ) : null}
        <AppBody
            isInstitution={
                organisationUserGroup &&
                organisationUserGroup.length &&
                !communityStandAloneEnabled
            }
        >
            <Switch>
                {!communityStandAloneEnabled ? (
                    <Route
                        path="/enterprise-management-tools"
                        render={props => (
                            <EnterpriseAppRoutes
                                enterpriseNavOpen={enterpriseNavOpen}
                                toggle={toggleEnterpriseNav}
                                {...props}
                            />
                        )}
                    />
                ) : null}
                <Route
                    path="/"
                    render={props => (
                        <PatronThemeProvider theme={adminTheme}>
                            <AppRoutes
                                isInstitution={
                                    organisationUserGroup &&
                                    organisationUserGroup.length &&
                                    !communityStandAloneEnabled
                                }
                                isOpen={open}
                                toggle={toggle}
                                {...props}
                            />
                        </PatronThemeProvider>
                    )}
                />
            </Switch>
        </AppBody>
    </AppContainer>;
};

export const AuthenticatedRoutes = compose(
    withRouter,
    connect(({authState}) => ({authState})),
)(({authState}) => {
    if (!Auth.loggedIn(authState, "university")) {
        return (
            <Redirect
                to={{
                    pathname: "/auth/login",
                    state: {
                        nextPathname: location.pathname,
                    },
                }}
            />
        );
    }

    const universitySlug = authState?.me?.universityUser?.university?.slug ?? "";
    return (
        <UniversityProvider slug={universitySlug}>
            <DesignSystemProvider>
                <EnterpriseErrorContextProvider>
                    <RenderRoutes authState={authState}/>
                </EnterpriseErrorContextProvider>
            </DesignSystemProvider>
        </UniversityProvider>
    );
});

const MainRoutes = () => (
    <Switch>
        <Route path="/devtools" component={DevTools}/>
        <Route path="/auth" component={PublicRoutes}/>
        <Route component={AuthenticatedRoutes}/>
    </Switch>
);

export default MainRoutes;
