import {DefaultTheme} from "styled-components";

//TODO Add default theming for Patron components here.
export const universityDashboardTheme: DefaultTheme = {
  TextInput: {
    placeholderColor: "#5C5F72",
    variants: {
      disabled: {
        // borderColor: "red",
      },
      focus: {
        // borderColor: "green",
      },
      error: {
        // borderColor: "#DB808C",
      },
    },
  },
};
