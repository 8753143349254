import gql from "graphql-tag";

const addCustomAttributeMutation = gql`
    mutation AddCustomAttributeMutation(
        $name: String
        $attributeValues: [String]
        $destination: String
    ) {
        addCustomAttribute(
            name: $name
            attributeValues: $attributeValues
            destination: $destination
        ) {
            university {
                id
                customAttributes(destination: $destination) {
                    id
                    name
                    active
                    type
                }
            }
        }
    }
`;

export const AddCustomAttributeMutationMock = (name, attributeValues) => ({
    request: {
            query: addCustomAttributeMutation,
            variables: {
                name,
                destination: "mentor_profile",
                attributeValues,
            },
        },
    result: jest.fn(() => ({
        data: {
            university: {
                id: "unibuddy",
                customAttributes: [{
                    id: 123,
                    name,
                    active: false,
                }],
            },
        },
    })),

});

export default addCustomAttributeMutation;
