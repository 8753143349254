import * as actions from "../actions/blogTopicActions";

function blogTopicReducer(
    state = {
        selectedBlogTopics: [],
    },
    action,
) {
    switch (action.type) {
        case actions.SELECT_BLOG_TOPIC:
            return {
                ...state,
                selectedBlogTopics: [...state.selectedBlogTopics, action.topicId],
            };
        case actions.DESELECT_BLOG_TOPIC:
            return {
                ...state,
                selectedBlogTopics: state.selectedBlogTopics.filter(
                    item => item !== action.topicId,
                ),
            };
        case actions.CLEAR_SELECTED_BLOG_TOPICS:
            return {
                ...state,
                selectedBlogTopics: [],
            };
        default:
            return state;
    }
}

export default blogTopicReducer;
