import {getConfigFromWindow} from "../../../tools/build/utils/utils";

// export const API_URL = getConfigFromWindow("API_URL", "http://localhost:5000");

class IConfiguration {
  API_URL = "";
  ASSETS_URL = "";
  VIRTUAL_EVENTS_ADMIN_APP_URL = "";
  USER_INSIGHTS_APP_URL = "";
}

class FeatureFlagConfiguration extends IConfiguration {
  LD_CONV_INSIGHTS_V2_ENABLE_FLAG = getConfigFromWindow(
    "LD_CONV_INSIGHTS_V2_ENABLE_FLAG",
    null,
  );
  LD_ACTIONABLE_INSIGHTS_V1 = getConfigFromWindow(
    "LD_ACTIONABLE_INSIGHTS_V1",
    null,
  );
  LD_CUSTOM_DATE_RANGE_CONV_INSIGHTS = getConfigFromWindow(
    "LD_CUSTOM_DATE_RANGE_CONV_INSIGHTS",
    null,
  );
  LD_UNIDASH_EDM_ANALYTICS_V1 = "ub-university-dashboard-edm-analytics-v1";
  LD_UNIDASH_EDM_ADMIN_INVITES_PROSPECTS = "ub-university-dashboard-edm-admin-invites-prospects";
  LD_UNIDASH_EDM_COHORTS = "ub-university-dashboard-edm-cohorts";
  LD_UNIDASH_CUMUL_DASHBOARD = "ub-university-dashboard-cumul-dashboard";
  LD_UNIDASH_PROSPECTS_CONVERSATION_SUMMARY_V1 = "ub-university-dashboard-open-ai-conversation-summarisation-v1";
  LD_UNIDASH_CUMUL_ANALYTICS_ROUTES = "ub-university-dashboard-cumul-analytics-routes";
  LD_UNIDASH_ANALYTICS_PREMUIM_PLUS_CUSTOMER = "ub-uni-dash-analytics-for-premium-plus-customers";
  LD_UNIDASH_ANALYTICS_LITE_AND_PRO_CUSTOMER = "ub-uni-dash-analytics-for-lite-and-pro-customers";
  LD_UNIDASH_PROSPECT_INSIGHTS_ANALYTICS = "ub-know-the-student-visualisation-unidash";
  LD_UNIDASH_ANALYTICS_LOGO_SERVICE_VISITOR_TRACKING = "ub-uni-dash-analytics-for-logo-service-visitor-tracking";
  LD_UNIDASH_VITALLY_NPS = "ub-uni-dash-vitally-nps";

  LD_UNIDASH_BULK_RESOLVE_CONVERSATIONS = "ub-university-dashboard-bulk_resolve_conversations";
  LD_CUSTOMISE_AMBASSADOR_PREVIEW_CARD = "ub-university-dashboard-customise-ambassador-preview-card";
  LD_EDIT_AMBASSADOR_PROFILE = "ub-edit-ambassador-profile";
  LD_AMBASSADOR_PROFILE_CUSTOMISATION = "ub-customise-ambassador-profile-fields";
  LD_RESOURCE_LIBRARY = "ub-university-dashboard-resource-library";
  LD_CONVERSATION_MANAGEMENT_UPGRADE = "ub-university-dashboard-conversation-management-upgrade";
  LD_CUSTOMISE_DEGREE_LEVEL_AND_YEAR = "ub-university-dashboard-customise-degree-level-yearofstudy";
  ASSISTANT_TEMP = "ub-university-dashboard-prospect-guidance-temporary";
  LD_IS_AI_PROMPTS_ENABLED = "ub-university-dashboard-AI-content-prompts";
  LD_IS_AI_MODERATION_ENABLED = "ub-university-dashboard-ai-moderation";
  LD_IS_DEANONYMISE_PROSPECT_ENABLED = "ub-university-dashboard-deanonymise-prospect";
  LD_IS_PROSPECT_CHAT_SUMMARY_V2_ENABLED = "ub-university-dashboard-chat-summary-v2";

  LD_UNIDASH_ENTERPRISE_LUZMO_DASHBOARD = "ub-unidash-enterprise-luzmo-dashboard-experiment";
}
const testingMode = process.env.NODE_ENV === "test";

class ConfigurationFromWindow extends FeatureFlagConfiguration {
  API_URL = getConfigFromWindow("API_URL", "http://localhost:5000");
  FEDERATION_API_URL = getConfigFromWindow("FEDERATION_API_URL", "https://gateway-qa-9.unibuddy.co");
  ASSETS_URL = getConfigFromWindow("ASSETS_URL", "https://images-dev.unibuddy.co");
  VIRTUAL_EVENTS_ADMIN_APP_URL = getConfigFromWindow("VIRTUAL_EVENTS_ADMIN_APP_URL", "http://localhost:8100");
  VIRTUAL_EVENTS_APP_URL = getConfigFromWindow("VIRTUAL_EVENTS_APP_URL", "https://dev-events-qa-9.unibuddy.co");
  USER_INSIGHTS_APP_URL = getConfigFromWindow("USER_INSIGHTS_APP_URL", "http://localhost:8101");
  UB_LAUNCHDARKLY_CLIENT_ID = getConfigFromWindow("UB_LAUNCHDARKLY_CLIENT_ID", "6279f5c646a60e15a96fa418");
  CONTENT_FEDERATED_MODULES_APP_URL = getConfigFromWindow("CONTENT_FEDERATED_MODULES_APP_URL", "https://d4btwaca5cwzr.cloudfront.net");
  CUMULIO_UNIDASH_INTEGRATION_ID = getConfigFromWindow("CUMULIO_UNIDASH_INTEGRATION_ID", "");
  SUBDOMAIN_AWS_ACCOUNT_PREFIX = getConfigFromWindow("SUBDOMAIN_AWS_ACCOUNT_PREFIX", "");
  SUBDOMAIN_ENV_SUFFIX = getConfigFromWindow("SUBDOMAIN_ENV_SUFFIX", "");
  COMMUNITY_APP_URL = getConfigFromWindow("COMMUNITY_APP_URL", "https://community-qa.unibuddy.co");
  COMMUNITY_FEDERATED_MODULES_APP_URL = getConfigFromWindow("COMMUNITY_FEDERATED_MODULES_APP_URL", "http://localhost:3502");
  AMBASSADOR_PROFILE_FEDERATED_MODULES_APP_URL = getConfigFromWindow("AMBASSADOR_PROFILE_FEDERATED_MODULES_APP_URL", "https://ambassador-profile-modules-qa.unibuddy.co");
  GATEWAY_URL = getConfigFromWindow("GATEWAY_URL", "https://gateway-qa-9.unibuddy.co");
  DEV_MIXPANEL_ID = testingMode ? "thisisafakemixpanelid" : "27f68c73044b2fb3f950b44bbdb38ea2";
  MIXPANEL_ID = getConfigFromWindow("MIXPANEL_ID", this.DEV_MIXPANEL_ID);
  MIXPANEL_PROXY_HOST = getConfigFromWindow("MIXPANEL_PROXY_HOST", "https://tukey.unibuddy.co");
  STORYBOOK_ASSETS_URL = getConfigFromWindow("STORYBOOK_ASSETS_URL");
  CIRCLE_SHA1 = getConfigFromWindow("CIRCLE_SHA1");
  INTERCOM_APP_ID = getConfigFromWindow("INTERCOM_APP_ID");
  VIDEO_API_ID = getConfigFromWindow("VIDEO_API_ID");
  ZENDESK_WIDGET_KEY = getConfigFromWindow("ZENDESK_WIDGET_KEY");
  GOOGLE_CLIENT_ID_RAPIDASH = getConfigFromWindow("GOOGLE_CLIENT_ID_RAPIDASH", "429626956784-apblui97u9qspgs2csvb08bqpbo3l86o.apps.googleusercontent.com");
  ENV_NAME = getConfigFromWindow("ENV_NAME");
  /*---------------------------*/
  SALESFORCE_CLIENT_ID = getConfigFromWindow(
    "SALESFORCE_CLIENT_ID",
    "3MVG9HxRZv05HarQRXc0yCJQimvLtBHdmzbaqodRdLrXa6wQMbCwqAQqVnHfxMxDRL_6j8aQsG28_4IqIPGX9",
  );
  SALESFORCE_REDIRECT_URI = getConfigFromWindow(
    "SALESFORCE_REDIRECT_URI",
    `http://localhost:${process.env.PORT || 3000}/integrating/salesforce`,
  );
  UNIBUDDY_SQUARE_LOGO =
    "https://s3-eu-west-1.amazonaws.com/unibudy-prod/StaticAssets/unibuddy_square_logo_blue_bg.png";
  ONIX_URL = getConfigFromWindow("ONIX_URL", "http://localhost:4000");
  TRANSLATIONS_URL = getConfigFromWindow("TRANSLATIONS_URL", "https://translations-dev.unibuddy.co");
  AGE_VERIFICATION_URL = getConfigFromWindow(
    "AGE_VERIFICATION_URL",
    "https://unibuddy.api.v1.kidswebservices.com",
  );
  HORSEA_URL = getConfigFromWindow("HORSEA_URL", "http://localhost:4300");
  PUSHER_APP_KEY = getConfigFromWindow("PUSHER_APP_KEY", "29543470517ba526ec5a");
  PUSHER_APP_CLUSTER = getConfigFromWindow("PUSHER_APP_CLUSTER", "eu");
  MEDIA_URL = getConfigFromWindow(
    "MEDIA_URL",
    "https://s3-eu-west-1.amazonaws.com/unibuddy-images-dev-eu-west-1/",
  );
  THEME_PRIMARY = "3b9dff";
  DEFAULT_PROFILE_PHOTO =
    "https://s3-eu-west-1.amazonaws.com/unibudy-prod/StaticAssets/default_profile.png";
  isDebug = global.DEBUG === false ? false : !process.argv.includes("--release");
}

class TestConfiguration extends FeatureFlagConfiguration {
  PUSHER_APP_KEY = "PUSHER_APP_KEY";
  PUSHER_APP_CLUSTER = "PUSHER_APP_CLUSTER";
  MIXPANEL_ID = "testtoken";
  MEDIA_URL = "https://s3-eu-west-1.amazonaws.com/unibuddy-images-dev-eu-west-1/";
  API_URL = "http://localhost/";
  ASSETS_URL = "http://aws/";
  ONIX_URL = "http://onix/";
  HORSEA_URL = "http://0.0.0.0:4300";
  LD_CONV_INSIGHTS_V2_ENABLE_FLAG = 'LD_CONV_INSIGHTS_V2_ENABLE_FLAG';
  TRANSLATIONS_URL = "http://translation/";
  DEFAULT_PROFILE_PHOTO = "https://s3-eu-west-1.amazonaws.com/unibudy-prod/StaticAssets/default_profile.png";
  UB_LAUNCHDARKLY_CLIENT_ID = "6279f5c646a60e15a96fa418";
  CUMULIO_UNIDASH_INTEGRATION_ID = "";
  GATEWAY_URL = "https://gateway-qa-9.unibuddy.co";
  AMBASSADOR_PROFILE_FEDERATED_MODULES_APP_URL = "http://localhost:8081";
}

export const config = !testingMode ? new ConfigurationFromWindow() : new TestConfiguration();
