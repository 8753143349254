import {useBooleanFeatureFlag} from "ub-feature-flagging-react";
import {
    IS_SSO_ENABLED,
    IS_SSO_ENABLED_DEFAULT_VALUE,
  } from "shared/Utils/Constants";

/**
 * Gets a returns a LaunchDarkly feature setting
 *
 * @export
 * @returns {{isSSOAuthEnabled: boolean}} from LaunchDarkly
 */
export default function useSSOAuth() {
    const isSSOAuthEnabled = useBooleanFeatureFlag(
        IS_SSO_ENABLED,
        IS_SSO_ENABLED_DEFAULT_VALUE,
    );

    return {
        isSSOAuthEnabled,
    };
}
