import React, {Fragment} from "react";
import {withRouter, Redirect} from "react-router";
import {Link} from "react-router-dom";
import classNames from "classnames";
import get from "lodash/get";
import find from "lodash/find";
import styled from "styled-components";
import {Box, TextBlock} from "@unibuddy/patron";
import Logo from "shared/Experimental/General/components/Logo/Logo";
import Inline from "shared/Experimental/Layout/components/Inline/Inline";
import Badge from "shared/Experimental/General/components/Badge/Badge";
import {
  isFeatureEnabledByUnibuddyOrByUnibuddyAndUniversity,
  useFeatureFlags,
} from "shared/Utils/FeatureUtils";
import {useBooleanFeatureFlag} from "ub-feature-flagging-react";
import {config} from "shared/ConfigManager/ConfigManager";
import {HasUniversityFeature} from "shared/Feature/HasUniversityFeature";
import NavbarLink, {SubLink} from "../components/NavbarLink/NavbarLink";
import IconBinoculars from "../../Icons/IconBinoculars";
import s from "./Navbar.pcss";
import {Consumer} from "../../App/AppContainer";
import UnansweredCountQuery from "../components/UnansweredCount/UnansweredCountQuery";
import BlogCountQuery from "../components/BlogCount/BlogCountQuery";
import ModerationCountQuery from "../components/ModerationCount/ModerationCountQuery";
import {USER_INSIGHTS_ENABLED} from "../../UserInsights/constants";
import IconSettings from "../../Icons/IconSettings";
import IconProfile from "../../Icons/IconProfile";
import IconArticle from "../../Icons/IconArticle";
import IconPuzzle from "../../Icons/IconPuzzle";
import IconUserGroup from "../../Icons/IconUserGroup";
import IconCalendar from "../../Icons/IconCalendar";
import IconSparkles from "../../Icons/IconSparkles";
import IconContent from "../../Icons/IconContent";
import IconUser from "../../Icons/IconUser";
import IconAcademicHat from "../../Icons/IconAcademicHat";
import IconInbox from "../../Icons/IconInbox";
import IconLightBulb from "../../Icons/IconLightBulb";
import IconChartBar from "../../Icons/IconChartBar";
import IconDanger from "../../Icons/IconDanger";
import {IconChatBot} from "../../Icons/IconChatBot";

const RedirectToRoute = ({to}) => <Redirect to={to} />;

const Pill = styled(Badge)`
  border-radius: 4px;
  font-weight: 600;
  letter-spacing: 1px;
`;

export const ProtectedTab = ({isEnabled, children, fallback}) => {
  if (!isEnabled) return fallback;
  return children;
};

const EventsTab = ({universitySlug, liveEventsPath, eventsPath}) => {
  const {data, loading} = useFeatureFlags(universitySlug);
  const features = get(data, "university.config.features", null);
  if (loading) return null;
  const isLiveEventManagementEnabled = isFeatureEnabledByUnibuddyOrByUnibuddyAndUniversity(
    features,
    "universityLiveEventManagement",
  );
  const isNewEventsTabEnabled = isFeatureEnabledByUnibuddyOrByUnibuddyAndUniversity(
    features,
    "newEventsTab",
  );
  return (
    <Fragment>
      <ProtectedTab
        isEnabled={isNewEventsTabEnabled}
        fallback={eventsPath ? <RedirectToRoute to="/analytics" /> : null}
      >
        <NavbarLink icon={<IconCalendar />} to="/events">
          <Inline space="xsmall" verticalAlign="center">
            Events
          </Inline>
        </NavbarLink>
      </ProtectedTab>

      <ProtectedTab
        isEnabled={isLiveEventManagementEnabled && !isNewEventsTabEnabled}
        fallback={
          liveEventsPath && !isNewEventsTabEnabled ? (
            <RedirectToRoute to="/analytics" />
          ) : null
        }
      >
        <NavbarLink icon={<IconCalendar />} to="/live-events">
          Live Events
        </NavbarLink>
      </ProtectedTab>
    </Fragment>
  );
};

function getAnalyticsDashboardLinks(isCumulAnalyticsEnabled) {
  if (!isCumulAnalyticsEnabled) {
    return null;
  }

  const links = [
    <SubLink to="/analytics/engagement" aria-label={"Analytics Engagement"}>
      <Inline space="xsmall" verticalAlign="center">
        Engagement
      </Inline>
    </SubLink>,
    <SubLink to="/analytics/prospect" aria-label={"Analytics Prospect"}>
      <Inline space="xsmall" verticalAlign="center">
        Prospect
      </Inline>
    </SubLink>,
    <SubLink
      to="/analytics/ambassador/student"
      aria-label={"Analytics Ambassador"}
    >
      <Inline space="xsmall" verticalAlign="center">
        Ambassador
      </Inline>
    </SubLink>,
  ];

  return links;
}

const InsightsDashboardMenuItem = ({path, universityFeatures}) => {
  const isCumulAnalyticsEnabled = useBooleanFeatureFlag(
    config.LD_UNIDASH_CUMUL_ANALYTICS_ROUTES,
    false,
  );
  const isProspectInsightsEnabled = useBooleanFeatureFlag(
    config.LD_UNIDASH_PROSPECT_INSIGHTS_ANALYTICS,
    false,
  );
  const isApplicationInsightsEnabled = useBooleanFeatureFlag(
    USER_INSIGHTS_ENABLED,
    false,
  );
  const isConversationInsightsEnabled = isFeatureEnabledByUnibuddyOrByUnibuddyAndUniversity(
    universityFeatures,
    "insights",
  );

  const isInsightsPath = path.includes("/insights");

  const insightsRoutes = [
    "/insights/conversation-insights",
    "/insights/prospect-insights",
    "/insights/application-insights",
  ];
  const links = [];
  const enabledUrls = [];

  if (isConversationInsightsEnabled) {
    links.push(
      <SubLink to={insightsRoutes[0]} aria-label="Conversation Insights">
        <Inline space="xsmall" verticalAlign="center">
          Conversations
        </Inline>
      </SubLink>,
    );
    enabledUrls.push(insightsRoutes[0]);
  }
  if (isCumulAnalyticsEnabled && isProspectInsightsEnabled) {
    links.push(
      <SubLink to={insightsRoutes[1]} aria-label="Prospect Insights">
        <Inline space="xsmall" verticalAlign="center">
          Prospects <Pill color="primary">NEW!</Pill>
        </Inline>
      </SubLink>,
    );
    enabledUrls.push(insightsRoutes[1]);
  }
  if (isApplicationInsightsEnabled) {
    links.push(
      <SubLink to={insightsRoutes[2]} aria-label="Application Insights">
        <Inline space="xsmall" verticalAlign="center">
          Applications
        </Inline>
      </SubLink>,
    );
    enabledUrls.push(insightsRoutes[2]);
  }

  if (enabledUrls.length === 0) {
    if (isInsightsPath) return <RedirectToRoute to="/analytics" />;
    return null;
  }

  return (
    <NavbarLink
      name="Insights"
      label="Insights"
      data-testid="insights"
      to={enabledUrls[0]}
      icon={<IconLightBulb />}
      subLinks={enabledUrls.length === 1 ? undefined : links}
      fallback={isInsightsPath ? <RedirectToRoute to="/analytics" /> : null}
    >
      <Inline space="small" verticalAlign="center">
        Insights
      </Inline>
    </NavbarLink>
  );
};

const MainNavBar = (props) => {
  const marketplaces = get(
    props.me.universityUser,
    "university.marketplaces",
    [],
  );
  // const uniUserGroups = get(props, "authState.me.universityUser.groups", []);
  // const organisationUserGroup = uniUserGroups.filter(group => group.type === "organisation_user");
  const selectedOrganisation = get(
    props,
    "authState.me.universityUser.university.name",
    "",
  );
  const path = get(props, "location.pathname");
  const ambassadorCustomizationPath = path.includes(
    "/customization/ambassadors",
  );
  const customizationFeaturesPath = path.includes("/customization/features");
  const blogsPath = path.includes("/content");
  const invitationPath =
    path === "/buddies/staff" ? "/account/invite/staff" : "/account/invite";
  const buddiesPath =
    path === "/buddies/staff" || path === "/account/invite/staff"
      ? "/buddies/staff"
      : "/buddies";
  const liveEventsPath = path.includes("/live-events");
  const eventsPath = path.includes("/events");
  const marketplacesPath = path.includes("/marketplaces");
  const adminsRoute = path.includes("/users");
  const isCommunityRoute = path.includes("/community");
  const universitySlug = get(props, "me.universityUser.university.slug");
  const universityFeatureData = useFeatureFlags(universitySlug);
  const universityFeatures = universityFeatureData.data
    ? universityFeatureData.data.university.config.features
    : [];
  const loadcustomAmbassadorAttributesSublink = () => {
    if (
      universityFeatureData.data &&
      isFeatureEnabledByUnibuddyOrByUnibuddyAndUniversity(
        universityFeatures,
        "customAmbassadorAttributes",
      )
    ) {
      return [
        <SubLink to="/customization/ambassadors/student">Ambassadors</SubLink>,
      ];
    } else if (!universityFeatureData.loading && ambassadorCustomizationPath) {
      return [<RedirectToRoute to="/customization/prospects" />];
    }
    return [];
  };
  const loadCustomizeFeaturesSublink = () => {
    if (
      !universityFeatureData.loading &&
      universityFeatureData.data &&
      universityFeatures.find(
        (aFeature) => aFeature.universityCanToggleFeature === true,
      )
    ) {
      return [<SubLink to="/customization/features">Features</SubLink>];
    } else if (!universityFeatureData.loading && customizationFeaturesPath) {
      return [<RedirectToRoute to="/customization/prospects" />];
    }
    return [];
  };
  const isDegreeTypesTabEnabled = isFeatureEnabledByUnibuddyOrByUnibuddyAndUniversity(
    universityFeatures,
    "enableDegreeTypes",
  );
  const isNextStepsEnabled = useBooleanFeatureFlag(
    config.LD_ACTIONABLE_INSIGHTS_V1,
    false,
  );
  const isAssistantEnabled = useBooleanFeatureFlag(
    config.ASSISTANT_TEMP,
    false,
  );
  const isProspectInviteEnabled = useBooleanFeatureFlag(
    config.LD_UNIDASH_EDM_ADMIN_INVITES_PROSPECTS,
    false,
  );
  const loadNextBestActionSublink = () => {
    if (isNextStepsEnabled)
      return [<SubLink to="/applicant/next-steps">Next Best Steps</SubLink>];
    return [];
  };
  const loadProspectInviteSublink = () => {
    if (isProspectInviteEnabled)
      return [<SubLink to="/applicant/invite">Invite</SubLink>];
    return [];
  };
  const isCumulAnalyticsEnabled = useBooleanFeatureFlag(
    config.LD_UNIDASH_CUMUL_ANALYTICS_ROUTES,
    false,
  );
  const isResourceLibraryEnabled = useBooleanFeatureFlag(
    config.LD_RESOURCE_LIBRARY,
    false,
  );
  const analyticsDashboardLinks = getAnalyticsDashboardLinks(
    isCumulAnalyticsEnabled,
  );
  const isContentPromptsEnabled = useBooleanFeatureFlag(
    config.LD_IS_AI_PROMPTS_ENABLED,
    false,
  );

  return (
    <Consumer>
      {({collapsed}) => (
        <div
          className={classNames(s.container, {
            collapsedSidebar: collapsed,
          })}
        >
          <Link
            to="/"
            className={s.logoLink}
            aria-label="Unibuddy logo"
            href="/"
          >
            <Logo className={s.logo} width={121} displayText={!collapsed} />
          </Link>

          {!collapsed ? (
            <Box paddingBottom="large">
              <TextBlock weight={600} size={"medium"} align={"center"}>
                {selectedOrganisation}
              </TextBlock>
            </Box>
          ) : null}

          {/* {!collapsed ? (
                        <MemberOrgDropdown organisation={activeMemberOrganisation} {...props} />
                    ) : null} */}

          {/* {!collapsed && organisationUserGroup.length ? (
                        <Link to="/enterprise-management-tools" className={s.enterpriseMgtLink}>
                            <span>Enterprise Management Tools</span>
                        </Link>
                    ) : null} */}

          <NavbarLink
            to="/analytics"
            icon={<IconChartBar />}
            subLinks={analyticsDashboardLinks}
          >
            Analytics
          </NavbarLink>
          {isAssistantEnabled && (
            <NavbarLink
              to="/assistant"
              icon={<IconChatBot height="24px" width="24px" />}
            >
              Assistant
            </NavbarLink>
          )}

          <InsightsDashboardMenuItem
            path={path}
            universityFeatures={
              universityFeatureData.data ? universityFeatures : []
            }
          />

          <UnansweredCountQuery
            render={({count}) => (
              <NavbarLink
                icon={<IconInbox />}
                subLinks={[
                  <SubLink to="/conversations">Manage</SubLink>,
                  <SubLink to="/inbox">Inbox</SubLink>,
                  <SubLink to="/outbox">Outbox</SubLink>,
                ]}
                notification={count}
              >
                Conversations
              </NavbarLink>
            )}
          />

          <NavbarLink
            to="/buddies"
            icon={<IconAcademicHat />}
            subLinks={[
              <SubLink to={buddiesPath}>Manage</SubLink>,
              <SubLink to={invitationPath}>Invitations</SubLink>,
              <SubLink to="/timesheet/students">Timesheet</SubLink>,
            ]}
          >
            Buddies
          </NavbarLink>

          {isNextStepsEnabled || isProspectInviteEnabled ? (
            <NavbarLink
              to="/applicant"
              icon={<IconUser />}
              subLinks={[
                <SubLink to="/applicant/manage">Manage</SubLink>,
                ...loadNextBestActionSublink(),
                ...loadProspectInviteSublink(),
              ]}
            >
              Prospects
            </NavbarLink>
          ) : (
            <NavbarLink to="/applicant" icon={<IconUser />}>
              Prospects
            </NavbarLink>
          )}
          <ModerationCountQuery
            render={({count}) => (
              <NavbarLink
                to="/moderation"
                icon={<IconDanger />}
                notification={count}
              >
                Moderation
              </NavbarLink>
            )}
          />
          <HasUniversityFeature
            feature="userGeneratedContent"
            universitySlug={universitySlug}
            fallback={blogsPath ? <RedirectToRoute to="/analytics" /> : null}
          >
            <BlogCountQuery
              render={({count}) => (
                <NavbarLink
                  to="/content"
                  icon={<IconContent />}
                  notification={count}
                >
                  <Inline space={"xsmall"}>
                    Content{" "}
                    {isContentPromptsEnabled ? (
                      <Pill color="primary" className={s.drawerPill}>
                        NEW!
                      </Pill>
                    ) : null}
                  </Inline>
                </NavbarLink>
              )}
            />
          </HasUniversityFeature>
          <HasUniversityFeature
            feature="community"
            universitySlug={universitySlug}
            fallback={
              isCommunityRoute ? <RedirectToRoute to="/analytics" /> : null
            }
          >
            <NavbarLink icon={<IconUserGroup />} to="/community">
              Community
            </NavbarLink>
          </HasUniversityFeature>

          <EventsTab
            universitySlug={universitySlug}
            liveEventsPath={liveEventsPath}
            eventsPath={eventsPath}
          />

          {marketplaces.length ? (
            <NavbarLink
              icon={<IconBinoculars />}
              subLinks={[
                <SubLink to="/marketplaces/analytics">Analytics</SubLink>,
                <SubLink to="/marketplaces/ambassadors">Ambassadors</SubLink>,
              ]}
            >
              {marketplaces.length === 1 && find(marketplaces, {slug: "ucas"})
                ? "UCAS"
                : "Discover"}
            </NavbarLink>
          ) : marketplacesPath ? (
            <RedirectToRoute to={"/analytics"} />
          ) : null}

          <NavbarLink
            icon={<IconSparkles />}
            to="/customization"
            subLinks={[
              <SubLink to="/customization/prospects">Prospects</SubLink>,
              <SubLink to="/degrees">Degrees</SubLink>,
              <SubLink to="/degree">
                {isDegreeTypesTabEnabled
                  ? "Degree Levels and Types"
                  : "Degree Levels"}
              </SubLink>,
              ...loadcustomAmbassadorAttributesSublink(),
              ...loadCustomizeFeaturesSublink(),
              <SubLink to="/customization/ambassadors/attribute" />,
              <SubLink to="/level_and_year" />,
            ]}
          >
            Customization
          </NavbarLink>

          <NavbarLink
            icon={<IconPuzzle />}
            subLinks={[
              <SubLink to="/snippets">Code Snippets</SubLink>,
              <SubLink to="/integrations">CRMs</SubLink>,
            ]}
          >
            Integrations
          </NavbarLink>

          <HasUniversityFeature
            feature="manageUniAdmins"
            universitySlug={universitySlug}
            fallback={adminsRoute ? <RedirectToRoute to="/analytics" /> : null}
          >
            <NavbarLink to="/users" icon={<IconProfile />}>
              Admin Users
            </NavbarLink>
          </HasUniversityFeature>

          <NavbarLink to="/settings" icon={<IconSettings />}>
            Settings
          </NavbarLink>

          {isResourceLibraryEnabled && (
            <NavbarLink to="/resource-library" icon={<IconArticle />}>
              Resource Library
            </NavbarLink>
          )}
        </div>
      )}
    </Consumer>
  );
};

export default withRouter(MainNavBar);
