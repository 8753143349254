import React, {useState} from "react";
import {useMutation} from "react-apollo";
import {string} from "yup";
import {
    ArrowLeft,
    Box,
    Button,
    Form,
    FormGroup,
    Heading,
    Inline,
    Stack,
    TextArea,
    TextBlock,
    TextInput,
} from "@unibuddy/patron";
import {FormikHelpers, FormikValues} from "formik";
import {NavLink, useHistory} from "react-router-dom";
import PageTitle from "shared/Shared/components/PageTitle/PageTitle";
import ErrorMessage from "shared/Forms/components/ErrorMessage/ErrorMessage";
import styled from "styled-components";
import IoIosArrowForward from "react-icons/lib/io/ios-arrow-forward";
import {COLORS} from "../../../ProspectNextSteps/utils/constants";
import AddNewFieldTooltip from "../Tooltips/AddNewFieldTooltip";
import addCustomAttributeMutation from "../../mutations/addCustomAttribute";

const {STRONG_BLUE, LIGHT_GREY} = COLORS;
const StyledLink = styled(TextBlock)`
    cursor: pointer;
    &:hover {
        color: ${STRONG_BLUE};
        text-decoration-line: underline;
        text-decoration-color: ${STRONG_BLUE};
    }
`;
const LegacyNarrowContainer = styled.div`
    margin: 0 auto;
    max-width: 640px;
    padding: 0 15px;
`;
const StyledIoIosArrowForward = styled(IoIosArrowForward)`
      color: ${LIGHT_GREY};
`;

const AddCustomAttributeFormV2 = (props: { destination: string, backUrl: string }) => {
    const [error, setError] = useState<string>();
    const [addCustomAttribute] = useMutation(addCustomAttributeMutation);
    const history = useHistory();


    const handleSubmit = async (values: FormikValues, formActions: FormikHelpers<any>) => {
        const attributeValues = values.attributeContent.split(/\r?\n/);
        try {
            formActions.setSubmitting(true);
            await addCustomAttribute({
                variables: {
                    name: values.attributeName,
                    attributeValues,
                    destination: props.destination,
                },
            });
            history.push(props.backUrl);
        } catch (err) {
            setError("Error in Saving");
        } finally {
            formActions.setSubmitting(false);
        }
    };

    return (
        <LegacyNarrowContainer>
            <Box paddingY="xlarge" paddingX="large">
                <Stack space="large">
                    <Inline space="small" verticalAlign="center">
                        <NavLink
                            aria-label="Go back Ambassador fields customisation"
                            to="/customization/ambassadors"
                        >
                            <StyledLink
                                size="medium" weight={400}
                                align="bottom"
                            ><ArrowLeft/> &nbsp;{"Customize ambassador profile fields"}
                            </StyledLink>
                        </NavLink>
                        <TextBlock size="medium"><StyledIoIosArrowForward/></TextBlock>
                        <TextBlock weight="600">
                            {`Add New Field`}
                        </TextBlock>
                    </Inline>
                    <Box backgroundColor="white" display="flex" justifyContent="center" padding="medium">
                        {/* @ts-ignore FIXME Patron should cast <Alert/> return type to React.ReactNode  */}
                        <Form
                            onSubmit={handleSubmit}
                            id="custom-attribute-form"
                            validationSchema={{
                                attributeName: string()
                                    .nullable()
                                    .trim()
                                    .required("Name is required"),
                                attributeContent: string()
                                    .trim()
                                    .required("Options is required"),
                            }}
                        >
                            {/* @ts-ignore refactor needed in PageTitle */}
                            <PageTitle title="Add new custom field"/>
                            <Stack space="medium">
                                <Heading level="1" weight="400" size="small">
                                    <Inline
                                        align="center" verticalAlign="top"
                                    >{"Add new field "}
                                        <Box paddingTop="xsmall"><AddNewFieldTooltip/></Box>
                                    </Inline>
                                </Heading>
                                <FormGroup label="Name">
                                    <TextInput
                                        type="text"
                                        name="attributeName"
                                        placeholder="Enter text"
                                        required
                                    />
                                </FormGroup>
                                <FormGroup label="Options (You can paste dropdown content from an Excel column)">
                                    <TextArea
                                        rows={10}
                                        name="attributeContent"
                                        placeholder="Enter text"
                                        required
                                    />
                                </FormGroup>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button
                                        type="submit"
                                        id="custom-attribute-submit"
                                        bsStyle="primary"
                                    >
                                        Create Field
                                    </Button>
                                    {error && <ErrorMessage>{error}</ErrorMessage>}
                                </Box>
                            </Stack>
                        </Form>
                    </Box>
                </Stack>
            </Box>
        </LegacyNarrowContainer>
    );
};

export default AddCustomAttributeFormV2;
