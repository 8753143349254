import PropTypes from "prop-types";
import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "@unibuddy/intl";
import {string} from "yup";
import {EMAIL_REQUIRED, VALID_EMAIL} from "shared/Shared/validations/validationMessages";
import {Form, FormGroup, SubmitButton, TextInput} from "@unibuddy/patron";
import TextBlock from "../../../Experimental/Typography/components/TextBlock/TextBlock";
import Stack from "../../../Experimental/Layout/components/Stack/Stack";
import Inline from "../../../Experimental/Layout/components/Inline/Inline";
import s from "./PasswordReset.pcss";
import PageHeader from "../../../Shared/components/PageHeader/PageHeader";

const PasswordReset = props => {
    const {t} = useTranslation(["passwordReset", "validations"]);

    return (
        <div className={s.container} id="requestPasswordReset">
            <div className={s.center}>
                <div className="text-center">
                    <Stack space="small">
                        <PageHeader title={t("resetPassword", "Reset Password")} />
                        <Inline space="xxsmall" align="center" verticalAlign="center">
                            <TextBlock>{t("knowYourPassword", "Know your password?")} </TextBlock>
                            <Link aria-label={t("loginHere", "Login here")} to={props.loginLink}>
                                {t("loginHere", "Login here")}
                            </Link>
                        </Inline>
                    </Stack>
                </div>
                <Form
                    onSubmit={props.onRequestPasswordReset}
                    validationSchema={{
                        email: string()
                            .email(t("validations:email", VALID_EMAIL))
                            .required(t("validations:emailRequired", EMAIL_REQUIRED)),
                    }}
                >
                    {({isValid}) => {
                        return (
                            <Stack space={"medium"}>
                                <FormGroup id={"email"} label={t("email", "Email")}>
                                    <TextInput name="email" required />
                                </FormGroup>
                                <Stack space={"small"} align={"center"}>
                                    <SubmitButton color="primary" id="continue" disabled={!isValid}>
                                        {t("sendLink", "Send Link")}
                                    </SubmitButton>
                                </Stack>
                            </Stack>
                        );
                    }}
                </Form>
            </div>
        </div>
    );
};

PasswordReset.propTypes = {
    loginLink: PropTypes.string.isRequired,
    onRequestPasswordReset: PropTypes.func.isRequired,
};

export default PasswordReset;
