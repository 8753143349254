export const VIDEO = "video";
export const NEXT_STEPS_OPTIMAL_COUNT = 3;

export const NEXT_STEP_PERSISTENCE_TYPE = {
    SAVE: "SAVE",
    EDIT: "EDIT",
};

export const NEXT_STEP_SUB_ROUTES = ["Manage", "Analytics"];

export const NEXT_STEP_TYPES = {
    BLOG: "BLOG",
    COMMUNITY: "COMMUNITY",
    IN_PERSON_EVENT: "IN_PERSON_EVENT",
    EVENT: "EVENT",
    AMBASSADOR: "AMBASSADOR",
    ARTICLE: "ARTICLE",
    VIDEO: "VIDEO",
    UNIVERSITY_WEBPAGE: "UNIVERSITY_WEBPAGE",
    NEWSLETTER: "NEWSLETTER",
    OTHER: "OTHER",
};

export const COLORS = {
    // Grey
    GREY: "#292929",
    DARK_GREY: "#404446",
    DARK_FADED_GRAY: "#F3F5F5",
    SMART_GREY: "#536374",
    LIGHT_GREY: "#949D9F",

    WHITE: "#FFFFFF",

    MINT_GREEN: "#006360",

    // Blue
    FADED_BLUE: "#DFEDF7",
    STRONG_BLUE: "#0171E0",
    DARK_BLUE: "#064A9B",
    TRUE_BLUE: "#0866D9",
    DENIM: "#075DC5",
    SAPPHIRE: "#0653B1",
    MEDIUM_ELECTRIC_BLUE: "#064A9B",
    MIDNIGHT_BLUE: "#05418A",

    // Black
    COOL_BLACK: "#042E62",

    // Red
    DANGER: "#A50000",

    // Orange
    SECONDARY_ORANGE: "#FFE5C8",
};
