export const BUDDIES = "Buddies";
export const CUSTOMIZATION = "Customization";
export const CUSTOMIZATION_DEGREES = "Degrees";
export const CUSTOMIZATION_DEGREE_LEVELS_AND_TYPES = "Degree Levels and Types";
export const CUSTOMIZATION_DEGREE_LEVELS = "Degree Levels";
export const COMMUNITY = "Community";
export const INTEGRATIONS = "Integrations";
export const CRM = "CRMs";
export const ADMIN_USERS = "Admin Users";
export const SETTINGS = "Settings";

export const CONFIGURABLE_FEATURES = {};
