import React, {useEffect, useState} from "react";
import s from "./App.pcss";

export const {Provider, Consumer} = React.createContext();
const KEY = "university_sidebar_collapsed";

function AppContainer(props) {
    const [collapsed, setCollapsed] = useState(!!JSON.parse(localStorage.getItem(KEY)));

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    useEffect(
        () => {
            localStorage.setItem(KEY, collapsed);
        },
        [collapsed],
    );

    return (
        <Provider value={{collapsed, toggle: toggleCollapse}}>
            <div className={s.appContainer}>{props.children}</div>
        </Provider>
    );
}

export default AppContainer;
