import React from "react";
import {AuthContext} from "../AuthProvider/AuthProvider";

/**
 * The purpose of this hook is to provide authState
 * from AuthProvider, and the reason for the abstraction
 * is so we can change our implementation of how the authState
 * is retrieved in the future in once place.
 *
 * @export
 * @returns authState from the AuthProvider
 */
export default function useAuth() {
    const authContext = React.useContext(AuthContext);

    return authContext;
}
