import React from "react";
import {withRouter} from "react-router";
import IoIosMenu from "react-icons/lib/io/android-menu";
import IoIosArrowBack from "react-icons/lib/io/ios-arrow-back";
import classNames from "classnames";
import {Consumer} from "./AppContainer";
import Button from "../../shared/Experimental/General/components/Button/Button";
import s from "./App.pcss";

function Sidebar(props) {
    const {location, isInstitution, isOpen, toggle} = props;
    const isUniversityView = location.pathname.indexOf("/enterprise-management-tools") === -1;

    const toggleNav = () => toggle();

    return (
        <Consumer>
            {({collapsed}) => (
                <React.Fragment>
                    <aside
                        data-test-id="sidebar"
                        className={classNames(s.sidebar, {
                            [s.institution]: isInstitution && !isUniversityView,
                            [s.open]: isOpen,
                            collapsedSidebar: collapsed,
                            [s.collapsedInstitutionSideBar]: isInstitution && isUniversityView,
                        })}
                    >
                        {props.children}
                    </aside>
                    <Button
                        data-test-id="hamburger"
                        onClick={toggleNav}
                        iconOnly
                        color="light"
                        size="lg"
                        className={classNames(s.hamburger, {
                            [s.open]: isOpen,
                        })}
                    >
                        {isOpen ? <IoIosArrowBack /> : <IoIosMenu />}
                    </Button>
                </React.Fragment>
            )}
        </Consumer>
    );
}

export default withRouter(Sidebar);
