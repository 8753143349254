import * as React from "react";
import {SVGProps} from "react";

const IconProfile = (props: SVGProps<SVGSVGElement>& {title?: string}) => {
    const {color = "#272B3D", title, ...rest} = props;
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        role="img"
        {...rest}
    >
        <title>{title ?? "Admin Users"}</title>
        <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M17.982 19.636A7.488 7.488 0 0 0 12 16.66a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21.91a8.966 8.966 0 0 1-5.982-2.275M15 10.66a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        />
    </svg>;
};
export default IconProfile;
