import React, {lazy, Suspense, useEffect, useState} from "react";
import {Spinner} from "@unibuddy/patron";
import {dependencies} from "../../../package.json";
import useDynamicScript from "./useDynamicScript";

const RemoteComponent = props => {
    const {remoteScope, remoteModule, url, nestedProps, shouldPatronBeShared = false} = props;

    const [shouldRender, setShouldRender] = useState(null);

    const {ready, failed} = useDynamicScript(url);

    useEffect(
        () => {
            if (!remoteScope || !remoteModule)
                throw new Error("You must specify scope and module to import a Remote Component");

            if (!ready || failed || !global) return;

            // Don't try to abstract the following in to a loop or some dynamic piece of code.
            // Webpack will break if you don't write static imports even in a dynamic way like Promise.resolve()
            // you must have something like require("react") and not require(dependencyName)
            // Also don't try to convert require calls in to import calls, doesn't work either.
            global[remoteScope].init({
                react: {
                    [dependencies.react]: {
                        // eslint-disable-next-line
                        get: () => Promise.resolve().then(() => () => require("react")),
                    },
                },
                "react-dom": {
                    [dependencies["react-dom"]]: {
                        // eslint-disable-next-line
                        get: () => Promise.resolve().then(() => () => require("react-dom")),
                    },
                },
                "react-router-dom": {
                    [dependencies["react-router-dom"]]: {
                        // eslint-disable-next-line
                        get: () => Promise.resolve().then(() => () => require("react-router-dom")),
                    },
                },
                "react-router": {
                    [dependencies["react-router"]]: {
                        // eslint-disable-next-line
                        get: () => Promise.resolve().then(() => () => require("react-router")),
                    },
                },
                "styled-components": {
                    [dependencies["styled-components"]]: {
                        // eslint-disable-next-line
                        get: () => Promise.resolve().then(() => () => require("styled-components")),
                    },
                },
                ...(shouldPatronBeShared && {
                    "@unibuddy/patron": {
                        [dependencies["@unibuddy/patron"]]: {
                            // eslint-disable-next-line
                            get: () => Promise.resolve().then(() => () => require("@unibuddy/patron")),
                        },
                    },
                }),
                "@unibuddy/tracking": {
                    [dependencies["@unibuddy/tracking"]]: {
                        get: () =>
                            // eslint-disable-next-line
                            Promise.resolve().then(() => () => require("@unibuddy/tracking")),
                    },
                },
                "ub-feature-flagging-react": {
                    [dependencies["ub-feature-flagging-react"]]: {
                        get: () =>
                            Promise.resolve().then(() => () =>
                                // eslint-disable-next-line
                                require("ub-feature-flagging-react"),
                            ),
                    },
                },
                "@unibuddy/analytics-ui": {
                    [dependencies["@unibuddy/analytics-ui"]]: {
                        get: () =>
                            Promise.resolve().then(() => () =>
                                // eslint-disable-next-line
                                require("@unibuddy/analytics-ui"),
                            ),
                    },
                },
                formik: {
                    [dependencies.formik]: {
                        get: () =>
                            Promise.resolve().then(() => () =>
                                // eslint-disable-next-line
                                require("formik"),
                            ),
                    },
                },
            });
            const Component = lazy(() =>
                global[remoteScope].get(remoteModule).then(factory => factory()),
            );

            setShouldRender({Component});
        },
        [failed, ready, remoteModule, remoteScope, shouldPatronBeShared],
    );

    if (!shouldRender) return null;

    const {Component} = shouldRender;

    return (
        <Suspense fallback={<Spinner/>}>
            <Component {...nestedProps} />
        </Suspense>
    );
};

export default RemoteComponent;
