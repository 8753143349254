import moment from "moment";
import * as actions from "../actions/mentorActions";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const defaultState = {
    itemsPerPage: 10,
    selectedMentors: [],
    sortField: "-created",
    filters: {
        degreeLevel: "",
        degreeName: "",
        year: "",
        unreadMessageState: "",
        firstName: "",
        lastName: "",
        email: "",
        staffRole: "",
        department: "",
        linkedUniversity: "",
        country: "",
        fromDate: moment
            .utc()
            .startOf("isoWeek")
            .format(DATE_FORMAT),
        toDate: moment
            .utc()
            .endOf("isoWeek")
            .format(DATE_FORMAT),
    },
    activeStatus: "active",
};

function mentorReducer(state = defaultState, action) {
    switch (action.type) {
        case actions.SELECT_MENTOR:
            return Object.assign({}, state, {
                selectedMentors: [...state.selectedMentors, action.mentorId],
            });
        case actions.SELECT_MULTIPLE_MENTORS:
            return Object.assign({}, state, {
                selectedMentors: [...new Set([...state.selectedMentors, ...action.mentorIds])],
            });
        case actions.DESELECT_MENTOR:
            return Object.assign({}, state, {
                selectedMentors: state.selectedMentors.filter(item => item !== action.mentorId),
            });
        case actions.CLEAR_MENTOR_SELECTION:
            return Object.assign({}, state, {
                selectedMentors: [],
            });
        case actions.SET_SORT_FIELD:
            return Object.assign({}, state, {
                sortField: action.sortField,
            });
        case actions.SET_MENTOR_FILTER:
            return Object.assign({}, state, {
                filters: Object.assign({}, state.filters, {
                    [action.filterKey]: action.filterText,
                }),
            });
        case actions.RESET_MENTOR_TABLE:
            return {...defaultState, filters: {...defaultState.filters}};
        default:
            return state;
    }
}

export default mentorReducer;
