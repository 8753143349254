import React, {useState} from "react";
import styled from "styled-components";
import {useQuery} from "react-apollo";
import get from "lodash/get";
import PropTypes from "prop-types";
import Box from "shared//Experimental/Layout/components/Box/Box";
import colours from "shared/Styling/colours";
import TextBlock from "shared/Experimental/Typography/components/TextBlock/TextBlock";
import Stack from "shared//Experimental/Layout/components/Stack/Stack";
import globalAlertQuery from "../queries/globalAlert";

const Container = styled.div``;

const GlobalAlertComponent = ({title, message}) => {
    const [showAlertMessage, setShowAlertMessage] = useState(true);
    return (
        <Box paddingTop="medium">
            <Container onClick={() => setShowAlertMessage(!showAlertMessage)}>
                <Stack space="small">
                    <TextBlock weight={700} size="small" color={colours.redCloud}>
                        Alert: {title}
                    </TextBlock>
                    {showAlertMessage && <TextBlock size="small">{message}</TextBlock>}
                </Stack>
            </Container>
        </Box>
    );
};

GlobalAlertComponent.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
};

GlobalAlertComponent.defaultProps = {
    title: "",
    message: "",
};

export const GlobalAlert = () => {
    const {data} = useQuery(globalAlertQuery, {
        variables: {
            active: true,
        },
    });
    const alerts = get(data, "globalAlerts", []);

    return alerts.length ? (
        <Box paddingX="large" paddingBottom="medium">
            {alerts.map(alert => (
                <GlobalAlertComponent title={alert.title} message={alert.message} />
            ))}
        </Box>
    ) : null;
};
