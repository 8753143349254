/* eslint-disable */
import React from "react";
import compose from "lodash/flowRight";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Redirect, Route, Switch} from "react-router-dom";
import get from "lodash/get";
import {useBooleanFeatureFlag} from "ub-feature-flagging-react";
import {config} from "shared/ConfigManager/ConfigManager";
import ErrorBoundary from "shared/General/components/ErrorBoundary/ErrorBoundary";
import ProfileRoutes from "./modules/Mentor/containers/ProfileRoute";
import ResourceLibraryPage from "./modules/ResourseLibrary/ResourceLibraryPage";
import AddCustomAttributeFormV2
        from "./modules/CustomizationAmbassador/components/AddCustomAttributeForm/AddCustomAttributeFormV2";
import {AnalyticsDashboardType} from "./modules/Analytics/utils/constant";
import AnalyticsPage from "./modules/Analytics/containers/AnalyticsPage";

const DegreeLevelsAndTypes = React.lazy(() =>
    import(/* webpackChunkName: "DegreeLevelsAndTypes" */ "./modules/DegreeLevels/containers/Degree"),
);

const LiveEventDetailsPage = React.lazy(() =>
    import(/* webpackChunkName: "LiveEventDetailsPage" */ "./modules/LiveEvents/containers/LiveEventDetailsPage/LiveEventDetailsPage"),
);

const BuddyPage = React.lazy(() =>
    import(/* webpackChunkName: "BuddyPage" */ "./modules/Mentor/containers/BuddyPageRoutes"),
);

const ApplicantPage = React.lazy(() =>
    import(/* webpackChunkName: "ApplicantPage" */ "./modules/Applicant/containers/ApplicantPage"),
);

const ProspectNextStepsPage = React.lazy(() =>
    import(/* webpackChunkName: "ProspectNextStepsPage" */ "./modules/ProspectNextSteps/containers/ProspectNextSteps"),
);

const CustomAttributeContentPage = React.lazy(() =>
    import(/* webpackChunkName: "CustomAttributeContentPage" */ "./modules/CustomizationAmbassador/containers/CustomAttributeContentPage"),
);

const CustomizationAmbassador = React.lazy(() =>
    import(/* webpackChunkName: "CustomizationAmbassador" */ "./modules/CustomizationAmbassador/containers/CustomizationAmbassadorRoutes"),
);

const UcasMentorList = React.lazy(() =>
    import(/* webpackChunkName: "UcasMentorList" */ "./modules/Markeplaces/ucas/containers/UcasMentorList"),
);

const CustomizationProspectPageOld = React.lazy(() =>
    import(/* webpackChunkName: "CustomizationProspectPageOld" */ "./modules/CustomizationProspects/containers/CustomizationProspectPageOld"),
);

const CustomizationProspectPage = React.lazy(() =>
    import(/* webpackChunkName: "CustomizationProspectPage" */ "./modules/CustomizationProspects/containers/CustomizationProspectPage"),
);

const UcasAnalyticsRoutes = React.lazy(() =>
    import(/* webpackChunkName: "UcasAnalyticsRoutes" */ "./modules/Markeplaces/ucas/containers/UcasAnalytics"),
);

const CustomizationFeatures = React.lazy(() =>
    import(/* webpackChunkName: "CustomizationFeatures" */ "./modules/CustomizationFeatures/containers/CustomizationFeaturesPage"),
);

const UserSettingsPage = React.lazy(() =>
    import(/* webpackChunkName: "UserSettingsPage" */ "./modules/Settings/containers/UserSettingsPage"),
);

const InvitePage = React.lazy(() =>
    import(/* webpackChunkName: "InvitePage" */ "./modules/Invite/containers/InvitePageRoutes"),
);

const ConnectPage = React.lazy(() =>
    import(/* webpackChunkName: "ConnectPage" */ "./modules/Integrations/Connect/ConnectPage"),
);

const LoadingSalesforce = React.lazy(() =>
    import(/* webpackChunkName: "LoadingSalesforce" */ "./modules/Integrations/lib/Salesforce/containers/LoadingSalesforce/LoadingSalesforce"),
);

const IntegrationsPage = React.lazy(() =>
    import(/* webpackChunkName: "IntegrationsPage" */ "./modules/Integrations/List/containers/IntegrationsPage/IntegrationsPage"),
);

const IntegrationView = React.lazy(() =>
    import(/* webpackChunkName: "IntegrationView" */ "./modules/Integrations/View/containers/IntegrationView/IntegrationView"),
);

const AnalyticsRoutes = React.lazy(() =>
    import(/* webpackChunkName: "analytics" */ "./modules/Analytics/containers/AnalyticsRoutes"),
);

const ChatIntervention = React.lazy(() =>
    import(/* webpackChunkName: "chat-intervention" */ "./modules/Chat/InterventionRoutes"),
);

const ChatPage = React.lazy(() =>
    import(/* webpackChunkName: "chat" */ "./modules/Chat/containers/Chat/Chat"),
);
const OutboxPage = React.lazy(() =>
    import(/* webpackChunkName: "outbox" */ "./modules/Outbox/containers/Outbox"),
);
const InboxPage = React.lazy(() =>
    import(/* webpackChunkName: "inbox" */ "./modules/Inbox/containers/Inbox"),
);

const ModerationPage = React.lazy(() =>
    import(/* webpackChunkName: "moderation" */ "./modules/Moderation/containers/ModerationPage"),
);

const SnippetsRoutes = React.lazy(() =>
    import(/* webpackChunkName: "snippets" */ "./modules/CodeSnippets/containers/SnippetsRoutes"),
);

const BlogPageContainer = React.lazy(() =>
    import(/* webpackChunkName: "blogs" */ "./modules/Blog/containers/Blog"),
);

const EventsPage = React.lazy(() =>
    import(/* webpackChunkName: "events" */ "./modules/Events/containers/EventsPage"),
);

const AssistantPage = React.lazy(() =>
    import(/* webpackChunkName: "events" */ "./modules/Assistant/containers/AssistantPage"),
);

const ProspectInvitePage = React.lazy(() =>
    import(/* webpackChunkName: "ProspectInvitePage" */ "./modules/ProspectInvite/containers/ProspectInvitePage"),
);

const LiveEventsPage = React.lazy(() =>
    import(/* webpackChunkName: "live-events" */ "./modules/LiveEvents/containers/LiveEventsPage/LiveEventsPage"),
);

const CreateLiveEvent = React.lazy(() =>
    import(/* webpackChunkName: "create-live-event" */ "./modules/LiveEvents/containers/CreateLiveEvent/CreateLiveEvent"),
);

const SessionRecordings = React.lazy(() =>
    import(/* webpackChunkName: "session-recordings" */ "./modules/LiveEvents/components/SessionRecordings/SessionRecordings"),
);

const EditLiveEvent = React.lazy(() =>
    import(/* webpackChunkName: "edit-live-event" */ "./modules/LiveEvents/containers/EditLiveEvent/EditLiveEvent"),
);

const EventsAdmin = React.lazy(() =>
    import(/* webpackChunkName: "events-admin" */ "./modules/Events/containers/EventsAdmin"),
);

const PastApplicationInsights = React.lazy(() =>
    import(/* webpackChunkName: "user-insights" */ "./modules/UserInsights/containers/PastApplicationInsights"),
);

const Insights = React.lazy(() =>
    import(/* webpackChunkName: "insights" */ "./modules/Insights/containers/Insights"),
);

const Community = React.lazy(() =>
    import(/* webpackChunkName: "community-landing-page" */ "./modules/Community/containers/Community"),
);

const Admins = React.lazy(() =>
    import(/* webpackChunkName: "admins-landing-page" */ "./modules/Admins/containers/Admins"),
);

const Degrees = React.lazy(() =>
    import(/* webpackChunkName: "degrees-landing-page" */ "./modules/Degrees/containers/Degrees"),
);
const Timesheet = React.lazy(() =>
    import(/* webpackChunkName: "timesheet-landing-page" */ "./modules/Timesheet/containers/TimesheetPage"),
);

const MainNavRoutes = compose(
    withRouter,
    connect(({authState}) => ({authState})),
)(({authState, isInstitution}) => {
        const isNextStepsEnabled = useBooleanFeatureFlag(config.LD_ACTIONABLE_INSIGHTS_V1, false);
        const isProspectInviteEnabled = useBooleanFeatureFlag(config.LD_UNIDASH_EDM_ADMIN_INVITES_PROSPECTS, false);
        const isAmboProfileEditEnabled = useBooleanFeatureFlag(config.LD_EDIT_AMBASSADOR_PROFILE, false);
        const isNewAmboCustomisationRouteEnabled = useBooleanFeatureFlag(config.LD_AMBASSADOR_PROFILE_CUSTOMISATION, false);
        const isResourceLibraryEnabled = useBooleanFeatureFlag(config.LD_RESOURCE_LIBRARY, false);
        const isCustomisationLevelAndYearEnabled = useBooleanFeatureFlag(config.LD_CUSTOMISE_DEGREE_LEVEL_AND_YEAR, false);
        const isAssistantEnabled = useBooleanFeatureFlag(config.ASSISTANT_TEMP, false);
        const isCumulAnalyticsEnabled = useBooleanFeatureFlag(config.LD_UNIDASH_CUMUL_ANALYTICS_ROUTES, false);
        const isProspectInsightsEnabled = useBooleanFeatureFlag(config.LD_UNIDASH_PROSPECT_INSIGHTS_ANALYTICS, false);

        return <Switch>
        {isInstitution && (
            <Route
                path="/"
                exact
                render={() => <Redirect to="/enterprise-management-tools/analytics" />}
            />
        )}
        <Route path="/" exact component={AnalyticsRoutes} />

        <Route path="/analytics" render={props => <AnalyticsRoutes {...props} />} />

        <Route path="/account/invite" component={InvitePage} />

        <Route path="/conversations" component={ChatIntervention} />
        {isAmboProfileEditEnabled && <Route
            path="/buddies/student/profile"
            render={renderProps => (
                <ErrorBoundary>
                    <ProfileRoutes  authState={authState} rootUrl={"/buddies/student/profile"} {...renderProps} />
                </ErrorBoundary>
            )}
        />}

        <Route path="/buddies" render={props => <BuddyPage {...props} />} />

        {isNextStepsEnabled || isProspectInviteEnabled ? <Route path="/applicant" exact render={() => <Redirect to="/applicant/manage" />} /> :
        <Route path="/applicant" exact component={ApplicantPage} />}

        {isNextStepsEnabled || isProspectInviteEnabled ? <Route path="/applicant/manage" component={ApplicantPage} /> :
        <Route path="/applicant/manage" render={() => <Redirect to="/404" />} />}

        {isNextStepsEnabled ? <Route path="/applicant/next-steps" render={props => <ProspectNextStepsPage authState={authState}/>} /> :
        <Route path="/applicant/next-steps" render={() => <Redirect to="/404" />} />}

        {isProspectInviteEnabled ? <Route path="/applicant/invite" render={props => <ProspectInvitePage authState={authState} {...props}/>} /> :
        <Route path="/applicant/invite" render={() => <Redirect to="/404" />} />}

        <Route path="/moderation/:pageId" render={props => <ModerationPage {...props} />} />
        <Route path="/moderation" render={props => <ModerationPage {...props} />} />
        <Route
            path="/content"
            render={props => <BlogPageContainer {...props} authState={authState} />}
        />

        <Route
            path="/inbox/:chatGroupId"
            render={props => <ChatPage redirectRoute={"/inbox"} {...props} />}
        />
        <Route
            path="/outbox/:chatGroupId"
            render={props => <ChatPage redirectRoute={"/outbox"} {...props} />}
        />
        <Route path="/inbox" render={props => <InboxPage {...props} />} />
        <Route path="/outbox" render={props => <OutboxPage {...props} />} />

        <Route path="/integrating/salesforce" component={LoadingSalesforce} />
        <Route path="/integrations/:crmIdentifier/connect" component={ConnectPage} />
        <Route path="/integrations/:crmIdentifier/:integrationId" component={IntegrationView} />
        <Route path="/integrations" component={IntegrationsPage} />
        <Route path="/customization/features" component={CustomizationFeatures} />
        <Route
            path="/customization/ambassadors/attribute/add"
            render={(props) => {
                const state = props?.location?.state;
                return isNewAmboCustomisationRouteEnabled ? (
                <AddCustomAttributeFormV2 destination={state?.destination} backUrl={state?.backUrl}/>
                ) : null;
            }}
        />
        {isCustomisationLevelAndYearEnabled && <Route
            path="/customization/ambassadors/level_and_year"
            render={() => {
                return <div> Customise degree level and Year of study </div>
            }}
        />}
        <Route
            path="/customization/ambassadors/attribute/:attributeId"
            component={CustomAttributeContentPage}
        />
        <Route path="/customization/ambassadors" component={CustomizationAmbassador} />
        <Route
            path="/customization/prospect/attribute/entries"
            component={CustomizationProspectPageOld}
        />
        <Route
            path="/customization/prospect/attribute/:attributeId"
            component={CustomAttributeContentPage}
        />
        <Route path="/customization/prospects" component={CustomizationProspectPage} />
        <Route path="/customization" component={CustomizationProspectPage} />

        <Route path="/settings" component={UserSettingsPage} />

        <Route path="/snippets" render={props => <SnippetsRoutes {...props} />} />

        <Route path="/events" exact>
            <EventsPage authState={authState} />
        </Route>

        {isAssistantEnabled ?
            <Route path="/assistant" exact component={AssistantPage}/>
            : isInstitution && (
                <Route
                    path="/"
                    exact
                    render={() => <Redirect to="/enterprise-management-tools/analytics" />}
                />
        )}

        <Route
            path="/events/microsite/admin"
            render={props => <EventsAdmin authState={authState} {...props} />}
        />

        <Route path="/live-events/:id/session-recordings">
            <SessionRecordings />
        </Route>

        <Route
            path="/live-events/details/:id"
            render={props => <LiveEventDetailsPage {...props} />}
        />

        <Route path="/live-events/create" render={props => <CreateLiveEvent {...props} />} />
        <Route path="/live-events/update/:id" render={props => <EditLiveEvent {...props} />} />
        <Route
            path="/live-events/update-active/:id"
            render={props => <EditLiveEvent {...props} />}
        />
        <Route
            path="/live-events/:view"
            render={props => (
                <LiveEventsPage
                    {...props}
                    universitySlug={get(authState, "me.universityUser.university.slug", "")}
                />
            )}
        />
        <Route path="/live-events" render={() => <Redirect to="/live-events/upcoming" />} />

        <Route
            path="/marketplaces/analytics"
            render={props => <UcasAnalyticsRoutes authState={authState} {...props} />}
        />
        <Route
            path="/marketplaces/ambassadors"
            render={props => <UcasMentorList authState={authState} {...props} />}
        />
        <Route path="/insights" exact render={() => <Redirect to="/insights/conversation-insights" />} />
        <Route path="/past-application-insights" render={(props) => <Redirect to="/insights/application-insights" {...props} />} />

        <Route path="/insights/conversation-insights" component={Insights} />
        {isCumulAnalyticsEnabled && isProspectInsightsEnabled && <Route path="/insights/prospect-insights" render={() => <AnalyticsPage
            authState={authState}
            analyticsDashboardType={AnalyticsDashboardType.ProspectInsights}
        />} />}
        <Route path="/insights/application-insights" render={(props) => <PastApplicationInsights authState={authState} {...props} />} />

        <Route path="/blank" render={() => null} />
        <Route
            path="/404"
            render={() => (
                <Redirect
                    to={{
                        pathname: "/auth/logout/?next=true",
                        state: {
                            nextPath: "/auth/login",
                        },
                    }}
                />
            )}
        />
        <Route path="/community" render={props => <Community authState={authState} {...props} />} />
        {isResourceLibraryEnabled && <Route path="/resource-library" component={ResourceLibraryPage} />}
        <Route path="/users" render={props => <Admins authState={authState} {...props} />} />
        <Route path="/degrees" render={props => <Degrees authState={authState} {...props} />} />
        <Route
            path="/degree"
            render={props => <DegreeLevelsAndTypes authState={authState} {...props} />}
        />

        <Route path="/timesheet" render={props => <Timesheet authState={authState} {...props} />} />

    </Switch>
});

export default connect(({authState}) => ({authState}))(MainNavRoutes);
