import React, {memo} from "react";
import IoClose from "react-icons/lib/io/android-close";
import {useTranslation} from "@unibuddy/intl";

function CloseIcon(props) {
    const {t} = useTranslation("common");
    return <IoClose {...props} alt={t("close", "Close")} />;
}

export default memo(CloseIcon);
