import useUniversityFeature from "shared/Feature/useUniversityFeature";
import {enableIntercomWidget, logoutIntercom} from "shared/Intercom/Intercom";
import initialiseZendesk, {hideZendex} from "shared/Zendesk/Zendesk";

export const SupportWidget = ({me}) => {
    const {loading, isFeatureEnabled} = useUniversityFeature({
        universitySlug: me.universityUser.university.slug,
        feature: "intercomWidget",
    });
    if (!loading) {
        if (isFeatureEnabled) {
            enableIntercomWidget(me);
            hideZendex(); //if zendex widget is already visible
        } else {
            initialiseZendesk();
            logoutIntercom(); //if intercom widget is already visible
        }
    }
    return null;
};
