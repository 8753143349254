import React from "react";
import Logo from "shared/Experimental/General/components/Logo/Logo";
import s from "./AuthRoutesContainer.pcss";

const AuthRoutesContainer = props => {
    return (
        <div className={s.container}>
            <div className={s.logoContainer}>
                <Logo className={s.logo} width={121} />
            </div>
            <div className={s.body}>{props.children}</div>
        </div>
    );
};

export default AuthRoutesContainer;
