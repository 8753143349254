import {routerReducer as routing} from "react-router-redux";
import mentorReducer from "../../Mentor/reducers/mentorReducer";
import inviteReducer from "../../Invite/reducers/inviteReducer";
import blogTopicReducer from "../../../shared/Blog/reducers/blogTopicReducer";
import authReducer from "../../../shared/Auth/reducers/authReducer";
import messengerReducer from "../../../shared/Chat/reducers/messengerReducer";
import blogReducer from "../../Blog/reducers/blogReducer";

const rootReducer = {
    authState: authReducer,
    mentorState: mentorReducer,
    inviteState: inviteReducer,
    blogTopicState: blogTopicReducer,
    blogState: blogReducer,
    messengerState: messengerReducer,
    routing,
};

export default rootReducer;
