import * as React from "react";
import {SVGProps} from "react";

const IconLightBulb = (props: SVGProps<SVGSVGElement>& {title?: string}) => {
    const {color = "#131622", title, ...rest} = props;
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        role="img"
        {...rest}
    >
        <title>{title ?? "Insights"}</title>
        <g clipPath="url(#a)">
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 18.911v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.19a6.01 6.01 0 0 1-1.5-.19m3.75 7.479a12.069 12.069 0 0 1-4.5 0m3.75 2.382a14.406 14.406 0 0 1-3 0m3.75-4.422v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.334 1.509 2.317v.191"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 .911h24v24H0z"/>
            </clipPath>
        </defs>
    </svg>;
};
export default IconLightBulb;
