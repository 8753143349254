import React from "react";
import compose from "lodash/flowRight";
import {connect} from "react-redux";

function withAuth(WrappedComponent) {
    const withAuthContainer = props => <WrappedComponent {...props} />;

    const mapStateToProps = state => ({
        authState: state.authState,
        me: state.authState.me,
    });
    return compose(connect(mapStateToProps))(withAuthContainer);
}

export default withAuth;
