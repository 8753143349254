import React from "react";
import {Query} from "react-apollo";
import {MINUTES} from "shared/Utils/DateUtils";
import BLOG_COUNT_QUERY from "./query";

const BlogCountQuery = ({render}) => (
    <Query query={BLOG_COUNT_QUERY} asyncMode pollInterval={5 * MINUTES}>
        {({data, error, loading}) => {
            if (loading || error) {
                return render({count: 0});
            }
            return render({count: data.universityBlogPostsCount});
        }}
    </Query>
);

export default BlogCountQuery;
