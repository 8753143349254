import React, {useContext} from "react";
import Hotjar from "@hotjar/browser";
import {useBooleanFeatureFlag} from "ub-feature-flagging-react";
import {ConfigContext} from "shared/ConfigContext/configContext";

const LD_UNIDASH_HOTJAR = "ub-university-dashboard-hot-jar";

export default function HotJar() {
    const config = useContext(ConfigContext);
    const isHotJarEnabled = useBooleanFeatureFlag(LD_UNIDASH_HOTJAR, false);
    const isProd = config.ENV_NAME === "production";

    React.useEffect(() => {
        if (isHotJarEnabled) {
            const siteId = isProd ? 3386954 : 3317986;
            const hotjarVersion = 6;
            Hotjar.init(siteId, hotjarVersion);
        }
    }, [isHotJarEnabled, isProd]);

    return null;
}
