// / action types

export const SELECT_BLOG_TOPIC = "SELECT_BLOG_TOPIC";
export const DESELECT_BLOG_TOPIC = "DESELECT_BLOG_TOPIC";
export const CLEAR_SELECTED_BLOG_TOPICS = "CLEAR_SELECTED_BLOG_TOPICS";

// / action creators

export function selectBlogTopic(topicId) {
    return {
        type: SELECT_BLOG_TOPIC,
        topicId,
    };
}

export function deselectBlogTopic(topicId) {
    return {
        type: DESELECT_BLOG_TOPIC,
        topicId,
    };
}

export function clearSelectedBlogTopics() {
    return {
        type: CLEAR_SELECTED_BLOG_TOPICS,
    };
}
