/**
 * Destructure and transform to a string. (Not usefully,
 * the Redux store field is named "token" and one of its
 * fields is also called "token")
 *
 * @param {{scheme: string, token: string}} token
 * @returns {string}
 */
export function toAuthorizationScheme ({scheme, token}) {
    return `${scheme} ${token}`;
}

/**
 * Using the token field retrieved from the Redux store,
 * generate the value for a request Authorization header
 *
 * @param {string | {scheme: string, token: string}} token
 * @returns {string}
 */
export function getAuthorizationHeaderFor (token) {
    if (typeof token === 'string') {
        // A string means non-SSO (JWT)
        return `JWT ${token}`;
    }

    // An object means SSO (Bearer)
    return toAuthorizationScheme(token);
}
