import * as actions from "../actions/inviteActions";

function inviteReducer(
    state = {
        pageNumber: 0,
        selectedInvites: [],
        sortField: "-lastUpdatedAt",
        filters: {
            accepted: "",
        },
    },
    action,
) {
    switch (action.type) {
        case actions.SET_INVITE_PAGE_NUMBER:
            return Object.assign({}, state, {
                pageNumber: action.pageNumber,
            });
        case actions.SELECT_INVITE:
            return Object.assign({}, state, {
                selectedInvites: [...state.selectedInvites, action.inviteId],
            });
        case actions.DESELECT_INVITE:
            return Object.assign({}, state, {
                selectedInvites: state.selectedInvites.filter(item => item !== action.inviteId),
            });
        case actions.CLEAR_INVITE_SELECTION:
            return Object.assign({}, state, {
                selectedInvites: [],
            });
        case actions.SET_SORT_FIELD:
            return Object.assign({}, state, {
                sortField: action.sortField,
            });
        case actions.SET_INVITE_FILTER:
            return Object.assign({}, state, {
                filters: Object.assign({}, state.filters, {
                    [action.filterKey]: action.filterText,
                }),
            });
        default:
            return state;
    }
}

export default inviteReducer;
