import React from "react";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import Popover from "react-bootstrap/lib/Popover";
import TooltipIndicator from "../../../../shared/General/components/TooltipIndicator/TooltipIndicator";

const popover = (
    <Popover title="Add New Field">
        Add a new field to your ambassador profiles and the options that they can choose from.
        <br />
        Example:
        <br />
        <strong>Field Name:</strong> Campus
        <br />
        <strong>Options:</strong>
        London Campus
        <br />
        NY Campus
        <br />
        Shanghai Campus
    </Popover>
);
const AddNewFieldTooltip = () => (
    <OverlayTrigger placement="right" overlay={popover}>
        <TooltipIndicator />
    </OverlayTrigger>
);
export default AddNewFieldTooltip;
