import {ApolloLink} from "apollo-link";
import {ApolloClient} from "apollo-client";
import {createHttpLink} from "apollo-link-http";
import {InMemoryCache} from "apollo-cache-inmemory";
import {setContext} from "apollo-link-context";
import {onError} from "apollo-link-error";
import get from "lodash/get";
import {v4 as uid} from "uuid";
import {clearAuth} from "shared/Auth/actions/authActions";
import {createCustomFetch} from "shared/Core/defaultLink";
import {configureRequestContext} from "shared/General/components/ErrorReporting/errorReportingAdaptor";
import {config} from "shared/ConfigManager/ConfigManager";
import {getAuthorizationHeaderFor} from 'shared/Helpers/AuthorizationHelper';
import {store} from "../../main";

function getApolloLinks() {
    const httpLink = createHttpLink({
        uri: `${config.GATEWAY_URL}/graphql`,
        fetch: createCustomFetch(),
        headers: {
            "keep-alive": "true",
        },
    });

    const middlewareLink = setContext(() => {
        const token = get(store.getState(), "authState.token", false);
        // generate unique request id and setting it as Sentry tag and attaching it to every request
        const requestId = uid();
        configureRequestContext(requestId);
        if (token) {
            return {
                headers: {
                    authorization: getAuthorizationHeaderFor(token),
                    "X-Request-Id": requestId,
                },
            };
        }

        return {
            headers: {
                "X-Request-Id": requestId,
            },
        };
    });

    const errorLink = onError(({networkError = {}}) => {
        if (networkError.statusCode === 401) {
            store.dispatch(clearAuth());
            // Refresh the page
            window.location = window.location; // eslint-disable-line  no-self-assign
        }
    });

    return ApolloLink.from([errorLink, middlewareLink, httpLink]);
}

export default function createApolloGatewayClient() {
    const link = getApolloLinks();
    const cache = new InMemoryCache({
        dataIdFromObject: o => o.id,
    });

    return new ApolloClient({
        link,
        cache,
        name: "university-dashboard",
    });
}
