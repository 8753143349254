import React from "react";
import classNames from "classnames";
import s from "./Panel.pcss";

export const Panel = ({children, overflowHidden, className}) => (
    <div
        className={classNames(
            "panel",
            "panel-default",
            {[s.overflowHidden]: overflowHidden},
            className,
        )}
    >
        {children}
    </div>
);

export const PanelBody = ({noPadding, children}) => (
    <div
        className={classNames("panel-body", {
            "no-padding": noPadding === true,
        })}
    >
        {children}
    </div>
);

export const PanelTitle = ({children}) => <div className={s.title}>{children}</div>;
