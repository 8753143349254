import gql from "graphql-tag";

const anyUserFragment = gql`
    fragment AnyUserFull on AnyUserField {
        id
        email
        firstName
        lastName
        accountRole
        degreeLevel
        chatToken
        verified
        country {
            id
            name
        }
        domesticRegion {
            id
            name
        }
        profilePhoto
    }
`;

export default anyUserFragment;
