import React from "react";
import {Link} from "react-router-dom";
import AccountLocked from "../../../../shared/PasswordReset/components/AccountLocked/AccountLocked";
import ContentLayout from "../../components/ContentLayout/ContentLayout";

const requestPasswordLink = <Link to="/auth/reset">here.</Link>;

const ResetPasswordLayout = () => (
    <ContentLayout>
        <AccountLocked requestResetPasswordLink={requestPasswordLink} />
    </ContentLayout>
);

export default ResetPasswordLayout;
