import React, {Component, Dispatch} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {hot} from "react-hot-loader/root";
import {withApollo} from "react-apollo";
import {withRouter} from "react-router";
import get from "lodash/get";
import {DesignSystemProvider} from "@unibuddy/patron";
import ErrorBoundary from "shared/General/components/ErrorBoundary/ErrorBoundary";
import ErrorReportingProvider from "shared/General/components/ErrorReporting/ErrorReportingProvider";
import {
    apps,
    errorReportingAdaptor,
    initErrorReportingService,
} from "shared/General/components/ErrorReporting/errorReportingAdaptor";
import {AnalyticsProvider} from "@unibuddy/tracking";
import SocketProvider from "shared/Experimental/Sockets/SocketProvider/SocketProvider";
import AuthProvider from "shared/Auth/AuthProvider/AuthProvider";
import {config} from "shared/ConfigManager/ConfigManager";
import {SkipNavLink} from "shared/Experimental/A11y/components/SkipNav/SkipNav";
import Intl, {Constants, I18nextEngine} from "@unibuddy/intl";
import {createFeatureFlaggingProvider, User} from "ub-feature-flagging-react";
import {withConstantTheme} from "shared/Styling/hocs/withTheme";
import {setMe} from "shared/Auth/actions/authActions";
import {ApolloClient} from "apollo-client";
import {universityDashboardTheme} from "./theme";
import withAuth from "./shared/Auth/withAuth/withAuth";
import Auth from "./shared/Auth/auth";
import meQuery from "./shared/Auth/queries/meQuery";
import MainRoutes from "./MainRoutes";
import analyticsProductSpecification from "./analyticsProductSpecification";
import HotJar from "./HotJar";
import "./app.css";

const clientID: string = config.UB_LAUNCHDARKLY_CLIENT_ID || "";
const FeatureFlaggingProvider: any = createFeatureFlaggingProvider(
    clientID,
);

// Initialise i18next
const options = {
    appName: Constants.APP_NAMES.UNIVERSITY_DASHBOARD,
    lng: Constants.DEFAULT_LOCALE,
    translationsUrl: config.TRANSLATIONS_URL,
};
const i18nextEngine = new I18nextEngine(options);

// Initialise Sentry
initErrorReportingService({app: apps.UNIVERSITY_DASHBOARD, env: ""});

const analyticsEvents = {
    ...analyticsProductSpecification.shared.events,
    ...analyticsProductSpecification.chat.events,
};

type AppProps = {
    authState: Record<string, any>;
    client: ApolloClient<any>;
    dispatch: Dispatch<any>;
    analyticsAdaptors: Array<any>;
}

class App extends Component<AppProps> {
    async componentDidMount() {
        if (Auth.loggedIn(this.props.authState)) {
            const result = await this.props.client.query({
                query: meQuery,
            });
            this.props.dispatch(setMe(result.data.me));
        }
    }

    render() {
        const university = get(this.props.authState, "me.universityUser.university", {});
        const slug: string = get(university, "slug");
        const id = `ub-university-dashboard-${slug}`;
        const user: User = new User(id, {
            university: {
                id: get(university, "id"),
                slug,
                name: get(university, "name"),
            },
        });

        return (
            <ErrorReportingProvider adaptor={errorReportingAdaptor}>
                <ErrorBoundary>
                    <AuthProvider>
                        <FeatureFlaggingProvider user={user}>
                            <HotJar />
                            <AnalyticsProvider
                                user={get(this.props.authState, "me.anyUser")}
                                adaptors={this.props.analyticsAdaptors}
                                productEvents={analyticsEvents}
                            >
                                <SocketProvider>
                                    <DesignSystemProvider theme={universityDashboardTheme}>
                                        <SkipNavLink
                                            contentId={"main-content"}
                                            className={"skip-to-content-link"}
                                        >
                                            Skip to content
                                        </SkipNavLink>
                                        {/*
                                        // @ts-ignore: add crowdin project name later when starting with translations */}
                                        <Intl engine={i18nextEngine}>
                                            <MainRoutes/>
                                        </Intl>
                                    </DesignSystemProvider>
                                </SocketProvider>
                            </AnalyticsProvider>
                        </FeatureFlaggingProvider>
                    </AuthProvider>
                </ErrorBoundary>
            </ErrorReportingProvider>
        );
    }
}

const themeValue = "#306FAE";
export default compose(
    hot,
    withRouter,
    connect(),
    withAuth,
    withApollo,
    withConstantTheme(themeValue),
)(App);
