import gql from "graphql-tag";

const UNANSWERED_AND_UNRESOLVED_QUERY = gql`
    query MentorApplicantChatGroupCount($getMentor: Boolean = true, $getStaff: Boolean = true) {
        mentorApplicantChatGroupCount(unanswered: true) @include(if: $getMentor)
        buddyApplicantChatGroupCount(unanswered: true) @include(if: $getStaff)
    }
`;

export default UNANSWERED_AND_UNRESOLVED_QUERY;
