import React from "react";
import styled from "styled-components";
import GoAlert from "react-icons/lib/go/alert";
import {Box, Columns, Column, Stack, TextBlock} from "@unibuddy/patron";

const SupportLink = styled.span`
    text-decoration-line: underline !important;
`;

const IconBox = styled(Box)`
    padding-top: 25px;
`;

const Alert = ({bgColor, width, text, color, errorMessage, bold, misc, isDepartment}) => {
    return (
        <Box backgroundColor={bgColor || "#FFC1C1"}>
            <Columns space="xsmall">
                <Column width="content">
                    <IconBox paddingLeft={isDepartment ? "medium" : "large"}>
                        <GoAlert size={18} />
                    </IconBox>
                </Column>
                <Column width={width || "3/4"}>
                    <Box paddingX="xsmall" paddingY="medium" data-test-id={"alert-error-message"}>
                        {text === "custom" ? (
                            <Stack space="small">
                                <TextBlock color={color || "#404446"} weight="500">
                                    {errorMessage}
                                    <SupportLink color={color || "#404446"}>
                                        <b>{bold} </b>
                                    </SupportLink>
                                    {misc}
                                </TextBlock>
                            </Stack>
                        ) : (
                            <Stack space="small">
                                <TextBlock color={color || "#404446"} weight="600">
                                    {errorMessage}
                                </TextBlock>
                                <TextBlock color={color || "#404446"} weight="600">
                                    {misc}
                                    <SupportLink color={color || "#404446"}>
                                        <b>{bold}</b>
                                    </SupportLink>
                                </TextBlock>
                            </Stack>
                        )}
                    </Box>
                </Column>
            </Columns>
        </Box>
    );
};

export default Alert;
