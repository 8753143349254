import PropTypes from "prop-types";
import React, {Component} from "react";
import compose from "lodash/flowRight";
import {graphql} from "react-apollo";
import {connect} from "react-redux";
import requestPasswordResetMutation from "../../../Auth/mutations/requestPasswordResetMutation";
import ProgressBar from "../../../General/components/ProgressBar/ProgressBar";
import PasswordReset from "../../components/PasswordReset/PasswordReset";

class RequestPasswordReset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
        };
    }

    async onRequestPasswordReset({email}, formActions) {
        this.setState({loading: true});
        try {
            await this.props.requestPasswordReset({
                variables: {
                    email: email.toLowerCase(),
                    redirectUrl: this.props.redirectUrl,
                    rootUrl: this.props.rootUrl,
                    universitySlug: this.props.universitySlug,
                    localePreference: this.props.currentLocale,
                },
            });

            formActions.setSubmitting(false);
            this.props.onComplete();
        } catch (e) {
            this.setState({loading: false, error: true});
            formActions.setSubmitting(false);
        }
    }

    render() {
        return (
            <div>
                <ProgressBar start={this.state.loading} />
                <PasswordReset
                    loginLink={this.props.loginLink}
                    themePrimary={this.props.themePrimary}
                    onRequestPasswordReset={this.onRequestPasswordReset.bind(this)}
                />
            </div>
        );
    }
}

RequestPasswordReset.propTypes = {
    themePrimary: PropTypes.string.isRequired,
    loginLink: PropTypes.string.isRequired,
    onComplete: PropTypes.func.isRequired,
    redirectUrl: PropTypes.string.isRequired,
    rootUrl: PropTypes.string.isRequired,
    universitySlug: PropTypes.string,
    currentLocale: PropTypes.string,
};

RequestPasswordReset.defaultProps = {
    redirectUrl: "",
    currentLocale: undefined,
};

const requestPasswordResetOptions = {
    name: "requestPasswordReset",
};

const mapStateToProps = () => ({});

export default compose(
    connect(mapStateToProps),
    graphql(requestPasswordResetMutation, requestPasswordResetOptions),
)(RequestPasswordReset);
