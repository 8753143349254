import gql from "graphql-tag";
import universityFragment from "../fragments/universityFragment";

const GetUniversityQuery = gql`
    query GetUniversityQuery($slug: String) {
        university(slug: $slug) {
            ...UniversityFull
        }
    }
    ${universityFragment}
`;

export const mockGetUniversityQuery = (props) => {
    return {
        request: {
            query: GetUniversityQuery,
            variables: {
                slug: "unibuddy",
            },
        },
        result: {
            data: {
                university: {
                    id: "1",
                    name: "Unibuddy",
                    nickname: null,
                    slug: "unibuddy",
                    country: {
                        id: "1",
                        name: "UK",
                        __typename: "CountryField",
                    },
                    logo: "https://s3-eu-west-1.amazonaws.com/unibudy-dev/5cee7600eb980c002041e00a.png",
                    squareLogo:
                        "https://s3-eu-west-1.amazonaws.com/unibudy-dev/UniversitySquareLogos/unibuddy_square_logo_blue_bg.png",
                    whiteLogo:
                        "https://s3-eu-west-1.amazonaws.com/unibudy-dev/5a82cf6d0b2da20014c8b9a7.svg",
                    widgetUrl: "https://demo.unibuddy.co/QueenMary/queen-mary.html",
                    widgetUrls: [
                        {
                            name: "German",
                            locale: "de-DE",
                            url: "www.example-german.com",
                            __typename: "WidgetUrlsField",
                        },
                        {
                            name: "Icelandic",
                            locale: "is-IS",
                            url: "www.example-icelandic.com",
                            __typename: "WidgetUrlsField",
                        },
                    ],
                    colour: "1a428a",
                    mentorMessagesCount: 715,
                    launchDate: "2017-02-01 00:00:00",
                    privacyPolicyUrl: "https://www.qmul.ac.uk/site/privacy",
                    customMarketingConsentText: "I agree to everything",
                    customPreSignupText: "I agree to everything again",
                    customMarketingConsentTextInLocales: [
                        {
                            locale: "en-GB",
                            name: "English, United Kingdom",
                            text: "I agree to everything",
                            __typename: "TextInLocale",
                        },
                    ],
                    customChatBubbleTextInLocales: [
                        {
                            locale: "en-GB",
                            name: "English, United Kingdom",
                            text: "I agree to everything",
                            __typename: "TextInLocale",
                        },
                    ],
                    customPreSignupTextInLocales: [
                        {
                            locale: "en-GB",
                            name: "English, United Kingdom",
                            text: "I agree to everything again",
                            __typename: "TextInLocale",
                        },
                    ],
                    degreeTypeOrder: [],
                    entries: {active: true, entryMonths: [9], __typename: "EntriesField"},
                    config: {
                        features: [],
                        __typename: "UniversityConfigField",
                    },
                    __typename: "UniversityField",
                    ...props,
                },
            },
        },
    };
};

export default GetUniversityQuery;
