export enum AnalyticsDashboardType {
  Engagement = "engagementanalytics",
  Prospects = "prospectanalytics",
  AmboStudent = "studentamboanalytics",
  AmboStaff = "staffamboanalytics",
  ProspectInsights = "prospectinsightsanalytics",
  EnterpriseDashboard = "enterprise"
}

export enum CustomerTier {
  PremiumPlusCustomer = "premiumplus",
  LiteAndProCustomer = "liteandpro",
}

export const LOGO_SERVICE_TRACKING = "logotracking";
export const LOGO_SERVICE_TRACKING_ENABLED_DASHBOARDS = new Set([
  AnalyticsDashboardType.Engagement,
]);
