import ToastProvider from "shared/Experimental/Overlays/components/ToastProvider/ToastProvider";
import React from "react";
import FocusVisibleManager from "../FocusVisibleManager/FocusVisibleManager";

/**
 * Kitchen sink of design system required providers.
 *
 * @export
 * @param {*} {children}
 * @returns
 */
export default function DesignSystemProvider({children}) {
    return (
        <ToastProvider>
            <FocusVisibleManager>{children}</FocusVisibleManager>
        </ToastProvider>
    );
}
