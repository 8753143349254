import React, {useState, createContext} from "react";

export const EnterpriseErrorContext = createContext();

export const PROSPECT_CONVERSION = "prospectConversion";
export const SIGNUP_DISTRIBUTION = "signUpDistribution";
export const TRENDING_INSIGHTS = "trendingInsights";
export const EVENTS_OVERVIEW = "eventsOverview";
export const HEADER_DEPARTMENT_LIST = "headerDepartmentList";
export const NAVIGATION_DEPARTMENT_LIST = "navigationDepartmentList";
export const LOGO = "logo";

export const DEFAULT_ERROR =
    "We are unable to display some of the data required for this dashboard. Please try again later. If the problem persists,";
export const DEFAULT_MISC = "reach out to our support team ";
export const DEFAULT_HIGHLIGHT = "support@unibuddy.com";

const ERROR_MESSAGE =
    "It looks like we are currently unable to display this information. Please try again later and contact our support team ";
const HIGHLIGHT_TEXT = "support@unibuddy.com";
const MISC_TEXT = "if this problem continues.";

export const EnterpriseErrorContextProvider = props => {
    const initialErrorContext = {
        [PROSPECT_CONVERSION]: {
            hasError: false,
            errorMessage: ERROR_MESSAGE,
            bold: HIGHLIGHT_TEXT,
            misc: MISC_TEXT,
        },
        [SIGNUP_DISTRIBUTION]: {
            hasError: false,
            errorMessage: ERROR_MESSAGE,
            bold: HIGHLIGHT_TEXT,
            misc: MISC_TEXT,
        },
        [TRENDING_INSIGHTS]: {
            hasError: false,
            errorMessage: ERROR_MESSAGE,
            bold: HIGHLIGHT_TEXT,
            misc: MISC_TEXT,
        },
        [EVENTS_OVERVIEW]: {
            hasError: false,
            errorMessage: ERROR_MESSAGE,
            bold: HIGHLIGHT_TEXT,
            misc: MISC_TEXT,
        },
        [HEADER_DEPARTMENT_LIST]: {
            hasError: false,
            errorMessage: ERROR_MESSAGE,
            bold: HIGHLIGHT_TEXT,
            misc: MISC_TEXT,
        },
        [NAVIGATION_DEPARTMENT_LIST]: {
            hasError: false,
            errorMessage: ERROR_MESSAGE,
            bold: HIGHLIGHT_TEXT,
            misc: MISC_TEXT,
        },
        [LOGO]: {hasError: false, errorMessage: ""},
    };
    const [errorContext, setErrorContext] = useState(initialErrorContext);

    const setError = (
        key,
        hasError,
        errorMessage = ERROR_MESSAGE,
        bold = HIGHLIGHT_TEXT,
        misc = MISC_TEXT,
    ) => {
        const modifiedErrorContext = Object.assign({}, errorContext, {
            [key]: {hasError, errorMessage, bold, misc},
        });
        setErrorContext(modifiedErrorContext);
    };

    return (
        <EnterpriseErrorContext.Provider value={{errorContext, setError}}>
            {props.children}
        </EnterpriseErrorContext.Provider>
    );
};
