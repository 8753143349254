import React, {useState, useContext, useEffect} from "react";
import {get} from "lodash";
import {useLocation, Redirect} from "react-router-dom";
import styled from "styled-components";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import GoAlert from "react-icons/lib/go/alert";
import IoLogOut from "react-icons/lib/io/log-out";
import {Box, Stack, Inline, TextBlock, ButtonLink} from "@unibuddy/patron";
import {EnterpriseErrorContext, NAVIGATION_DEPARTMENT_LIST, LOGO} from "./EnterpriseErrorContext";
import {
    Analytics,
    Buddies,
    Departments,
    Unibuddy,
    UnibuddyLogoOnly,
    Chevron,
} from "../../icons/InstitutionIcons";
import DotsLoader from "../../../InstitutionAnalytics/general/components/DotsLoader/DotsLoader";
import Alert from "../../../InstitutionAnalytics/general/components/Alert/Alert";
import s from "./InstitutionNavContent.pcss";

const CollapsedUniversityLogo = styled(Box)`
    padding: 40px 0 0 0;
    margin-left: 4px;
    margin-top: 32px;
`;
const UniversityLogo = styled(Box)`
    padding-top: 64px;
`;
const UniversityHeader = styled(Box)`
    text-align: center;
    ${({isUniversityView}) =>
        isUniversityView &&
        `
        display: none;
    `}
`;
const NavLink = styled(ButtonLink)`
    padding-top: 7px;
    box-sizing: border-box;
    width: ${({isUniversityView}) => (isUniversityView ? "59px" : "315px")};
    justify-content: left;
    border-radius: 0px;
    text-decoration: none !important;
    position: relative;
    margin-bottom: 40px;
    display: block;
    border-left: 5px solid transparent;
    &:hover {
        color: white !important;
        background: ${({isSelected, isUniversityView}) =>
            isSelected || isUniversityView ? "#2f2f36 !important" : "none !important"};
    }
    ${({isUniversityView}) =>
        isUniversityView &&
        `
        // with the help of padding left we are trying to hide labels of Navlink.
        // padding-left: 22px;
        &:hover {
            width: 250px;
        }
    `}
`;
const DropDownButton = styled(Box)`
    cursor: pointer;
    padding-bottom: 6px;
    padding-left: 17px;
    padding-top: 8px;
    box-sizing: border-box;
    /* width: ${({isUniversityView}) => (isUniversityView ? "59px" : "310px")}; */
    text-decoration: none !important;
    position: relative;
    display: block;
    border-radius: 0px;
    border-left: 5px solid transparent;
    /* &:hover {
        color: white !important;
        background: ${({isSelected, isUniversityView}) =>
        isSelected || isUniversityView ? "#2f2f36 !important" : "none !important"};
    } */
`;

const DepartmentBox = styled(Box)`
    margin-bottom: 40px;
`;

const DepartmentNavLink = styled(ButtonLink)`
    padding-left: 20px !important;
    color: white !important;
    font-weight: normal;
    font-family: Nunito;
    font-size: 16px;
    position: relative;
    width: ${({isUniversityView}) => (isUniversityView ? "250px" : "315px")};
    &:hover {
        color: white !important;
        border: none !important;
        background: transparent !important;
        text-decoration: none !important;
    }
    &:active {
        text-decoration: none !important;
    }
    text-decoration: none !important;
    display: flex;
    justify-content: left;
    text-align: left;
    white-space: normal;
    height: auto;
    min-height: 38px;
    padding: 5px 20px 5px 20px;
`;
const Footer = styled(Box)`
    position: fixed;
    bottom: 20px;
    left: 63px;
    width: 315px;
`;
const StyledFooter = styled(Box)`
    position: fixed;
    left: 2px;
    bottom: 20px;
    width: 59px;
`;

function UniversityLogoComponent({logo, isUniversityView}) {
    const {errorContext, setError} = useContext(EnterpriseErrorContext);
    const {loading, error, data} = logo;
    if (loading) {
        if (isUniversityView) {
            return (
                <CollapsedUniversityLogo>
                    <DotsLoader color="white" />
                </CollapsedUniversityLogo>
            );
        }
        return (
            <UniversityLogo>
                <DotsLoader color="white" />
            </UniversityLogo>
        );
    }
    if (error) {
        if (isUniversityView) {
            return (
                <CollapsedUniversityLogo>
                    <Box paddingX="small">
                        <GoAlert size={18} data-test-id="go-alert-icon" />
                    </Box>
                </CollapsedUniversityLogo>
            );
        }
        if (!errorContext[LOGO].hasError) {
            setError(LOGO, true, "error while fetching logo");
        }
        return (
            <UniversityLogo>
                <Box backgroundColor="white" borderRadius="small" padding="medium">
                    <GoAlert size={25} color="black" data-test-id="go-alert-icon" />
                </Box>
            </UniversityLogo>
        );
    }
    if (!error) {
        if (errorContext[LOGO].hasError) {
            setError(LOGO, false, "");
        }
    }
    if (!loading) {
        let uniLogo = "";
        const uniSquareLogo = get(data, "university.squareLogo", "");
        if (!uniSquareLogo) {
            uniLogo = get(data, "university.logo", "");
        } else {
            uniLogo = uniSquareLogo;
        }
        const imageText = get(data, "university.name", "University Name");
        if (isUniversityView) {
            return (
                <CollapsedUniversityLogo>
                    <img src={uniLogo} alt={imageText} width="48" height="48" />
                </CollapsedUniversityLogo>
            );
        }
        return (
            <UniversityLogo>
                <img src={uniLogo} alt={imageText} width="80" height="80" />
            </UniversityLogo>
        );
    }
}

/*InstituteNavbarButtonLinkDropDown is a div, which is inheritly non-tabable and not interactive when pressed enter on focus. This function captures the enter key press and runs the callback function being passed to the div nav link.*/

function accessibleDivClickHandler(key, callback) {
    if (key === "Enter") {
        callback();
    }
}

const InstituteNavbarButtonLink = (props) => {
    return (
        <NavLink
            to={props.to}
            clear
            onClick={props.onClick}
            className={classNames({
                [s.isSelected]: props.isSelected,
                [s.navbarLinkHover]: props.isUniversityView,
            })}
            isSelected={props.isSelected}
            isUniversityView={props.isUniversityView}
        >
            <Inline space="small" wrap="nowrap" verticalAlign="center" align="left">
                <Box className={s.icon}>{props.icon}</Box>
                <Box className={classNames({[s.disappear]: props.isUniversityView})}>
                    <TextBlock color="white" fontFamily="Nunito" weight="600" size="standard">
                        {props.label}
                    </TextBlock>
                </Box>
            </Inline>
        </NavLink>
    );
};

const InstituteNavbarButtonLinkDropDown = (props) => {
    if (props.isUniversityView) {
        return (
            <DepartmentBox
                tabIndex={0}
                onKeyUp={({key}) => accessibleDivClickHandler(key, props.onClick)}
                data-test-id="navbar-departments-dropdown"
                onTouchStart={!props.isSelected ? props.onClick : undefined}
                onMouseEnter={!props.isSelected ? props.onClick : undefined}
                className={classNames(
                    s.dropdownButtonAndContainer,
                    s.dropdownButtonAndContainerCompressed,
                    {
                        [s.isSelected]:
                            (props.state &&
                                props.state.selectedOrganisation &&
                                !isEmpty(props.state.selectedOrganisation)) ||
                            props.isSelected,
                    },
                    s.navbarLinkHover,
                )}
            >
                <DropDownButton
                    isUniversityView
                    onTouchStart={!props.isSelected ? props.onClick : undefined}
                    onMouseEnter={!props.isSelected ? props.onClick : undefined}
                >
                    <Inline space="medium" wrap="nowrap" verticalAlign="center" align="left">
                        {props.icon}
                        <Box className={classNames(s.disappear)}>
                            <TextBlock
                                color="white"
                                fontFamily="Nunito"
                                weight="600"
                                size="standard"
                            >
                                {props.label}
                            </TextBlock>
                        </Box>
                        <Chevron className={classNames(s.caret, s.disappear)} align="right" title={"Close Department dropdown"}/>
                    </Inline>
                </DropDownButton>
                <Box
                    className={classNames(s.dropdownContainer, {
                        [s.activeDropDownContainer]: props.isSelected,
                    })}
                >
                    {props.children}
                </Box>
            </DepartmentBox>
        );
    }
    return (
        <DepartmentBox
            tabIndex={0}
            onKeyUp={({key}) => accessibleDivClickHandler(key, props.onClick)}
            onClick={props.onClick}
            data-test-id="navbar-departments-dropdown"
            className={classNames(s.dropdownButtonAndContainer, {
                [s.isSelected]:
                    (props.state &&
                        props.state.selectedOrganisation &&
                        !isEmpty(props.state.selectedOrganisation)) ||
                    props.isSelected,
            })}
        >
            <DropDownButton isSelected={props.isSelected}>
                <Inline space="medium" wrap="nowrap" verticalAlign="center" align="left">
                    {props.icon}
                    <Box>
                        <TextBlock color="white" fontFamily="Nunito" weight="600" size="standard">
                            {props.label}
                        </TextBlock>
                    </Box>
                    <Chevron className={classNames(s.caret)} align="right" title={"Open Department dropdown"}/>
                </Inline>
            </DropDownButton>
            <Box
                className={classNames(s.dropdownContainer, {
                    [s.activeDropDownContainer]: props.isSelected,
                })}
            >
                {props.children}
            </Box>
        </DepartmentBox>
    );
};

function InstitutionNavContent({university, logo, organisations}) {
    const {errorContext, setError} = useContext(EnterpriseErrorContext);
    const {errorMessage, bold, misc} = errorContext[NAVIGATION_DEPARTMENT_LIST];
    const {pathname: currentPath, state, search} = useLocation();
    const queryParams = new URLSearchParams(search);
    const uniId = queryParams.get("id");
    let redirect = false;
    let universityIdNotFound = false;
    const universityIdNotFoundPath = "/404";
    let selectedUniversity = {};
    /*
        This is to redirect the user to respective university when he access the buddy export link
        If the current university and user requested university are same don't redirect
        If the user requested university is not part of enterprise route it to
    */
    if (uniId && university.id !== uniId && organisations && organisations.length > 0) {
        redirect = true;
        selectedUniversity = organisations.find(
            (organisation) => organisation.linkedInstance === uniId,
        );
        if (!selectedUniversity) {
            universityIdNotFound = true;
        }
    }
    const isUniversityView = currentPath.indexOf("/enterprise-management-tools") === -1;
    const [isUniversityAnalyticsButtonSelected, setUniversityAnalyticsButtonAsSelected] = useState(
        currentPath.indexOf("/enterprise-management-tools/analytics") === 0,
    );
    const [isBuddiesButtonSelected, setBuddiesButtonAsSelected] = useState(
        currentPath.indexOf("/enterprise-management-tools/buddies") === 0,
    );
    const [isDepartmentsButtonSelected, setDepartmentsButtonAsSelected] = useState(false);

    const highlightUniversityAnalyticsButton = () => {
        setUniversityAnalyticsButtonAsSelected(true);
        setBuddiesButtonAsSelected(false);
        setDepartmentsButtonAsSelected(false);
    };

    const highlightBuddiesButton = () => {
        setUniversityAnalyticsButtonAsSelected(false);
        setBuddiesButtonAsSelected(true);
        setDepartmentsButtonAsSelected(false);
    };

    const highlightDepartmentsButton = () => {
        if (isDepartmentsButtonSelected) {
            setDepartmentsButtonAsSelected(false);
            if (currentPath.indexOf("/enterprise-management-tools/analytics") === 0) {
                setUniversityAnalyticsButtonAsSelected(true);
            } else if (currentPath.indexOf("/enterprise-management-tools/buddies") === 0) {
                setBuddiesButtonAsSelected(true);
            }
        } else {
            setUniversityAnalyticsButtonAsSelected(false);
            setBuddiesButtonAsSelected(false);
            setDepartmentsButtonAsSelected(true);
        }
    };

    const renderRedirectRoute = () => {
        if (universityIdNotFound) {
            return <Redirect to={universityIdNotFoundPath} />;
        }
        return (
            <Redirect
                to={{
                    pathname: "/analytics",
                    state: {
                        selectedOrganisation: selectedUniversity,
                        nextPath: currentPath,
                    },
                }}
            />
        );
    };

    useEffect(
        () => {
            if (!organisations || !organisations.length) {
                if (!errorContext[NAVIGATION_DEPARTMENT_LIST].hasError) {
                    setError(NAVIGATION_DEPARTMENT_LIST, true);
                }
            }

            if (organisations && organisations.length) {
                if (errorContext[NAVIGATION_DEPARTMENT_LIST].hasError) {
                    setError(NAVIGATION_DEPARTMENT_LIST, false);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [organisations],
    );

    return redirect ? (
        renderRedirectRoute()
    ) : (
        <Stack space="large">
            <Stack space="xlarge">
                <Stack space="medium" align={isUniversityView ? "left" : "center"}>
                    <UniversityLogoComponent logo={logo} isUniversityView={isUniversityView} />
                    <UniversityHeader isUniversityView={isUniversityView}>
                        <TextBlock color="white" fontFamily="Nunito" weight="600" size="large">
                            {university.name}
                        </TextBlock>
                    </UniversityHeader>
                </Stack>
                <Box style={{position: "fixed"}}>
                    <InstituteNavbarButtonLink
                        to="/enterprise-management-tools/analytics"
                        icon={<Analytics />}
                        label="University Analytics"
                        isSelected={isUniversityAnalyticsButtonSelected}
                        isUniversityView={isUniversityView}
                        onClick={highlightUniversityAnalyticsButton}
                    />
                    <InstituteNavbarButtonLink
                        to="/enterprise-management-tools/buddies"
                        icon={<Buddies />}
                        label="Buddies"
                        isSelected={isBuddiesButtonSelected}
                        isUniversityView={isUniversityView}
                        onClick={highlightBuddiesButton}
                    />
                    <InstituteNavbarButtonLinkDropDown
                        icon={<Departments />}
                        label="Departments"
                        isSelected={isDepartmentsButtonSelected}
                        isUniversityView={isUniversityView}
                        onClick={highlightDepartmentsButton}
                        state={state}
                    >
                        {(!organisations || !organisations.length) && (
                            <Box>
                                {" "}
                                <Alert
                                    isDepartment
                                    bgColor="transparent"
                                    width="3/4"
                                    color="white"
                                    text="custom"
                                    errorMessage={errorMessage}
                                    bold={bold}
                                    misc={misc}
                                />
                            </Box>
                        )}
                        {organisations && organisations.length
                            ? organisations.map((organisation) => (
                                  <DepartmentNavLink
                                      to={{
                                          pathname: "/analytics",
                                          state: {
                                              selectedOrganisation: organisation,
                                          },
                                      }}
                                      key={organisation.id}
                                      clear
                                  >
                                      {organisation.name}
                                  </DepartmentNavLink>
                              ))
                            : null}
                    </InstituteNavbarButtonLinkDropDown>
                    <InstituteNavbarButtonLink
                        to="/auth/logout"
                        icon={<IoLogOut style={{color: "white"}} role={"img"} aria-label={"Logout"}/>}
                        label="Logout"
                        isUniversityView={isUniversityView}
                    />
                </Box>
            </Stack>
            {isUniversityView ? (
                <StyledFooter>
                    <UnibuddyLogoOnly />
                </StyledFooter>
            ) : (
                <Footer>
                    <Unibuddy />
                </Footer>
            )}
        </Stack>
    );
}

export default InstitutionNavContent;
