import React, {memo} from "react";
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";

const PageTitle = memo(({title}) => (
    <Helmet>
        <title>{`Unibuddy - ${title}`}</title>
    </Helmet>
));

PageTitle.propTypes = {
    title: PropTypes.string.isRequired,
};

export default PageTitle;
