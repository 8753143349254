import gql from "graphql-tag";
import anyUserFragment from "../fragments/anyUserFragment";
import universityUserFragment from "../fragments/universityUserFragment";
import applicantFragment from "../fragments/applicantFragment";

const getTokenQuery = gql`
    query GetTokenQuery($email: String, $password: String, $redirectUrl: String, $rootUrl: String) {
        getToken(email: $email, password: $password, redirectUrl: $redirectUrl, rootUrl: $rootUrl) {
            accessToken
            me {
                anyUser {
                    ...AnyUserFull
                }
                universityUser {
                    ...UniversityUserFull
                }
                applicant {
                    ...ApplicantFull
                }
            }
        }
    }
    ${anyUserFragment}
    ${universityUserFragment}
    ${applicantFragment}
`;

export default getTokenQuery;
