import React, {Fragment, Suspense} from "react";
import get from "lodash/get";
import compose from "lodash/flowRight";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Redirect, Route, Switch} from "react-router-dom";
import {store} from "../../main";
import BuddyPage from "./components/Buddies/BuddyPageRoutes";

const InstitutionAnalytics = React.lazy(() =>
    import(/* webpackChunkName: "institution-analytics-page" */ "../InstitutionAnalytics/Institution/Analytics/InstitutionAnalytics"),
);

export const AuthenticatedRoutes = compose(
    withRouter,
    connect(({authState}) => ({authState})),
)(({authState, enterpriseNavOpen, toggle}) => {
    const uniUserGroups = get(authState, "me.universityUser.groups", []);
    const organisationUserGroup = uniUserGroups.filter(group => group.type === "organisation_user");
    if (!organisationUserGroup.length) {
        return <Redirect to={"/analytics"} />;
    }

    const enterpriseToolsPath = "/enterprise-management-tools";
    return (
        <Fragment>
            <Suspense fallback={<div />}>
                <Switch>
                    <Route
                        path={enterpriseToolsPath}
                        exact
                        render={() => <Redirect to={`${enterpriseToolsPath}/buddies`} />}
                    />
                    <Route
                        path={`${enterpriseToolsPath}/buddies`}
                        render={props => (
                            <BuddyPage
                                enterpriseNavOpen={enterpriseNavOpen}
                                toggle={toggle}
                                {...props}
                                mainRoute={enterpriseToolsPath}
                            />
                        )}
                    />
                    <Route
                        path={`${enterpriseToolsPath}/analytics`}
                        exact
                        render={props => (
                            <InstitutionAnalytics
                                enterpriseNavOpen={enterpriseNavOpen}
                                toggle={toggle}
                                {...props}
                                store={store}
                            />
                        )}
                    />
                </Switch>
            </Suspense>
        </Fragment>
    );
});

const EnterpriseAppRoutes = ({enterpriseNavOpen, toggle}) => {
    return (
        <Switch>
            <Route
                render={props => (
                    <AuthenticatedRoutes
                        enterpriseNavOpen={enterpriseNavOpen}
                        toggle={toggle}
                        {...props}
                    />
                )}
            />
        </Switch>
    );
};

export default connect(({authState}) => ({authState}))(EnterpriseAppRoutes);
