// action types

export const SELECT_MENTOR = "SELECT_MENTOR";
export const SELECT_MULTIPLE_MENTORS = "SELECT_MULTIPLE_MENTORS";
export const DESELECT_MENTOR = "DESELECT_MENTOR";
export const CLEAR_MENTOR_SELECTION = "CLEAR_MENTOR_SELECTION";
export const SET_SORT_FIELD = "SET_SORT_FIELD";
export const SET_MENTOR_FILTER = "SET_MENTOR_FILTER";
export const RESET_MENTOR_TABLE = "RESET_MENTOR_TABLE";

// action creators

export function selectMentor(mentorId) {
    return {
        type: SELECT_MENTOR,
        mentorId,
    };
}

export function selectMultipleMentors(mentorIds) {
    return {
        type: SELECT_MULTIPLE_MENTORS,
        mentorIds,
    };
}

export function deselectMentor(mentorId) {
    return {
        type: DESELECT_MENTOR,
        mentorId,
    };
}

export function resetMentorTable() {
    return {
        type: RESET_MENTOR_TABLE,
    };
}

export function clearMentorSelection() {
    return {
        type: CLEAR_MENTOR_SELECTION,
    };
}

export function setSortField(text) {
    return {
        type: SET_SORT_FIELD,
        sortField: text,
    };
}

export function setFilterField(filterKey, filterText) {
    return {
        type: SET_MENTOR_FILTER,
        filterText,
        filterKey,
    };
}
