import React from "react";
import {useLocation} from "react-router-dom";
import classNames from "classnames";
import Button from "shared/Experimental/General/components/Button/Button";
import IoIosArrowBack from "react-icons/lib/io/ios-arrow-back";
import IoIosArrowForward from "react-icons/lib/io/ios-arrow-forward";
import {Consumer} from "./AppContainer";
import s from "./App.pcss";

function AppBody(props) {
    const {isInstitution} = props;
    const currentPath = useLocation().pathname;
    const isUniversityView = currentPath.indexOf("/enterprise-management-tools") === -1;

    return (
        <Consumer>
            {({collapsed, toggle}) => (
                <div
                    className={classNames(
                        s.appBody,
                        {[s.open]: !collapsed},
                        {[s.collapsedInstitution]: isInstitution && isUniversityView},
                        {[s.collapsedSidebar]: collapsed},
                        {[s.institution]: isInstitution && !isUniversityView},
                    )}
                >
                    {isUniversityView && (
                        <Button
                            aria-label={collapsed ? "Expand sidebar" : "Collapse sidebar"}
                            className={s.collapseButton}
                            iconOnly
                            round
                            color="light"
                            onClick={toggle}
                        >
                            {collapsed ? (
                                <IoIosArrowForward size={20} role={"presentation"}/>
                            ) : (
                                <IoIosArrowBack size={20} role={"presentation"}/>
                            )}
                        </Button>
                    )}
                    {props.children}
                </div>
            )}
        </Consumer>
    );
}

export default AppBody;
