import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "@unibuddy/intl";
import s from "./AccountLocked.pcss";
import PageHeader from "../../../Shared/components/PageHeader/PageHeader";

const AccountLocked = props => {
    const {t} = useTranslation("accountLocked");

    return (
        <div className={s.container} id="accountLocked">
            <div className={s.header}>
                <p className={s.icons}>
                    <i className="fa fa-lock" aria-hidden="true" />
                </p>
                <PageHeader className={s.heading} title={t("accountLocked", "Account Locked")} />
                <p>
                    {t(
                        "accountLockedMessage",
                        "For your security, your account has been locked after too many unsuccessful login attempts.",
                    )}
                </p>
                <p>
                    {t(
                        "unlockInstructions",
                        "We have sent you an email to reset your password. After resetting your password, " +
                            "your account will immediately unlock.",
                    )}
                </p>
                <p className={s.smalltext}>
                    {t(
                        "requestNewEmail",
                        "Didn't received a password reset email? Request a new one ",
                    )}
                    {props.requestResetPasswordLink}
                </p>
            </div>
        </div>
    );
};

AccountLocked.propTypes = {
    requestResetPasswordLink: PropTypes.element.isRequired,
};

export default AccountLocked;
