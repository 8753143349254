import Pusher from "pusher-js";
import get from "lodash/get";
import {config} from "shared/ConfigManager/ConfigManager";
import {getAuthorizationHeaderFor} from 'shared/Helpers/AuthorizationHelper';
import {setConnectionState, setPusherObject} from "./actions/messengerActions";

class Messenger {
    static async connect(token, messengerState, dispatch) {
        return new Promise(resolve => {
            if (token && get(messengerState, "connectionState") !== "connected") {
                // connect to pusher
                const pusher = new Pusher(config.PUSHER_APP_KEY, {
                    cluster: config.PUSHER_APP_CLUSTER,
                    auth: {headers: {Authorization: getAuthorizationHeaderFor(token)}},
                    authEndpoint: `${config.GATEWAY_URL}/pusher/auth`,
                    encrypted: true,
                });
                // Use for debugging: Pusher.logToConsole = true;
                if (pusher && pusher.connection) {
                    // check for connection limit error
                    pusher.connection.bind("error", err => {
                        if (get(err, "error.data.code") === 4004) {
                            // TODO: report error
                            console.log(">>> detected limit error");
                        }
                        console.log("error from pusher connection", err);
                    });
                    // fires on every connection state change
                    pusher.connection.bind("state_change", states => {
                        // Expected shape of states: {previous: 'oldState', current: 'newState'}
                        dispatch(setConnectionState(states.current));
                    });
                }
                dispatch(setPusherObject(pusher));
                resolve(pusher);
            }
            resolve(null);
        });
    }

    // Subscribe to a channel with the chatGroupId as its name
    static subscribe(chatGroupId, connectionState, pusher) {
        return new Promise((resolve, reject) => {
            if (typeof TESTING !== "undefined" && TESTING === "true") resolve(null);
            // check if we're connected to Pusher Chat, otherwise connect
            if (connectionState === "connected") {
                // check if we have a subscription to this chat group
                const channels = get(pusher, "channels.channels");
                const subscribed = get(channels, `private-${chatGroupId}.subscribed`, false);
                if (!subscribed) {
                    const channel = pusher.subscribe(`private-${chatGroupId}`);
                    if (channel) {
                        channel.bind("pusher:subscription_error", status => {
                            if (status === 408 || status === 503) {
                                const channelRetry = pusher.subscribe(`private-${chatGroupId}`);
                                resolve(channelRetry);
                            } else reject(channel);
                        });
                        channel.bind("pusher:subscription_succeeded", () => {
                            resolve(channel);
                        });
                    } else {
                        const error = {
                            subscribed: false,
                            message: "Problem calling subscribe on channel",
                        };
                        resolve(error);
                    }
                } else {
                    const error = {
                        subscribed: false,
                        message: "Already subscribed",
                    };
                    resolve(error);
                }
            } else {
                const error = {
                    subscribed: false,
                    message: "Connection to Pusher has to be established first!",
                };
                resolve(error);
            }
        });
    }

    // Listen to messages from a channel
    static listenMessage(channel, callback) {
        try {
            channel.bind("ChatMessage", data => {
                callback(data);
            });
        } catch (err) {
            // TODO: report error
            console.log("caught error binding to channel", err);
        }
    }
}

export default Messenger;
