import React, {Component, Dispatch} from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router";
import {withApollo} from "react-apollo";
import {ApolloClient} from "apollo-client";
import {MemoryHistory, Location} from 'history';
import compose from "lodash/flowRight";
import {setMe, setToken, clearAuth} from "shared/Auth/actions/authActions";
import Auth from "shared/Auth/auth";
import Query from 'shared/Auth/queries/meQuery';
import withSSOAuth from "shared/Auth/withSSOAuth/withSSOAuth";
import PageTitle from "shared/Shared/components/PageTitle/PageTitle";
import ProgressBar from "shared/General/components/ProgressBar/ProgressBar";
import {config} from "shared/ConfigManager/ConfigManager";
import {ACVL_APPLICATION_ID} from "shared/Utils/Constants";
import removeTrailingSlash from "shared/Utils/removeTrailingSlash";
// @ts-ignore A question for Aakriti
import loginStyles from "./Login.pcss";

type SSOLoginProps = {
    authState: Record<string, any>;
    client: ApolloClient<any>;
    dispatch: Dispatch<any>;
    history: MemoryHistory;
    location: Location
}

const {
    GATEWAY_URL = '',
} = config;

class SSOLoginComponent extends Component<SSOLoginProps> {
    state = {errorMessage: null};

    componentDidMount() {
        const {location, dispatch, client} = this.props;
        const params = new URLSearchParams(location.search);
        const code = params.get('code');

        if (code) {
            // Each code is single-use so if we try to re-use one then the transaction will fail
            this.handleAuthCode(code)
                .then((token) => {
                    dispatch(setToken(token));
                })
                .then(() => client.query({query: Query}))
                .then(({data: {me}}) => {
                    dispatch(setMe(me));
                })
                .then(() => this.handleLogin())
                .catch((error: Error | number) => this.handleError(error));
        }
    }

    async handleAuthCode(code: string) {
        const url = `${removeTrailingSlash(String(GATEWAY_URL ?? ''))}/acvl/auth-code`;
        const clientKey = `${ACVL_APPLICATION_ID}-`;
        const redirectUrl = `${window.location.origin}/auth/sso`;
        const urlSearchParams = new URLSearchParams({
            code,
            client_key: clientKey,
            redirect_url: redirectUrl, // `origin` is required (see the const declaration)
        });

        const response = await fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "content-type": "application/x-www-form-urlencoded",
            },
            body: urlSearchParams.toString(),
        });

        // 200 (The code is good, here is a token) or any other ok
        if (response.ok) {
            return response.json();
        }

        // 403 (The code is not good, no token for you) or any not-ok status
        throw response.status;
    }

    handleError (error: Error | number) {
        if (error instanceof Error) {
            this.setState({
                errorMessage:
                    "Login encountered an error. Please try again later.",
            });
        } else if (error >= 500 && error < 600) {
            this.setState({
                errorMessage:
                    "Login encountered an error. Please try again later.",
            });
        } else if (error >= 400 && error < 500) {
            this.setState({
                errorMessage:
                    "Only university users can login here.",
            });
        }
    }

    handleLogin () {
        const {authState} = this.props;
        const isUniversity = Auth.loggedIn(authState, "university");
        if (isUniversity) {
            this.props.history.replace("/");
        } else {
            this.setState({errorMessage: "Only university users can login here."}, () => {
                this.props.dispatch(clearAuth());
            });
        }
    }

    renderErrorMessage () {
        const {errorMessage} = this.state;
        if (errorMessage) {
            return (
                <p>{errorMessage}</p>
            );
        }
    }

    render() {
        const content = this.renderErrorMessage() ?? <ProgressBar start />;
        return (
            <div className={loginStyles.container}>
                <PageTitle // @ts-ignore Known issue in PageTitle
                    title="Login"
                />
                {content}
            </div>
        );
    }
}

const mapStateToProps = ({authState, me}) => ({
    authState,
    me,
});

export default compose(
    withRouter,
    connect(mapStateToProps),
    withSSOAuth,
    withApollo,
)(SSOLoginComponent);
