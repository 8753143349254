import {useQuery} from "react-apollo";
import get from "lodash/get";
import gql from "graphql-tag";

export const isFeatureEnabledQuery = gql`
    query hasUniversityFeatureQuery($universitySlug: String!, $feature: String!) {
        university(slug: $universitySlug) {
            id
            isFeatureEnabled(feature: $feature)
            isFeatureReadOnly(feature: $feature)
        }
    }
`;

export default function useUniversityFeature({universitySlug, feature}) {
    const {data, loading, error} = useQuery(isFeatureEnabledQuery, {
        variables: {
            universitySlug,
            feature,
        },
    });

    const university = get(data, "university", {isFeatureEnabled: false, isFeatureReadOnly: true});

    return {
        loading,
        error,
        isFeatureEnabled: university.isFeatureEnabled,
        isFeatureReadOnly: university.isFeatureReadOnly,
    };
}
