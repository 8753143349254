import {Component} from "react";
import PropTypes from "prop-types";
import {ErrorReportingContext} from "../ErrorReporting/ErrorReportingProvider";

/**
 * Renders nothing in case of an error, and reports it to sentry
 *
 * @class HiddenErrorBoundary
 * @extends {Component}
 */
class HiddenErrorBoundary extends Component {
    static propTypes = {
        boundaryName: PropTypes.string,
    };
    static contextType = ErrorReportingContext;

    componentDidCatch(err) {
        if (this.context && this.context.reportError) {
            this.context.reportError(err, {
                tags: {
                    framework: "react",
                    errorBoundaryName: this.props.boundaryName,
                },
                level: "fatal",
            });
        }
    }

    render() {
        return this.props.children === undefined ? null : this.props.children;
    }
}

export default HiddenErrorBoundary;
