import React, {useEffect, useState, useCallback} from "react";
import PropTypes from "prop-types";
import Pusher from "pusher-js";
import pullAt from "lodash/pullAt";

import useAuth from "shared/Auth/hooks/useAuth";
import {config} from "shared/ConfigManager/ConfigManager";
import {getAuthorizationHeaderFor} from 'shared/Helpers/AuthorizationHelper';

export const SocketContext = React.createContext({
    ready: false,
    socket: undefined,
});

export class Channel {
    constructor(name, options = {}) {
        this.name = name;
        this.private = options.private || false;
    }
    getName(channelId) {
        return `${this.private ? "private-" : ""}${this.name}-${channelId}`;
    }
}

export class Event {
    constructor(name, channel) {
        this.channel = channel;
        this.name = name;
    }
    getChannel(channelId) {
        return this.channel.getName(channelId);
    }
}

export function useSocketListener(event, channelId, callback) {
    const {socket, ready} = React.useContext(SocketContext);
    useEffect(
        () => {
            if (ready && channelId) {
                // we don't want to use the socket if we don't know the id yet
                socket.subscribe(event, channelId, callback);
                return () => {
                    socket.unsubscribe(event, channelId, callback);
                };
            }
        },
        [socket, ready, event, channelId, callback],
    );
}

export function useSocketData(event, channelId) {
    const [value, setValue] = useState();
    const callback = useCallback(socketData => setValue(socketData), [setValue]);
    useSocketListener(event, channelId, callback);
    return value;
}

export class Socket {
    constructor(token, PusherImplementation = Pusher) {
        this._pusher = new PusherImplementation(config.PUSHER_APP_KEY, {
            cluster: config.PUSHER_APP_CLUSTER,
            auth: {headers: {Authorization: getAuthorizationHeaderFor(token)}},
            authEndpoint: `${config.GATEWAY_URL}/pusher/auth`,
            encrypted: true,
        });
        this._pusher.connection.bind("error", function onError(err) {
            console.error(err);
        });
        this._channels = {};

        // we clear the channels when disconnected, because we are going to reconnect those
        this._pusher.connection.bind("unavailable", () => {
            this._channels = {};
        });
        this._pusher.connection.bind("disconnected", () => {
            this._channels = {};
        });
    }

    subscribe(event, channelId, callback) {
        if (this._pusher.connection.state !== "connected") {
            return; // can't subscribe, we are not connected
        }

        const channelName = event.getChannel(channelId);
        /** if we haven't already subscribed, we add it */
        if (!this._channels[channelName]) {
            this._channels[channelName] = {
                channel: this._pusher.subscribe(channelName),
                binded: [callback], // we keep track of the callback we bind
            };
        } else {
            this._channels[channelName].binded.push(callback); // we keep track of the callback we bind
        }
        this._channels[channelName].channel.bind(event.name, callback);

        return true;
    }

    unsubscribe(event, channelId, callback) {
        if (this._pusher.connection.state !== "connected") {
            return; // can't subscribe, we are not connected
        }
        const channelName = event.getChannel(channelId);
        if (!this._channels[channelName]) {
            /** nothing to unsubscribe from */
            return;
        }

        /** we unbind the callback */
        this._channels[channelName].channel.unbind(event.name, callback);

        /** we remove it from the list of callback that exist */
        pullAt(
            this._channels[channelName].binded,
            this._channels[channelName].binded.lastIndexOf(callback),
        );

        /** if we don't have any listener left, unsubscribe channel */
        if (this._channels[channelName].binded.length === 0) {
            this._channels[channelName].channel.unsubscribe();
            delete this._channels[channelName];
        }
    }

    disconnect() {
        this._pusher.disconnect();
    }

    onConnected(callback) {
        this._pusher.connection.bind("connected", callback);
    }

    onDisconnected(callback) {
        this._pusher.connection.bind("unavailable", callback);
        this._pusher.connection.bind("disconnected", callback);
    }
}

function SocketProvider({children, SocketImplementation = Socket}) {
    const {authState} = useAuth();
    const token = authState && authState.token;
    const [value, setValue] = React.useState({ready: false});
    React.useEffect(
        () => {
            if (token) {
                const socket = new SocketImplementation(token);
                socket.onConnected(() => {
                    setValue({
                        socket,
                        ready: true,
                    });
                });
                socket.onDisconnected(() => {
                    setValue({
                        socket,
                        ready: false,
                    });
                });
                return () => socket.disconnect();
            }
        },
        [token, SocketImplementation],
    );
    return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
}

SocketProvider.propTypes = {
    SocketImplementation: PropTypes.func,
};

export default SocketProvider;
