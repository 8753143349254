import React, {useCallback} from "react";
import {connect} from "react-redux";
import {Redirect, useLocation, Link} from "react-router-dom";
import compose from "lodash/flowRight";
import get from "lodash/get";
import useLogout from "shared/Auth/hooks/useLogout";
import {logoutIntercom} from "shared/Intercom/Intercom";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import {clearAuth} from "shared/Auth/actions/authActions";
import PageTitle from "../../shared/components/PageTitle/PageTitle";
import {Panel, PanelBody} from "../../shared/components/Panel";
import loginStyles from "./Login.pcss";

/*
 * This component clears the authentication and then redirects the
 * user to the landing page
 */
export const LogoutComponent = ({dispatch}) => {
    const clearLocalAuthFunction = useCallback(() => dispatch(clearAuth()), [dispatch]);
    const {error, isLoggedOut} = useLogout(clearLocalAuthFunction);
    const {state} = useLocation();
    const queryParams = new URLSearchParams(window.location.search);
    const nextPath = get(state, "nextPath", null);
    const hasNext = queryParams.get("next");
    if (isLoggedOut) {
        logoutIntercom();
        return hasNext && nextPath ? (
            <Redirect to={nextPath} />
        ) : (
            <div className="container">
                <Row>
                    <Col xs={12}>
                        <PageTitle title="Logout" className={loginStyles.title} />
                        <div className={loginStyles.container}>
                            <Panel>
                                <PanelBody>
                                    {error && <div>An error occured, please try again</div>}
                                    {isLoggedOut ? (
                                        <div>
                                            {" "}
                                            You have been logged out.{" "}
                                            <Link to="/auth/login">Login</Link>
                                        </div>
                                    ) : (
                                        <div>Logging out..</div>
                                    )}
                                </PanelBody>
                            </Panel>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
    return <div>Logging out...</div>;
};

export default compose(connect())(LogoutComponent);
