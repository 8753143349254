import React from "react";
import useSSOAuth from "../hooks/useSSOAuth";

/**
 * This HOC exposes a LaunchDarkly feature setting to components
 *
 * @export
 * @param {Component} WrappedComponent The component to wrap and enhance.
 * @return {Component} The wrapped component.
 */
const withSSOAuth = (WrappedComponent) => {
    return ({...props}) => {
        const {isSSOAuthEnabled} = useSSOAuth();
        return <WrappedComponent {...props} isSSOAuthEnabled={isSSOAuthEnabled} />;
    };
};

export default withSSOAuth;
