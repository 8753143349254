import * as React from "react";
import {SVGProps} from "react";

export const IconChatBot = (props: SVGProps<SVGSVGElement>) => {
    const {color = "currentColor", width = "1em", height = "1em", ...rest} = props;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            {...rest}
        >
            <g clipPath="url(#a)">
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.429}
                d="M3 16.502c0 1.245 1.037 2.329 2.5 2.51 1.04.13 2.094.228 3.158.294.324.02.62.164.799.39L12 22.912l2.543-3.214c.18-.227.475-.37.799-.39a52.53 52.53 0 0 0 3.159-.295c1.462-.18 2.499-1.265 2.499-2.51V11.82c0-1.246-1.037-2.33-2.5-2.51a52.873 52.873 0 0 0-6.5-.4c-2.208 0-4.379.136-6.5.4-1.463.18-2.5 1.264-2.5 2.51v4.68Z"
            />
            <circle
                cx={11.952}
                cy={4.864}
                r={1.667}
                fill="none"
                stroke={color}
                strokeWidth={1.429}
            />
            <circle cx={8.429} cy={14.34} r={1.429} fill={color} />
            <circle cx={15.429} cy={14.34} r={1.429} fill={color} />
            <path stroke={color} strokeWidth={1.429} d="M12 9.625V6.768" />
            </g>
            <defs>
            <clipPath id="a">
                <path fill="none" d="M0 .911h24v24H0z" />
            </clipPath>
            </defs>
        </svg>
    );
};
