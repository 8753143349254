import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import LogoWithTextSVG from "./LogoWithTextSVG/LogoWithTextSVG";
import LogoWithoutTextSVG from "./LogoWithoutTextSVG/LogoWithoutTextSVG";

const StyledLogo = styled.div`
    width: ${({width}) => width}px;
`;

function Logo({className, color, displayText, logoColor, width}) {
    return (
        <StyledLogo className={className} width={width} id="logo" data-test-id="logo">
            {displayText ? (
                <LogoWithTextSVG color={color} logoColor={logoColor} />
            ) : (
                <LogoWithoutTextSVG logoColor={logoColor} />
            )}
        </StyledLogo>
    );
}

Logo.propTypes = {
    color: PropTypes.string,
    displayText: PropTypes.bool,
    logoColor: PropTypes.string,
    width: PropTypes.number,
};

Logo.defaultProps = {
    color: "#3E3E48",
    displayText: true,
    logoColor: "#01D4CF",
    width: 100,
};

export default Logo;
