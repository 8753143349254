import mixpanelAdaptor from "@unibuddy/tracking/dist/adaptors/mixpanel";
import {COOKIE_CATEGORIES, MIXPANEL} from "shared/Utils/Constants";
import {config} from "shared/ConfigManager/ConfigManager";

/**
 * Because mixpanel is a singleton, its important that we only invoke it once
 * and this should be at the top of the app.
 */
mixpanelAdaptor.init(config.MIXPANEL_ID, {api_host: config.MIXPANEL_PROXY_HOST});
/**
 * https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelopt_in_tracking
 * We opt the user out of being tracked until they give
 * their express consent to opt in
 */
mixpanelAdaptor.optOut();

export const initMixpanel = preferences => {
    const adaptors = [];
    let mixpanelInit = false;
    preferences.forEach(preference => {
        if (preference) {
            const key = preference.trim().toLowerCase();
            if (
                !mixpanelInit &&
                COOKIE_CATEGORIES.has(key) &&
                COOKIE_CATEGORIES.get(key).includes(MIXPANEL)
            ) {
                /**
                 * https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelopt_in_tracking
                 * We use this method to confirm that a user is opting into being tracked.
                 */
                mixpanelAdaptor.optIn();
                adaptors.push(mixpanelAdaptor);
                mixpanelInit = true;
            }
        }
    });
    return adaptors;
};
