import * as React from "react";
import {SVGProps} from "react";

const IconSparkles = (props: SVGProps<SVGSVGElement>& {title?: string}) => {
    const {color = "#272B3D", title, ...rest} = props;
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        role="img"
        {...rest}
    >
        <title>{title ?? "Customisation"}</title>
        <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M9.813 16.815 9 19.66l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12.91l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 6.16l.813 2.846a4.5 4.5 0 0 0 3.09 3.09l2.847.814-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 9.626 18 10.66l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456l-1.036-.259 1.036-.259a3.375 3.375 0 0 0 2.455-2.455L18 3.16l.259 1.036a3.375 3.375 0 0 0 2.456 2.455l1.035.26-1.035.258a3.375 3.375 0 0 0-2.456 2.456ZM16.894 21.478l-.394 1.183-.394-1.183a2.25 2.25 0 0 0-1.423-1.423l-1.183-.394 1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
        />
    </svg>;
};
export default IconSparkles;
