import React from "react";
import {NavLink} from "react-router-dom/";

import focusHeader from "shared/Experimental/A11y/components/SkipNav/FocusHeader";

const NavLinkWithFocus = props => {
    return (
        <NavLink onClick={() => focusHeader("content")} {...props}>
            {props.children}
        </NavLink>
    );
};
export default NavLinkWithFocus;
