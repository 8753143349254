import * as React from "react";
import {SVGProps} from "react";

const IconInbox = (props: SVGProps<SVGSVGElement>& {title?: string}) => {
    const {color = "#131622", title, ...rest} = props;
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        role="img"
        {...rest}
    >
        <title>{title ?? "Conversations"}</title>
        <path
            fill={color}
            d="M10.026 2.49a7.535 7.535 0 1 1 0 15.068 7.453 7.453 0 0 1-3.345-.791 1.458 1.458 0 0 0-1.129-.076L3 17.113l.486-2.42a1.496 1.496 0 0 0-.112-1.133 7.529 7.529 0 0 1 5.37-10.968 7.96 7.96 0 0 1 1.28-.105l.003.003ZM10.024.99c-.51 0-1.02.042-1.523.125a9.025 9.025 0 0 0-6.447 13.147h-.006l-.656 3.26a.997.997 0 0 0 1.141 1.183l3.505-.578-.023-.023a8.983 8.983 0 0 0 5.77.778A9.031 9.031 0 0 0 10.026.994l-.001-.003Z"
        />
        <path
            fill={color}
            d="M13.459 9.259H6.575a.748.748 0 1 1 0-1.496h6.88a.748.748 0 0 1 0 1.496h.004ZM9.497 12.285H6.575a.748.748 0 1 1 0-1.496h2.922a.748.748 0 0 1 0 1.496Z"
        />
    </svg>;
};
export default IconInbox;
