import React from "react";
import styled from "styled-components";
import Button from "../../../General/components/Button/Button";
import CloseIcon from "../../../Icons/CloseIcon";
import TextBlock from "../../../Typography/components/TextBlock/TextBlock";
import Stack from "../../../Layout/components/Stack/Stack";
import BackgroundColorProvider from "../../../Utils/components/BackgroundColorProvider/BackgroundColorProvider";

const CloseButton = styled(Button)`
    position: absolute;
    top: 1px;
    right: 1px;
`;

const backgrounds = {
    success: "var(--fresh-leaves)",
    danger: "var(--red-cloud)",
    default: "var(--smart-grey)",
};

const Container = styled.div`
    position: relative;
    padding: 12px 16px;
    padding-right: 44px;
    background-color: ${({tone}) => backgrounds[tone]};
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(70, 70, 70, 0.2);
`;

Toast.defaultProps = {
    tone: "default",
};

export default function Toast({title, text, onDismiss, tone}) {
    return (
        <Container tone={tone}>
            <BackgroundColorProvider background={backgrounds[tone]}>
                <Stack space="small">
                    {title && (
                        <TextBlock size="large" weight="600">
                            {title}
                        </TextBlock>
                    )}
                    {text && <TextBlock tone="secondary">{text}</TextBlock>}
                </Stack>
                <CloseButton
                    aria-label="Dismiss"
                    color="light"
                    onClick={onDismiss}
                    iconOnly
                    clear
                    round
                    size="sm"
                >
                    <TextBlock>
                        <CloseIcon aria-hidden />
                    </TextBlock>
                </CloseButton>
            </BackgroundColorProvider>
        </Container>
    );
}
