import gql from "graphql-tag";

const requestPasswordResetMutation = gql`
    mutation RequestPasswordResetMutation(
        $email: String!
        $redirectUrl: String!
        $rootUrl: RootURLEnum
        $marketplaceSlug: String
        $universitySlug: String
        $localePreference: String
    ) {
        requestPasswordReset(
            email: $email
            redirectUrl: $redirectUrl
            rootUrl: $rootUrl
            marketplaceSlug: $marketplaceSlug
            universitySlug: $universitySlug
            localePreference: $localePreference
        ) {
            success
        }
    }
`;

export default requestPasswordResetMutation;
